import React, { FC } from 'react';

interface Props {
  x: number;
  y: number;
  payload: { value: string };
  dy: number;
  textAnchor?: string;
}

const textColor = '#5C6E79';

export const ChartTick: FC<Props> = ({ x, y, payload, dy, textAnchor }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={dy || 6} textAnchor={textAnchor || 'end'} fill={textColor} fontSize={12} fontWeight={600}>
        {payload.value}
      </text>
    </g>
  );
};
