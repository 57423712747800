import { SortByFn } from 'react-table';
import { useCallback } from 'react';
import { ReportItemDataFragment } from 'generated/types';
import { isAfter } from 'date-fns';

export const useReportTableSorts = () => {
  const sortTitleType = useCallback<SortByFn<ReportItemDataFragment>>((rowA, rowB, _columnId, desc) => {
    if (!rowA.original.title) {
      return desc ? -1 : 1;
    }
    if (!rowB.original.title) {
      return desc ? 1 : -1;
    }

    return (rowA.original.title || '').localeCompare(rowB.original.title || '', 'en');
  }, []);

  const sortProjectType = useCallback<SortByFn<ReportItemDataFragment>>((rowA, rowB) => {
    return rowA.original.project.name.localeCompare(rowB.original.project.name, 'en');
  }, []);

  const sortStartDateType = useCallback<SortByFn<ReportItemDataFragment>>((rowA, rowB) => {
    return isAfter(new Date(rowA.original.startDate), new Date(rowB.original.startDate)) ? 1 : -1;
  }, []);

  const sortPaidAmountType = useCallback<SortByFn<ReportItemDataFragment>>((rowA, rowB) => {
    return (rowA.original?.paidAmount || 0) - (rowB.original?.paidAmount || 0);
  }, []);

  const sortPaidDateType = useCallback<SortByFn<ReportItemDataFragment>>((rowA, rowB) => {
    return isAfter(new Date(rowA.original.paymentDate), new Date(rowB.original.paymentDate)) ? 1 : -1;
  }, []);

  const sortCreatedAtDateType = useCallback<SortByFn<ReportItemDataFragment>>((rowA, rowB) => {
    return isAfter(new Date(rowA.original.created_at), new Date(rowB.original.created_at)) ? 1 : -1;
  }, []);

  const sortUpdatedAtDateType = useCallback<SortByFn<ReportItemDataFragment>>((rowA, rowB) => {
    return isAfter(new Date(rowA.original.updated_at), new Date(rowB.original.updated_at)) ? 1 : -1;
  }, []);

  const sortPMType = useCallback<SortByFn<ReportItemDataFragment>>((rowA, rowB, _columnId, desc) => {
    if (!rowA.original.project.pm?.id) {
      return desc ? -1 : 1;
    }
    if (!rowB.original.project.pm?.id) {
      return desc ? 1 : -1;
    }

    return `${rowA.original.project.pm?.first_name} ${rowA.original.project.pm?.last_name}`.localeCompare(
      `${rowB.original.project.pm?.first_name} ${rowB.original.project.pm?.last_name}`,
      'en',
    );
  }, []);

  const sortStatusType = useCallback<SortByFn<ReportItemDataFragment>>((rowA, rowB) => {
    return (rowA.original.status || '').localeCompare(rowB.original.status || '', 'en');
  }, []);

  const sortCountComments = useCallback<SortByFn<ReportItemDataFragment>>((rowA, rowB) => {
    return (rowA.original?.countComments || 0) - (rowB.original?.countComments || 0);
  }, []);

  return {
    sortTitleType,
    sortProjectType,
    sortStartDateType,
    sortPMType,
    sortStatusType,
    sortCreatedAtDateType,
    sortUpdatedAtDateType,
    sortPaidAmountType,
    sortPaidDateType,
    sortCountComments,
  };
};
