import { useCallback, useState } from 'react';
import { localStorageManager } from 'services';
import { TEAM_TABLE_COLUMN_ORDER, TEAM_TABLE_HIDDEN_COLUMNS, TeamTableColumns } from 'consts';
import { useCompanyIntegrationsQuery } from 'generated/graphql';
import { useAuth } from 'contexts';

const defaultActiveColumns = [
  TeamTableColumns.JobTitle,
  TeamTableColumns.Type,
  TeamTableColumns.ReportingTo,
  TeamTableColumns.Specialization,
  TeamTableColumns.Seniority,
  TeamTableColumns.ActiveAssignments,
  TeamTableColumns.IntegrationStatus,
];

const allColumnsOptions = [
  TeamTableColumns.Email,
  TeamTableColumns.JobTitle,
  TeamTableColumns.Type,
  TeamTableColumns.ReportingTo,
  TeamTableColumns.Specialization,
  TeamTableColumns.Seniority,
  TeamTableColumns.ActiveAssignments,
  TeamTableColumns.IntegrationStatus,
  TeamTableColumns.CurrentSalary,
  TeamTableColumns.JoiningDate,
  TeamTableColumns.ExitDate,
];

export const useTeamTableSetting = () => {
  const { userData } = useAuth();
  const currentColumnOrder = (localStorageManager.getItem(TEAM_TABLE_COLUMN_ORDER) as unknown) as TeamTableColumns[];

  const [columnsOrder, setColumnsOrder] = useState<string[]>(currentColumnOrder || []);

  const { data: { companyIntegrations = [] } = {} } = useCompanyIntegrationsQuery({
    variables: { companyId: userData!.company.id },
  });
  const filteredAllColumnsOptions = allColumnsOptions.filter((column) =>
    column === TeamTableColumns.IntegrationStatus ? !!companyIntegrations.length : true,
  );
  const filteredDefaultActiveColumns = defaultActiveColumns.filter((column) =>
    column === TeamTableColumns.IntegrationStatus ? !!companyIntegrations.length : true,
  );

  const onChangeColumnsOrder = useCallback((columns: string[]) => {
    setColumnsOrder(columns);
    localStorageManager.setItem(TEAM_TABLE_COLUMN_ORDER, columns);
  }, []);

  const hiddenColumnOptions = (localStorageManager.getItem(TEAM_TABLE_HIDDEN_COLUMNS) as unknown) as TeamTableColumns[];

  const currentColumnOptions = hiddenColumnOptions
    ? Object.values(TeamTableColumns).filter((column) => !hiddenColumnOptions?.includes(column))
    : filteredDefaultActiveColumns;
  const filteredCurrentColumnOptions = currentColumnOptions.filter((column) =>
    column === TeamTableColumns.IntegrationStatus ? !!companyIntegrations.length : true,
  );

  const [activeColumns, setActiveColumns] = useState<string[]>(filteredCurrentColumnOptions);

  const onChangeActiveColumns = useCallback((columns: string[]) => {
    setActiveColumns(columns);

    const hiddenColumns = Object.values(TeamTableColumns).filter((column) => !columns?.includes(column));
    localStorageManager.setItem(TEAM_TABLE_HIDDEN_COLUMNS, hiddenColumns);
  }, []);

  return {
    defaultActiveColumns: filteredDefaultActiveColumns,
    allColumnsOptions: filteredAllColumnsOptions,
    columnsOrder,
    onChangeColumnsOrder,
    activeColumns,
    onChangeActiveColumns,
  };
};
