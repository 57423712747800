import React, { FC, useState } from 'react';
import { isAfter, parse } from 'date-fns';

interface Props {
  fill: string;
  x: number;
  y: number;
  width: number;
  height: number;
  radius: number;
  strokeWidth: number;
  stroke: string;
  withMask?: boolean;
  withFutureDateOpacity?: boolean;
  nameOfPeriod: string;
  hoveredNameOfPeriod?: string | null;
}

export const ChartBar: FC<Props | undefined> = ({
  fill,
  x,
  y,
  width,
  height,
  radius,
  strokeWidth,
  stroke,
  withMask,
  withFutureDateOpacity,
  nameOfPeriod,
  hoveredNameOfPeriod,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  if (!height) {
    return <svg />;
  }

  const barDate = parse(nameOfPeriod, 'MMM yy', new Date());

  return (
    <svg
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      opacity={withFutureDateOpacity && isAfter(barDate, new Date()) ? 0.4 : 1}
    >
      <pattern id="pattern-stripe" width="8" height="8" patternUnits="userSpaceOnUse" patternTransform="rotate(10)">
        <rect width="6" height="8" transform="translate(0,0)" fill="white"></rect>
      </pattern>
      <mask id="mask-stripe">
        <rect x="0" y="0" width="100%" height="100%" fill="url(#pattern-stripe)" />
      </mask>

      <rect
        x={x + 1}
        y={y + 1}
        width={width - 2}
        height={height > 2 ? height - 2 : height}
        fill={fill}
        rx={radius}
        mask={withMask ? 'url(#mask-stripe)' : undefined}
      />

      {(isHovered || hoveredNameOfPeriod === nameOfPeriod) && (
        <rect
          x={x - 0.5}
          y={y - 0.5}
          height={height + 1}
          width={width + 1}
          stroke={stroke}
          rx={radius}
          strokeWidth={strokeWidth}
          fill="transparent"
        />
      )}
    </svg>
  );
};
