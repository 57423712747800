import React, { CSSProperties } from 'react';
import { HeaderGroup } from 'react-table';
import clsx from 'clsx';

import styles from './Heading.module.scss';
import { DescSortTableIcon, SortTableIcon } from 'icons';
import { ACTIONS, SELECTION, SETTING } from 'consts';

export interface TableHeaderProps<D extends Record<string, unknown> = Record<string, never>> {
  headerGroups: Array<HeaderGroup<D>>;
  headingClassName?: string;
  insideAccordion?: boolean;
  isSelectMode?: boolean;
  isSetDefaultWidth?: boolean;
  stickyLeftSelection?: boolean;
}

const DEFAULT_CELL_MIN_WIDTH = 132;

const getStyles = <D extends Record<string, unknown>>(
  column: HeaderGroup<D>,
  columnsWidth: number,
  isSetDefaultWidth?: boolean,
) => {
  const ellipsisStyles: CSSProperties = {
    display: 'inline-block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  if (isSetDefaultWidth) {
    const tableHeaderStyles =
      column.id !== SELECTION
        ? {
            minWidth: column.minWidth,
            width: `${(+(column.width || 1) / columnsWidth) * 100}%`,
          }
        : {};

    return {
      tableHeaderStyles,
      headerItemStyles: { maxWidth: 'none' },
    };
  }

  if (column.id === SELECTION || column.id === ACTIONS || column.id === SETTING) {
    return { tableHeaderStyles: {}, headerItemStyles: {} };
  }

  if (!isSetDefaultWidth && column.minWidth) {
    return {
      tableHeaderStyles: { maxWidth: column.maxWidth, minWidth: column.minWidth || DEFAULT_CELL_MIN_WIDTH },
      headerItemStyles: ellipsisStyles,
    };
  }

  return {
    tableHeaderStyles: { maxWidth: column.maxWidth, minWidth: column.minWidth || DEFAULT_CELL_MIN_WIDTH },
    headerItemStyles: { ...ellipsisStyles, span: ellipsisStyles },
  };
};

export const Heading = <D extends Record<string, unknown> = Record<string, never>>({
  headerGroups,
  headingClassName,
  insideAccordion,
  isSelectMode,
  isSetDefaultWidth,
  stickyLeftSelection,
}: TableHeaderProps<D>) => {
  return (
    <thead className={clsx(styles.heading, insideAccordion && styles.insideAccordion, headingClassName)}>
      {headerGroups.map((headerGroup) => {
        const columnsWidth = isSetDefaultWidth
          ? headerGroup.headers.reduce((acc, column) => {
              return column.id === SELECTION ? acc : acc + +(column?.width || 0);
            }, 0)
          : 0;

        return (
          <tr className={styles.row} {...headerGroup.getHeaderGroupProps()} key={`heading-row${headerGroup.id}`}>
            {headerGroup.headers.map((column: HeaderGroup<D> & { sortable?: boolean }) => {
              const headerSortProps = column.sortable ? column.getSortByToggleProps() : undefined;
              const sortIcon = column.isSorted ? (
                <DescSortTableIcon className={clsx('ml-4', !column.isSortedDesc && styles.sortAsc)} />
              ) : (
                <SortTableIcon className="ml-4 hidden" />
              );
              const { tableHeaderStyles, headerItemStyles } = getStyles(column, columnsWidth, isSetDefaultWidth);

              return (
                <th
                  {...column.getHeaderProps(headerSortProps)}
                  title={typeof column.Header === 'string' ? column.Header : ''}
                  key={`heading-column${column.id}`}
                  style={tableHeaderStyles}
                  className={clsx({
                    [styles.stickySetting]: column.id === SETTING,
                    [styles.stickyAction]: column.id === ACTIONS,
                    [styles.selection]: column.id === SELECTION,
                    [styles.selectionStickyLeft]: column.id === SELECTION && stickyLeftSelection,
                    [styles.stickyLeft]: column.sticky === 'left',
                    [styles.stickyLeftWithSelection]: column.sticky === 'left' && isSelectMode,
                    [styles.stickyRight]: column.sticky === 'right',
                  })}
                >
                  <div className={styles.content}>
                    <span
                      className={clsx(styles.title, column.sortable && styles.titleWithSort)}
                      style={headerItemStyles}
                    >
                      {column.render('Header')}
                    </span>
                    {column.sortable ? sortIcon : ''}
                  </div>
                </th>
              );
            })}
          </tr>
        );
      })}
    </thead>
  );
};
