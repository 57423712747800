/* eslint-disable */
import * as Types from './types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export const MemberAssignmentDataFragmentDoc = gql`
    fragment MemberAssignmentData on Member {
  id
  first_name
  last_name
  leaves {
    id
    memberId
    startDate
    endDate
  }
  reportingToId
  archived_at
  costRateManagersIds
  color
}
    `;
export const AssignmentDataFragmentDoc = gql`
    fragment AssignmentData on Assignment {
  id
  member {
    ...MemberAssignmentData
  }
  endDate
  seniority
  seniorityId
  role {
    id
    name
  }
  project {
    id
    name
    color
    type
    feeCurrencyId
  }
  startDate
  allocationTimeAmount
  bill_amount_calculation_type
  billable
}
    ${MemberAssignmentDataFragmentDoc}`;
export const BillableLeaveRuleDataFragmentDoc = gql`
    fragment BillableLeaveRuleData on BillableLeaveRule {
  projectId
  limit
  leaveTypes {
    name
    id
  }
  id
  accrualDate
  accrualType
  created_at
}
    `;
export const ReportMemberEntityDataFragmentDoc = gql`
    fragment ReportMemberEntityData on ReportMemberEntity {
  allocationTime
  endDate
  id
  rateAmount
  rateUnit
  startDate
  total
}
    `;
export const ReportMemberDataFragmentDoc = gql`
    fragment ReportMemberData on ReportMember {
  allocationTimeAmount
  assignmentId
  endDate
  excludedDays
  id
  leaves
  memberColor
  memberId
  memberName
  reportId
  calculationType
  reportMemberEntity {
    ...ReportMemberEntityData
  }
  role
  seniority
  startDate
}
    ${ReportMemberEntityDataFragmentDoc}`;
export const ReportDataFragmentDoc = gql`
    fragment ReportData on Report {
  endDate
  holidays
  id
  memberId
  members {
    ...ReportMemberData
    billedLeavesBalance
  }
  project {
    color
    id
    name
    fee_currency
    type
  }
  startDate
  status
  title
  created_at
  updated_at
  paymentDate
  paidAmount
  customization
  countComments
}
    ${ReportMemberDataFragmentDoc}`;
export const ReportDataWithClientFragmentDoc = gql`
    fragment ReportDataWithClient on Report {
  ...ReportData
  project {
    color
    id
    name
    fee_currency
    client {
      id
      name
    }
  }
}
    ${ReportDataFragmentDoc}`;
export const ReportDataWithPmFragmentDoc = gql`
    fragment ReportDataWithPM on Report {
  ...ReportData
  project {
    color
    id
    name
    fee_currency
    archived_at
    client {
      id
    }
    pm {
      id
      first_name
      last_name
      color
    }
  }
}
    ${ReportDataFragmentDoc}`;
export const ReportItemDataFragmentDoc = gql`
    fragment ReportItemData on Report {
  project {
    color
    id
    name
    type
    fee_currency
    archived_at
    client {
      id
    }
    pm {
      id
      first_name
      last_name
      color
    }
  }
  endDate
  id
  memberId
  startDate
  status
  title
  created_at
  updated_at
  paymentDate
  paidAmount
  countComments
}
    `;
export const CommentDataFragmentDoc = gql`
    fragment CommentData on Comment {
  companyId
  createdByMemberColor
  createdByMemberName
  created_at
  entityId
  entityType
  id
  note
  updated_at
  memberId
}
    `;
export const ExternalRateDataFragmentDoc = gql`
    fragment ExternalRateData on ExternalRate {
  id
  projectId
  roleId
  role {
    id
    name
  }
  start_date
  end_date
  rate_amount
  unit
  seniorityId
  seniority
  created_at
}
    `;
export const CompanyIntegrationSyncDataFragmentDoc = gql`
    fragment CompanyIntegrationSyncData on CompanyIntegrationSync {
  id
  companyIntegrationId
  integrationOptionId
  integrationOption
  lastSyncAt
  lastSyncStatus
  syncByMember
  syncOption {
    id
  }
}
    `;
export const BalanceMemberDataFragmentDoc = gql`
    fragment BalanceMemberData on BalanceMember {
  period
  limit
  last_name
  memberId
  first_name
  color
  balance
  accrualMonth
}
    `;
export const RuleMembersBalanceDataFragmentDoc = gql`
    fragment RuleMembersBalanceData on RuleMembersBalance {
  leaveTypes
  members {
    ...BalanceMemberData
  }
  ruleId
}
    ${BalanceMemberDataFragmentDoc}`;
export const BillableLeaveRuleMonthBalanceDataFragmentDoc = gql`
    fragment BillableLeaveRuleMonthBalanceData on BillableLeaveRuleMonthBalance {
  month
  billedLeaves
  balance
  accrualAmount
}
    `;
export const MemberBalanceAccrualMonthDataFragmentDoc = gql`
    fragment MemberBalanceAccrualMonthData on MemberBalanceAccrualMonth {
  accrualDate
  id
  memberId
}
    `;
export const MemberDataFragmentDoc = gql`
    fragment MemberData on CompanyUser {
  id
  type
  member {
    id
    email
    first_name
    last_name
    color
  }
  company {
    id
    name
    primaryCurrencyId
  }
}
    `;
export const ProjectCommissionDataFragmentDoc = gql`
    fragment ProjectCommissionData on ProjectCommission {
  commission
  effectiveFrom
  id
}
    `;
export const TagDataFragmentDoc = gql`
    fragment TagData on Tag {
  color
  id
  name
}
    `;
export const ProjectDocumentDataFragmentDoc = gql`
    fragment ProjectDocumentData on ProjectDocument {
  id
  projectId
  companyId
  documentId
  document {
    id
    companyId
    name
    url
    size
  }
  createdBy
  createdByMemberColor
  createdByMemberName
  projectColor
  projectName
  created_at
  tags {
    ...TagData
  }
}
    ${TagDataFragmentDoc}`;
export const ProjectMilestoneDataFragmentDoc = gql`
    fragment ProjectMilestoneData on ProjectMilestone {
  amount
  created_at
  id
  notes
  paymentDate
  projectId
  reportId
  title
}
    `;
export const ProjectMilestoneSectionDataFragmentDoc = gql`
    fragment ProjectMilestoneSectionData on ProjectMilestoneSection {
  id
  actualCommission
  actualRevenue
  cost
  notes
  amount
  paymentDate
  plannedCommission
  plannedRevenue
  profit
  profitMargin
  status
  title
}
    `;
export const RequestDataFragmentDoc = gql`
    fragment RequestData on Request {
  id
  entityType
  createdBy
  createdByMemberName
  createdByMemberColor
  companyId
  status
  requestAction
  changes
  data
  entityId
  memberId
  note
  changedStatusAt
  changedStatusMemberName
  created_at
}
    `;
export const ActivityHistoriesDataFragmentDoc = gql`
    fragment ActivityHistoriesData on ActivityHistory {
  action
  changes
  companyId
  created_at
  data
  id
  integration
  memberId
  type
  createdByMemberName
  createdByMemberColor
}
    `;
export const BenchMemberDataFragmentDoc = gql`
    fragment BenchMemberData on BenchMember {
  memberId
  memberName
  memberColor
  specialization
  jobTitle
  period
  nonscheduledHours
  nonscheduledDays
  cost
}
    `;
export const BenchReportDataFragmentDoc = gql`
    fragment BenchReportData on BenchReport {
  nameOfPeriod
  totals {
    groupBy
    groupByValue
    totalType
    totalSum
  }
  members {
    ...BenchMemberData
  }
}
    ${BenchMemberDataFragmentDoc}`;
export const ExchangeRateDataFragmentDoc = gql`
    fragment ExchangeRateData on ExchangeRates {
  id
  exchangeCurrency {
    id
    name
    code
  }
  baseCurrency {
    id
    name
    code
  }
  exchangeRate
}
    `;
export const CompanyIntegrationDataFragmentDoc = gql`
    fragment CompanyIntegrationData on CompanyIntegration {
  id
  integrationId
  integration
  status
  connectedByMemberName
  connectedByMemberColor
  dateFormat
  integrationOptions {
    id
    companyIntegrationId
    integrationOptionId
    integrationOption
    lastSyncAt
    lastSyncStatus
    syncByMember
    syncOption {
      id
    }
  }
  created_at
}
    `;
export const IntegrationOptionDataFragmentDoc = gql`
    fragment IntegrationOptionData on IntegrationOption {
  id
  name
  syncOptions {
    id
    integrationOptionId
    name
  }
}
    `;
export const AvailableIntegrationDataFragmentDoc = gql`
    fragment AvailableIntegrationData on AvailableIntegration {
  id
  name
  status
  options {
    ...IntegrationOptionData
  }
}
    ${IntegrationOptionDataFragmentDoc}`;
export const MemberProjectDistributionDataFragmentDoc = gql`
    fragment MemberProjectDistributionData on MemberProjectDistribution {
  percentage
  projectColor
  projectId
  projectName
  totalHours
}
    `;
export const MemberIntegrationStatusesDataFragmentDoc = gql`
    fragment MemberIntegrationStatusesData on MemberIntegrationStatuses {
  memberId
  statuses {
    integrationName
    status
  }
}
    `;
export const MemberListDetailsDataFragmentDoc = gql`
    fragment MemberListDetailsData on MemberListData {
  activeAssignments
  currentSalary
  memberId
}
    `;
export const PermissionDataFragmentDoc = gql`
    fragment permissionData on Permission {
  action
}
    `;
export const ClientDataFragmentDoc = gql`
    fragment ClientData on Client {
  id
  name
}
    `;
export const ProjectMembershipDataFragmentDoc = gql`
    fragment ProjectMembershipData on ProjectMembership {
  accessLevel
  color
  endDate
  id
  memberFirstName
  memberId
  memberLastName
  projectId
  startDate
  projectName
  projectId
  projectColor
  projectType
  pmId
}
    `;
export const ProjectRoleOrderDataFragmentDoc = gql`
    fragment ProjectRoleOrderData on ProjectRoleOrder {
  orderingIndex
  roleName
  roleId
}
    `;
export const ProjectListDetailsDataFragmentDoc = gql`
    fragment ProjectListDetailsData on ProjectListData {
  activeAssignments
  currentCommission
  projectId
}
    `;
export const ProjectMarginsDataFragmentDoc = gql`
    fragment ProjectMarginsData on ProjectMargins {
  projectId
  margin
  forecastMargin
}
    `;
export const RevenueChartDataFragmentDoc = gql`
    fragment RevenueChartData on RevenueChart {
  totalUnpaid
  totalScheduled
  totalPaid
  totalActual
  nameOfMonth
}
    `;
export const TeamMemberDataFragmentDoc = gql`
    fragment TeamMemberData on Member {
  id
  first_name
  last_name
  email
  is_pending
  employment_type
  job_title
  reportingToId
  reportingTo {
    id
    first_name
    last_name
    color
  }
  member_seniority {
    id
    name
  }
  memberSpecialization
  archived_at
  companyUserId
  color
  join_date
  exit_date
}
    `;
export const CompanyUserDataFragmentDoc = gql`
    fragment CompanyUserData on CompanyUser {
  id
  type
  deactivatedAt
  createdAt
  reportingToCount
  status
  memberId
  projectsAccessCount(withAccessCount: $withAccessCount)
  member {
    id
    first_name
    last_name
    email
    is_pending
    color
    member_role {
      id
      name
    }
  }
}
    `;
export const UtilizationMemberDataFragmentDoc = gql`
    fragment UtilizationMemberData on UtilizationMember {
  memberId
  memberName
  memberColor
  period
  capacity
  scheduledHours
  billableHours
  nonBillableHours
  nonscheduledHours
  leavesHours
  actualCapacity
  utilization
  billableUtilization
  nonBillableUtilization
  nonscheduledPercent
  paidLeavesHours
  unpaidLeavesHours
  loggedHours
  overtimeHours
}
    `;
export const UtilizationReportDataFragmentDoc = gql`
    fragment UtilizationReportData on UtilizationReport {
  nameOfPeriod
  totalCapacity
  totalBillable
  totalNonBillable
  totalLeaves
  unassignedHours
  actualCapacity
  members {
    ...UtilizationMemberData
  }
}
    ${UtilizationMemberDataFragmentDoc}`;
export const PureSkillCategoryDataFragmentDoc = gql`
    fragment PureSkillCategoryData on SkillCategory {
  id
  name
}
    `;
export const SkillCategoryDataFragmentDoc = gql`
    fragment SkillCategoryData on SkillCategory {
  ...PureSkillCategoryData
  skills @include(if: $withSkills) {
    name
    id
  }
}
    ${PureSkillCategoryDataFragmentDoc}`;
export const TimeLogDataFragmentDoc = gql`
    fragment TimeLogData on TimeLog {
  id
  date
  minutes
  notes
  assignment_id
  overtime
  overtimeMultiplier
}
    `;
export const TotalTimeLogDataFragmentDoc = gql`
    fragment TotalTimeLogData on TotalTimeLog {
  totalMinutes
  member_id
  date
  assignment_id
}
    `;
export const ExpenseDataFragmentDoc = gql`
    fragment ExpenseData on Expense {
  id
  project {
    id
    name
  }
  budget {
    id
    name
  }
  member {
    id
    first_name
    last_name
    color
  }
  type
  typeName
  date
  amount
  isBillable
  notes
  billableAmount
  reportId
  created_at
}
    `;
export const LeaveTimeLogDataFragmentDoc = gql`
    fragment LeaveTimeLogData on Leave {
  startDate
  endDate
  leaveType {
    name
  }
}
    `;
export const ProjectAssignmentTimeLogDataFragmentDoc = gql`
    fragment ProjectAssignmentTimeLogData on Assignment {
  id
  startDate
  endDate
  bill_amount_calculation_type
  projectId
  member {
    id
    first_name
    last_name
    employment_type
    color
    leaves {
      ...LeaveTimeLogData
    }
  }
  seniority
  role {
    name
    id
  }
}
    ${LeaveTimeLogDataFragmentDoc}`;
export const ProfitabilityDataFragmentDoc = gql`
    fragment ProfitabilityData on Profitability {
  commission
  cost
  nameOfMonth
  other
  profit
  profitMargin
  revenue
  salary
  tmBenchmark
}
    `;
export const TeamDynamicsDataFragmentDoc = gql`
    fragment TeamDynamicsData on TeamDynamics {
  nameOfMonth
  roles {
    roleName
    employees
    contractors
  }
}
    `;
export const ProjectAssignmentsDataFragmentDoc = gql`
    fragment ProjectAssignmentsData on ProjectAssignmentMembers {
  memberId
  assignmentId
  assignmentStartDate
  assignmentEndDate
  first_name
  last_name
  role
  scheduledTime
  actualTime
  cost
  color
  costOrigin
  revenue
  allocationHours
  actualUnpaidRevenue
  actualRevenue
  actualPaidRevenue
  tmBenchmark
  rates {
    end_date
    rate_amount
    start_date
    unit
  }
  billingType
  billable
  seniority
  actualCommission
  actualProfit
  actualProfitMargin
  plannedCommission
  profit
  profitMargin
  daysRanges {
    start_date
    end_date
    rate_amount
    rate_currency
    unit
  }
}
    `;
export const PmProjectMembershipDataFragmentDoc = gql`
    fragment PmProjectMembershipData on PmProjectMembership {
  accessLevel
  startDate
  endDate
}
    `;
export const ProjectDataFragmentDoc = gql`
    fragment ProjectData on Project {
  id
  name
  unit
  type
  fee_amount
  fee_currency
  feeCurrencyId
  color
  client {
    id
    name
  }
  pm {
    id
    first_name
    last_name
    color
  }
  start_date
  end_date
  projectFinancialManagersIds
  archived_at
  pmFinAccess {
    ...PmProjectMembershipData
  }
  cost_budget_amount
  billableLeaves
  activeAssignments
  overtimeMultiplier
}
    ${PmProjectMembershipDataFragmentDoc}`;
export const ManagedProjectDataFragmentDoc = gql`
    fragment ManagedProjectData on Project {
  id
  name
  client {
    id
    name
  }
  pm {
    id
    first_name
    last_name
    color
  }
  type
  start_date
  end_date
  fee_currency
  feeCurrencyId
  archived_at
  color
  projectFinancialManagersIds
  countFiles
}
    `;
export const ResourcePlanningRequestDataFragmentDoc = gql`
    fragment ResourcePlanningRequestData on Request {
  changes
  status
  created_at
  data
  id
  note
}
    `;
export const ResourcePlanningProjectsDataFragmentDoc = gql`
    fragment ResourcePlanningProjectsData on ResourcePlanningProject {
  id
  name
  color
  assignment {
    id
    startDate
    endDate
    allocationTimeAmount
    billable
    created_at
    role {
      id
      name
    }
  }
  requests @include(if: $withRequests) {
    ...ResourcePlanningRequestData
  }
  member {
    id
  }
}
    ${ResourcePlanningRequestDataFragmentDoc}`;
export const ResourcePlanningMembersDataFragmentDoc = gql`
    fragment ResourcePlanningMembersData on ResourcePlanningMember {
  id
  first_name
  last_name
  job_title
  color
  capacity
  archived_at
  reportingToId
  employment_type
  costRateManagers {
    id
    first_name
    last_name
  }
  member_leave @include(if: $withLeaves) {
    id
    memberId
    startDate
    endDate
    leaveType {
      id
      name
    }
    notes
  }
  company {
    id
    name
  }
  projects {
    ...ResourcePlanningProjectsData
  }
  specialization @include(if: $withSpecialization)
}
    ${ResourcePlanningProjectsDataFragmentDoc}`;
export const ResourcePlanningAssignmentMemberDataFragmentDoc = gql`
    fragment ResourcePlanningAssignmentMemberData on AssignmentMember {
  id
  first_name
  last_name
  job_title
  reportingTo
  color
  capacity
  member_leave @include(if: $withLeaves) {
    id
    startDate
    endDate
  }
}
    `;
export const ResourcePlanningProjectAssignmentDataFragmentDoc = gql`
    fragment ResourcePlanningProjectAssignmentData on ResourcePlanningAssignment {
  id
  project_id
  role {
    id
    name
  }
  startDate
  endDate
  allocationTimeAmount
  member {
    ...ResourcePlanningAssignmentMemberData
  }
}
    ${ResourcePlanningAssignmentMemberDataFragmentDoc}`;
export const ResourcePlanningProjectDataFragmentDoc = gql`
    fragment ResourcePlanningProjectData on ResourcePlanningProjectAssignment {
  id
  name
  client {
    name
    id
  }
  archived_at
  assignments {
    ...ResourcePlanningProjectAssignmentData
  }
  color
  end_date
  fee_currency
  type
  start_date
  pm {
    id
    last_name
    first_name
    color
  }
}
    ${ResourcePlanningProjectAssignmentDataFragmentDoc}`;
export const ScenarioMemberEntityDataFragmentDoc = gql`
    fragment ScenarioMemberEntityData on ScenarioMembersEntity {
  startDate
  endDate
  allocationPerCent
  actualAllocationPerCent
  allocationTimeAmountOrigin
  allocationTimeAmount
  costOrigin
  cost
  revenueOrigin
  revenue
  profit
  profitMargin
  id
  externalRateUnit
  externalRateAmount
  internalRateUnit
  internalRateAmount
  leavesHours
  leavesPercent
  commission
  workingHoursInMonth
}
    `;
export const ScenarioMembersDataFragmentDoc = gql`
    fragment ScenarioMembersData on ScenarioMembers {
  id
  allocationTimeAmount
  seniority
  member {
    id
    first_name
    last_name
    job_title
    color
  }
  role {
    name
  }
  scenarioMemberEntity {
    ...ScenarioMemberEntityData
  }
}
    ${ScenarioMemberEntityDataFragmentDoc}`;
export const ScenarioDetailsDataFragmentDoc = gql`
    fragment ScenarioDetailsData on Scenario {
  id
  title
  exchangeRateAmount
  exchangeRateCurrency
  description
  leavesForecast
  bookedLeaves
  commissions
  project {
    id
    name
    type
    fee_amount
    fee_currency
  }
}
    `;
export const ScenarioDataFragmentDoc = gql`
    fragment ScenarioData on Scenario {
  id
  title
  created_at
  description
  activeMembers
  createdByMemberName
  archived_at
  project {
    id
    name
    start_date
    end_date
    color
    type
    client {
      id
      name
    }
  }
}
    `;
export const LeaveTypeDataFragmentDoc = gql`
    fragment LeaveTypeData on LeaveType {
  id
  name
  paid
  color
}
    `;
export const SkillDataFragmentDoc = gql`
    fragment SkillData on Skill {
  id
  name
  skillCategoryId
}
    `;
export const CostRateDataFragmentDoc = gql`
    fragment CostRateData on CostRate {
  id
  start_date
  end_date
  rate_amount
  rate_currency
  rateCurrencyId
  unit
  created_at
}
    `;
export const MemberProjectDataFragmentDoc = gql`
    fragment MemberProjectData on Project {
  id
  name
  color
  type
  pm {
    id
    first_name
    last_name
    color
  }
  overtimeMultiplier @include(if: $withOvertimeMultiplier)
}
    `;
export const MemberProjectAssignmentDataFragmentDoc = gql`
    fragment MemberProjectAssignmentData on Assignment {
  id
  member {
    id
  }
  projectId
  project {
    ...MemberProjectData
  }
  role {
    id
    name
  }
  seniority
  startDate
  endDate
  allocationTimeAmount
  bill_amount_calculation_type
  billable
}
    ${MemberProjectDataFragmentDoc}`;
export const LeaveDocument = gql`
    query leave($companyId: ID!, $leaveId: ID!) {
  leave(companyId: $companyId, leaveId: $leaveId) {
    id
    startDate
    leaveType {
      id
      name
    }
    notes
    endDate
    memberFirstName
    memberLastName
    memberId
  }
}
    `;

/**
 * __useLeaveQuery__
 *
 * To run a query within a React component, call `useLeaveQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeaveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeaveQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      leaveId: // value for 'leaveId'
 *   },
 * });
 */
export function useLeaveQuery(baseOptions: Apollo.QueryHookOptions<Types.LeaveQuery, Types.LeaveQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.LeaveQuery, Types.LeaveQueryVariables>(LeaveDocument, options);
      }
export function useLeaveLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.LeaveQuery, Types.LeaveQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.LeaveQuery, Types.LeaveQueryVariables>(LeaveDocument, options);
        }
export type LeaveQueryHookResult = ReturnType<typeof useLeaveQuery>;
export type LeaveLazyQueryHookResult = ReturnType<typeof useLeaveLazyQuery>;
export type LeaveQueryResult = Apollo.QueryResult<Types.LeaveQuery, Types.LeaveQueryVariables>;
export const ArchiveMemberDocument = gql`
    mutation ArchiveMember($companyId: ID!, $memberId: ID!) {
  archiveMember(companyId: $companyId, memberId: $memberId) {
    id
  }
}
    `;
export type ArchiveMemberMutationFn = Apollo.MutationFunction<Types.ArchiveMemberMutation, Types.ArchiveMemberMutationVariables>;

/**
 * __useArchiveMemberMutation__
 *
 * To run a mutation, you first call `useArchiveMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveMemberMutation, { data, loading, error }] = useArchiveMemberMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useArchiveMemberMutation(baseOptions?: Apollo.MutationHookOptions<Types.ArchiveMemberMutation, Types.ArchiveMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ArchiveMemberMutation, Types.ArchiveMemberMutationVariables>(ArchiveMemberDocument, options);
      }
export type ArchiveMemberMutationHookResult = ReturnType<typeof useArchiveMemberMutation>;
export type ArchiveMemberMutationResult = Apollo.MutationResult<Types.ArchiveMemberMutation>;
export type ArchiveMemberMutationOptions = Apollo.BaseMutationOptions<Types.ArchiveMemberMutation, Types.ArchiveMemberMutationVariables>;
export const ArchiveProjectDocument = gql`
    mutation ArchiveProject($companyId: ID!, $projectId: ID!) {
  archiveProject(companyId: $companyId, projectId: $projectId) {
    id
  }
}
    `;
export type ArchiveProjectMutationFn = Apollo.MutationFunction<Types.ArchiveProjectMutation, Types.ArchiveProjectMutationVariables>;

/**
 * __useArchiveProjectMutation__
 *
 * To run a mutation, you first call `useArchiveProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveProjectMutation, { data, loading, error }] = useArchiveProjectMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useArchiveProjectMutation(baseOptions?: Apollo.MutationHookOptions<Types.ArchiveProjectMutation, Types.ArchiveProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ArchiveProjectMutation, Types.ArchiveProjectMutationVariables>(ArchiveProjectDocument, options);
      }
export type ArchiveProjectMutationHookResult = ReturnType<typeof useArchiveProjectMutation>;
export type ArchiveProjectMutationResult = Apollo.MutationResult<Types.ArchiveProjectMutation>;
export type ArchiveProjectMutationOptions = Apollo.BaseMutationOptions<Types.ArchiveProjectMutation, Types.ArchiveProjectMutationVariables>;
export const CreateAssignmentDocument = gql`
    mutation createAssignment($companyId: ID!, $data: IAssignment!) {
  createAssignment(companyId: $companyId, data: $data) {
    id
    seniorityId
    bill_amount_calculation_type
    billable
  }
}
    `;
export type CreateAssignmentMutationFn = Apollo.MutationFunction<Types.CreateAssignmentMutation, Types.CreateAssignmentMutationVariables>;

/**
 * __useCreateAssignmentMutation__
 *
 * To run a mutation, you first call `useCreateAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssignmentMutation, { data, loading, error }] = useCreateAssignmentMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateAssignmentMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateAssignmentMutation, Types.CreateAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateAssignmentMutation, Types.CreateAssignmentMutationVariables>(CreateAssignmentDocument, options);
      }
export type CreateAssignmentMutationHookResult = ReturnType<typeof useCreateAssignmentMutation>;
export type CreateAssignmentMutationResult = Apollo.MutationResult<Types.CreateAssignmentMutation>;
export type CreateAssignmentMutationOptions = Apollo.BaseMutationOptions<Types.CreateAssignmentMutation, Types.CreateAssignmentMutationVariables>;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!, $remember: Boolean) {
  login(email: $email, password: $password, remember: $remember) {
    token
    me {
      id
      type
      member {
        id
        email
        first_name
        last_name
        color
      }
      company {
        id
        name
        primaryCurrencyId
      }
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<Types.LoginMutation, Types.LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      remember: // value for 'remember'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<Types.LoginMutation, Types.LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.LoginMutation, Types.LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<Types.LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<Types.LoginMutation, Types.LoginMutationVariables>;
export const RefreshDocument = gql`
    mutation Refresh {
  refresh {
    token
    me {
      id
      member {
        id
        email
        first_name
        last_name
      }
      company {
        id
        name
        primaryCurrencyId
      }
    }
  }
}
    `;
export type RefreshMutationFn = Apollo.MutationFunction<Types.RefreshMutation, Types.RefreshMutationVariables>;

/**
 * __useRefreshMutation__
 *
 * To run a mutation, you first call `useRefreshMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshMutation, { data, loading, error }] = useRefreshMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshMutation(baseOptions?: Apollo.MutationHookOptions<Types.RefreshMutation, Types.RefreshMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RefreshMutation, Types.RefreshMutationVariables>(RefreshDocument, options);
      }
export type RefreshMutationHookResult = ReturnType<typeof useRefreshMutation>;
export type RefreshMutationResult = Apollo.MutationResult<Types.RefreshMutation>;
export type RefreshMutationOptions = Apollo.BaseMutationOptions<Types.RefreshMutation, Types.RefreshMutationVariables>;
export const ChangePasswordFromEmailDocument = gql`
    mutation ChangePasswordFromEmail($password: String!, $resetId: ID!, $secret: String!) {
  changePasswordFromEmail(password: $password, resetId: $resetId, secret: $secret) {
    token
    me {
      id
      member {
        id
        email
        first_name
        last_name
      }
      company {
        id
        name
      }
    }
  }
}
    `;
export type ChangePasswordFromEmailMutationFn = Apollo.MutationFunction<Types.ChangePasswordFromEmailMutation, Types.ChangePasswordFromEmailMutationVariables>;

/**
 * __useChangePasswordFromEmailMutation__
 *
 * To run a mutation, you first call `useChangePasswordFromEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordFromEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordFromEmailMutation, { data, loading, error }] = useChangePasswordFromEmailMutation({
 *   variables: {
 *      password: // value for 'password'
 *      resetId: // value for 'resetId'
 *      secret: // value for 'secret'
 *   },
 * });
 */
export function useChangePasswordFromEmailMutation(baseOptions?: Apollo.MutationHookOptions<Types.ChangePasswordFromEmailMutation, Types.ChangePasswordFromEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ChangePasswordFromEmailMutation, Types.ChangePasswordFromEmailMutationVariables>(ChangePasswordFromEmailDocument, options);
      }
export type ChangePasswordFromEmailMutationHookResult = ReturnType<typeof useChangePasswordFromEmailMutation>;
export type ChangePasswordFromEmailMutationResult = Apollo.MutationResult<Types.ChangePasswordFromEmailMutation>;
export type ChangePasswordFromEmailMutationOptions = Apollo.BaseMutationOptions<Types.ChangePasswordFromEmailMutation, Types.ChangePasswordFromEmailMutationVariables>;
export const CreateClientDocument = gql`
    mutation CreateClient($companyId: ID!, $data: IClient!) {
  createClient(companyId: $companyId, data: $data) {
    id
    name
  }
}
    `;
export type CreateClientMutationFn = Apollo.MutationFunction<Types.CreateClientMutation, Types.CreateClientMutationVariables>;

/**
 * __useCreateClientMutation__
 *
 * To run a mutation, you first call `useCreateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientMutation, { data, loading, error }] = useCreateClientMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateClientMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateClientMutation, Types.CreateClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateClientMutation, Types.CreateClientMutationVariables>(CreateClientDocument, options);
      }
export type CreateClientMutationHookResult = ReturnType<typeof useCreateClientMutation>;
export type CreateClientMutationResult = Apollo.MutationResult<Types.CreateClientMutation>;
export type CreateClientMutationOptions = Apollo.BaseMutationOptions<Types.CreateClientMutation, Types.CreateClientMutationVariables>;
export const CreateBillableLeaveRuleDocument = gql`
    mutation CreateBillableLeaveRule($companyId: ID!, $data: IBillableLeaveRule!, $projectId: ID!) {
  createBillableLeaveRule(
    companyId: $companyId
    data: $data
    projectId: $projectId
  ) {
    ...BillableLeaveRuleData
  }
}
    ${BillableLeaveRuleDataFragmentDoc}`;
export type CreateBillableLeaveRuleMutationFn = Apollo.MutationFunction<Types.CreateBillableLeaveRuleMutation, Types.CreateBillableLeaveRuleMutationVariables>;

/**
 * __useCreateBillableLeaveRuleMutation__
 *
 * To run a mutation, you first call `useCreateBillableLeaveRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBillableLeaveRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBillableLeaveRuleMutation, { data, loading, error }] = useCreateBillableLeaveRuleMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useCreateBillableLeaveRuleMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateBillableLeaveRuleMutation, Types.CreateBillableLeaveRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateBillableLeaveRuleMutation, Types.CreateBillableLeaveRuleMutationVariables>(CreateBillableLeaveRuleDocument, options);
      }
export type CreateBillableLeaveRuleMutationHookResult = ReturnType<typeof useCreateBillableLeaveRuleMutation>;
export type CreateBillableLeaveRuleMutationResult = Apollo.MutationResult<Types.CreateBillableLeaveRuleMutation>;
export type CreateBillableLeaveRuleMutationOptions = Apollo.BaseMutationOptions<Types.CreateBillableLeaveRuleMutation, Types.CreateBillableLeaveRuleMutationVariables>;
export const CreateExpenseDocument = gql`
    mutation CreateExpense($companyId: ID!, $data: IExpense!) {
  createExpense(companyId: $companyId, data: $data) {
    id
    isBillable
    notes
  }
}
    `;
export type CreateExpenseMutationFn = Apollo.MutationFunction<Types.CreateExpenseMutation, Types.CreateExpenseMutationVariables>;

/**
 * __useCreateExpenseMutation__
 *
 * To run a mutation, you first call `useCreateExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExpenseMutation, { data, loading, error }] = useCreateExpenseMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateExpenseMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateExpenseMutation, Types.CreateExpenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateExpenseMutation, Types.CreateExpenseMutationVariables>(CreateExpenseDocument, options);
      }
export type CreateExpenseMutationHookResult = ReturnType<typeof useCreateExpenseMutation>;
export type CreateExpenseMutationResult = Apollo.MutationResult<Types.CreateExpenseMutation>;
export type CreateExpenseMutationOptions = Apollo.BaseMutationOptions<Types.CreateExpenseMutation, Types.CreateExpenseMutationVariables>;
export const CreateExpenseTypeDocument = gql`
    mutation CreateExpenseType($companyId: ID!, $data: IExpenseType!) {
  createExpenseType(companyId: $companyId, data: $data) {
    id
    name
  }
}
    `;
export type CreateExpenseTypeMutationFn = Apollo.MutationFunction<Types.CreateExpenseTypeMutation, Types.CreateExpenseTypeMutationVariables>;

/**
 * __useCreateExpenseTypeMutation__
 *
 * To run a mutation, you first call `useCreateExpenseTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExpenseTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExpenseTypeMutation, { data, loading, error }] = useCreateExpenseTypeMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateExpenseTypeMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateExpenseTypeMutation, Types.CreateExpenseTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateExpenseTypeMutation, Types.CreateExpenseTypeMutationVariables>(CreateExpenseTypeDocument, options);
      }
export type CreateExpenseTypeMutationHookResult = ReturnType<typeof useCreateExpenseTypeMutation>;
export type CreateExpenseTypeMutationResult = Apollo.MutationResult<Types.CreateExpenseTypeMutation>;
export type CreateExpenseTypeMutationOptions = Apollo.BaseMutationOptions<Types.CreateExpenseTypeMutation, Types.CreateExpenseTypeMutationVariables>;
export const CreateExternalRateDocument = gql`
    mutation CreateExternalRate($companyId: ID!, $projectId: ID!, $roleId: ID!, $data: IExternalRate!, $seniorityId: ID) {
  createExternalRate(
    companyId: $companyId
    projectId: $projectId
    roleId: $roleId
    data: $data
    seniorityId: $seniorityId
  ) {
    ...ExternalRateData
  }
}
    ${ExternalRateDataFragmentDoc}`;
export type CreateExternalRateMutationFn = Apollo.MutationFunction<Types.CreateExternalRateMutation, Types.CreateExternalRateMutationVariables>;

/**
 * __useCreateExternalRateMutation__
 *
 * To run a mutation, you first call `useCreateExternalRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExternalRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExternalRateMutation, { data, loading, error }] = useCreateExternalRateMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      projectId: // value for 'projectId'
 *      roleId: // value for 'roleId'
 *      data: // value for 'data'
 *      seniorityId: // value for 'seniorityId'
 *   },
 * });
 */
export function useCreateExternalRateMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateExternalRateMutation, Types.CreateExternalRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateExternalRateMutation, Types.CreateExternalRateMutationVariables>(CreateExternalRateDocument, options);
      }
export type CreateExternalRateMutationHookResult = ReturnType<typeof useCreateExternalRateMutation>;
export type CreateExternalRateMutationResult = Apollo.MutationResult<Types.CreateExternalRateMutation>;
export type CreateExternalRateMutationOptions = Apollo.BaseMutationOptions<Types.CreateExternalRateMutation, Types.CreateExternalRateMutationVariables>;
export const CreateHolidayDocument = gql`
    mutation CreateHoliday($companyId: ID!, $data: IHoliday!) {
  createHoliday(companyId: $companyId, data: $data) {
    id
  }
}
    `;
export type CreateHolidayMutationFn = Apollo.MutationFunction<Types.CreateHolidayMutation, Types.CreateHolidayMutationVariables>;

/**
 * __useCreateHolidayMutation__
 *
 * To run a mutation, you first call `useCreateHolidayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHolidayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHolidayMutation, { data, loading, error }] = useCreateHolidayMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateHolidayMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateHolidayMutation, Types.CreateHolidayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateHolidayMutation, Types.CreateHolidayMutationVariables>(CreateHolidayDocument, options);
      }
export type CreateHolidayMutationHookResult = ReturnType<typeof useCreateHolidayMutation>;
export type CreateHolidayMutationResult = Apollo.MutationResult<Types.CreateHolidayMutation>;
export type CreateHolidayMutationOptions = Apollo.BaseMutationOptions<Types.CreateHolidayMutation, Types.CreateHolidayMutationVariables>;
export const CreateIntegrationConnectionDocument = gql`
    mutation CreateIntegrationConnection($companyId: ID!, $data: ICompanyIntegration!) {
  createIntegrationConnection(companyId: $companyId, data: $data) {
    ...CompanyIntegrationData
  }
}
    ${CompanyIntegrationDataFragmentDoc}`;
export type CreateIntegrationConnectionMutationFn = Apollo.MutationFunction<Types.CreateIntegrationConnectionMutation, Types.CreateIntegrationConnectionMutationVariables>;

/**
 * __useCreateIntegrationConnectionMutation__
 *
 * To run a mutation, you first call `useCreateIntegrationConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIntegrationConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIntegrationConnectionMutation, { data, loading, error }] = useCreateIntegrationConnectionMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateIntegrationConnectionMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateIntegrationConnectionMutation, Types.CreateIntegrationConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateIntegrationConnectionMutation, Types.CreateIntegrationConnectionMutationVariables>(CreateIntegrationConnectionDocument, options);
      }
export type CreateIntegrationConnectionMutationHookResult = ReturnType<typeof useCreateIntegrationConnectionMutation>;
export type CreateIntegrationConnectionMutationResult = Apollo.MutationResult<Types.CreateIntegrationConnectionMutation>;
export type CreateIntegrationConnectionMutationOptions = Apollo.BaseMutationOptions<Types.CreateIntegrationConnectionMutation, Types.CreateIntegrationConnectionMutationVariables>;
export const CreateLeaveDocument = gql`
    mutation CreateLeave($companyId: ID!, $memberId: ID!, $data: ILeave!) {
  createLeave(companyId: $companyId, memberId: $memberId, data: $data) {
    id
    notes
  }
}
    `;
export type CreateLeaveMutationFn = Apollo.MutationFunction<Types.CreateLeaveMutation, Types.CreateLeaveMutationVariables>;

/**
 * __useCreateLeaveMutation__
 *
 * To run a mutation, you first call `useCreateLeaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLeaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLeaveMutation, { data, loading, error }] = useCreateLeaveMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      memberId: // value for 'memberId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateLeaveMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateLeaveMutation, Types.CreateLeaveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateLeaveMutation, Types.CreateLeaveMutationVariables>(CreateLeaveDocument, options);
      }
export type CreateLeaveMutationHookResult = ReturnType<typeof useCreateLeaveMutation>;
export type CreateLeaveMutationResult = Apollo.MutationResult<Types.CreateLeaveMutation>;
export type CreateLeaveMutationOptions = Apollo.BaseMutationOptions<Types.CreateLeaveMutation, Types.CreateLeaveMutationVariables>;
export const CreateMemberProjectsMembershipDocument = gql`
    mutation CreateMemberProjectsMembership($companyId: ID!, $data: IMemberProjectMembership!, $memberId: ID!) {
  createMemberProjectsMembership(
    companyId: $companyId
    data: $data
    memberId: $memberId
  ) {
    ...ProjectMembershipData
  }
}
    ${ProjectMembershipDataFragmentDoc}`;
export type CreateMemberProjectsMembershipMutationFn = Apollo.MutationFunction<Types.CreateMemberProjectsMembershipMutation, Types.CreateMemberProjectsMembershipMutationVariables>;

/**
 * __useCreateMemberProjectsMembershipMutation__
 *
 * To run a mutation, you first call `useCreateMemberProjectsMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMemberProjectsMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMemberProjectsMembershipMutation, { data, loading, error }] = useCreateMemberProjectsMembershipMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useCreateMemberProjectsMembershipMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateMemberProjectsMembershipMutation, Types.CreateMemberProjectsMembershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateMemberProjectsMembershipMutation, Types.CreateMemberProjectsMembershipMutationVariables>(CreateMemberProjectsMembershipDocument, options);
      }
export type CreateMemberProjectsMembershipMutationHookResult = ReturnType<typeof useCreateMemberProjectsMembershipMutation>;
export type CreateMemberProjectsMembershipMutationResult = Apollo.MutationResult<Types.CreateMemberProjectsMembershipMutation>;
export type CreateMemberProjectsMembershipMutationOptions = Apollo.BaseMutationOptions<Types.CreateMemberProjectsMembershipMutation, Types.CreateMemberProjectsMembershipMutationVariables>;
export const CreateProjectCommissionDocument = gql`
    mutation CreateProjectCommission($companyId: ID!, $data: IProjectCommission!, $projectId: ID!) {
  createProjectCommission(
    companyId: $companyId
    data: $data
    projectId: $projectId
  ) {
    ...ProjectCommissionData
  }
}
    ${ProjectCommissionDataFragmentDoc}`;
export type CreateProjectCommissionMutationFn = Apollo.MutationFunction<Types.CreateProjectCommissionMutation, Types.CreateProjectCommissionMutationVariables>;

/**
 * __useCreateProjectCommissionMutation__
 *
 * To run a mutation, you first call `useCreateProjectCommissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectCommissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectCommissionMutation, { data, loading, error }] = useCreateProjectCommissionMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useCreateProjectCommissionMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateProjectCommissionMutation, Types.CreateProjectCommissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateProjectCommissionMutation, Types.CreateProjectCommissionMutationVariables>(CreateProjectCommissionDocument, options);
      }
export type CreateProjectCommissionMutationHookResult = ReturnType<typeof useCreateProjectCommissionMutation>;
export type CreateProjectCommissionMutationResult = Apollo.MutationResult<Types.CreateProjectCommissionMutation>;
export type CreateProjectCommissionMutationOptions = Apollo.BaseMutationOptions<Types.CreateProjectCommissionMutation, Types.CreateProjectCommissionMutationVariables>;
export const CreateProjectDocumentDocument = gql`
    mutation CreateProjectDocument($companyId: ID!, $data: ICreateProjectDocument!) {
  createProjectDocument(companyId: $companyId, data: $data) {
    ...ProjectDocumentData
  }
}
    ${ProjectDocumentDataFragmentDoc}`;
export type CreateProjectDocumentMutationFn = Apollo.MutationFunction<Types.CreateProjectDocumentMutation, Types.CreateProjectDocumentMutationVariables>;

/**
 * __useCreateProjectDocumentMutation__
 *
 * To run a mutation, you first call `useCreateProjectDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectDocumentMutation, { data, loading, error }] = useCreateProjectDocumentMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateProjectDocumentMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateProjectDocumentMutation, Types.CreateProjectDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateProjectDocumentMutation, Types.CreateProjectDocumentMutationVariables>(CreateProjectDocumentDocument, options);
      }
export type CreateProjectDocumentMutationHookResult = ReturnType<typeof useCreateProjectDocumentMutation>;
export type CreateProjectDocumentMutationResult = Apollo.MutationResult<Types.CreateProjectDocumentMutation>;
export type CreateProjectDocumentMutationOptions = Apollo.BaseMutationOptions<Types.CreateProjectDocumentMutation, Types.CreateProjectDocumentMutationVariables>;
export const CreateProjectMembershipDocument = gql`
    mutation CreateProjectMembership($companyId: ID!, $data: IProjectMembership!) {
  createProjectMembership(companyId: $companyId, data: $data) {
    ...ProjectMembershipData
  }
}
    ${ProjectMembershipDataFragmentDoc}`;
export type CreateProjectMembershipMutationFn = Apollo.MutationFunction<Types.CreateProjectMembershipMutation, Types.CreateProjectMembershipMutationVariables>;

/**
 * __useCreateProjectMembershipMutation__
 *
 * To run a mutation, you first call `useCreateProjectMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMembershipMutation, { data, loading, error }] = useCreateProjectMembershipMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateProjectMembershipMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateProjectMembershipMutation, Types.CreateProjectMembershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateProjectMembershipMutation, Types.CreateProjectMembershipMutationVariables>(CreateProjectMembershipDocument, options);
      }
export type CreateProjectMembershipMutationHookResult = ReturnType<typeof useCreateProjectMembershipMutation>;
export type CreateProjectMembershipMutationResult = Apollo.MutationResult<Types.CreateProjectMembershipMutation>;
export type CreateProjectMembershipMutationOptions = Apollo.BaseMutationOptions<Types.CreateProjectMembershipMutation, Types.CreateProjectMembershipMutationVariables>;
export const CreateProjectMilestoneDocument = gql`
    mutation CreateProjectMilestone($companyId: ID!, $data: IProjectMilestone!, $projectId: ID!) {
  createProjectMilestone(
    companyId: $companyId
    data: $data
    projectId: $projectId
  ) {
    ...ProjectMilestoneData
  }
}
    ${ProjectMilestoneDataFragmentDoc}`;
export type CreateProjectMilestoneMutationFn = Apollo.MutationFunction<Types.CreateProjectMilestoneMutation, Types.CreateProjectMilestoneMutationVariables>;

/**
 * __useCreateProjectMilestoneMutation__
 *
 * To run a mutation, you first call `useCreateProjectMilestoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMilestoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMilestoneMutation, { data, loading, error }] = useCreateProjectMilestoneMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useCreateProjectMilestoneMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateProjectMilestoneMutation, Types.CreateProjectMilestoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateProjectMilestoneMutation, Types.CreateProjectMilestoneMutationVariables>(CreateProjectMilestoneDocument, options);
      }
export type CreateProjectMilestoneMutationHookResult = ReturnType<typeof useCreateProjectMilestoneMutation>;
export type CreateProjectMilestoneMutationResult = Apollo.MutationResult<Types.CreateProjectMilestoneMutation>;
export type CreateProjectMilestoneMutationOptions = Apollo.BaseMutationOptions<Types.CreateProjectMilestoneMutation, Types.CreateProjectMilestoneMutationVariables>;
export const CreateMemberRoleDocument = gql`
    mutation CreateMemberRole($companyId: ID!, $data: IRole!) {
  createRole(companyId: $companyId, data: $data) {
    id
    name
  }
}
    `;
export type CreateMemberRoleMutationFn = Apollo.MutationFunction<Types.CreateMemberRoleMutation, Types.CreateMemberRoleMutationVariables>;

/**
 * __useCreateMemberRoleMutation__
 *
 * To run a mutation, you first call `useCreateMemberRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMemberRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMemberRoleMutation, { data, loading, error }] = useCreateMemberRoleMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateMemberRoleMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateMemberRoleMutation, Types.CreateMemberRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateMemberRoleMutation, Types.CreateMemberRoleMutationVariables>(CreateMemberRoleDocument, options);
      }
export type CreateMemberRoleMutationHookResult = ReturnType<typeof useCreateMemberRoleMutation>;
export type CreateMemberRoleMutationResult = Apollo.MutationResult<Types.CreateMemberRoleMutation>;
export type CreateMemberRoleMutationOptions = Apollo.BaseMutationOptions<Types.CreateMemberRoleMutation, Types.CreateMemberRoleMutationVariables>;
export const CreateReportDocument = gql`
    mutation CreateReport($companyId: ID!, $data: IReport!) {
  createReport(companyId: $companyId, data: $data) {
    ...ReportData
  }
}
    ${ReportDataFragmentDoc}`;
export type CreateReportMutationFn = Apollo.MutationFunction<Types.CreateReportMutation, Types.CreateReportMutationVariables>;

/**
 * __useCreateReportMutation__
 *
 * To run a mutation, you first call `useCreateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReportMutation, { data, loading, error }] = useCreateReportMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateReportMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateReportMutation, Types.CreateReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateReportMutation, Types.CreateReportMutationVariables>(CreateReportDocument, options);
      }
export type CreateReportMutationHookResult = ReturnType<typeof useCreateReportMutation>;
export type CreateReportMutationResult = Apollo.MutationResult<Types.CreateReportMutation>;
export type CreateReportMutationOptions = Apollo.BaseMutationOptions<Types.CreateReportMutation, Types.CreateReportMutationVariables>;
export const CreateRequestDocument = gql`
    mutation CreateRequest($companyId: ID!, $entityType: RequestDomains!, $data: ICreateRequest!, $requestAction: RequestAction!, $entityId: ID) {
  createRequest(
    companyId: $companyId
    entityType: $entityType
    data: $data
    requestAction: $requestAction
    entityId: $entityId
  ) {
    ...RequestData
  }
}
    ${RequestDataFragmentDoc}`;
export type CreateRequestMutationFn = Apollo.MutationFunction<Types.CreateRequestMutation, Types.CreateRequestMutationVariables>;

/**
 * __useCreateRequestMutation__
 *
 * To run a mutation, you first call `useCreateRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRequestMutation, { data, loading, error }] = useCreateRequestMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      entityType: // value for 'entityType'
 *      data: // value for 'data'
 *      requestAction: // value for 'requestAction'
 *      entityId: // value for 'entityId'
 *   },
 * });
 */
export function useCreateRequestMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateRequestMutation, Types.CreateRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateRequestMutation, Types.CreateRequestMutationVariables>(CreateRequestDocument, options);
      }
export type CreateRequestMutationHookResult = ReturnType<typeof useCreateRequestMutation>;
export type CreateRequestMutationResult = Apollo.MutationResult<Types.CreateRequestMutation>;
export type CreateRequestMutationOptions = Apollo.BaseMutationOptions<Types.CreateRequestMutation, Types.CreateRequestMutationVariables>;
export const CreateSeniorityDocument = gql`
    mutation CreateSeniority($companyId: ID!, $data: IMemberSeniority!) {
  createSeniority(companyId: $companyId, data: $data) {
    id
    name
  }
}
    `;
export type CreateSeniorityMutationFn = Apollo.MutationFunction<Types.CreateSeniorityMutation, Types.CreateSeniorityMutationVariables>;

/**
 * __useCreateSeniorityMutation__
 *
 * To run a mutation, you first call `useCreateSeniorityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSeniorityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSeniorityMutation, { data, loading, error }] = useCreateSeniorityMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSeniorityMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateSeniorityMutation, Types.CreateSeniorityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateSeniorityMutation, Types.CreateSeniorityMutationVariables>(CreateSeniorityDocument, options);
      }
export type CreateSeniorityMutationHookResult = ReturnType<typeof useCreateSeniorityMutation>;
export type CreateSeniorityMutationResult = Apollo.MutationResult<Types.CreateSeniorityMutation>;
export type CreateSeniorityMutationOptions = Apollo.BaseMutationOptions<Types.CreateSeniorityMutation, Types.CreateSeniorityMutationVariables>;
export const CreateMemberSpecializationDocument = gql`
    mutation CreateMemberSpecialization($companyId: ID!, $data: ISpecialization!) {
  createSpecialization(companyId: $companyId, data: $data) {
    id
    name
  }
}
    `;
export type CreateMemberSpecializationMutationFn = Apollo.MutationFunction<Types.CreateMemberSpecializationMutation, Types.CreateMemberSpecializationMutationVariables>;

/**
 * __useCreateMemberSpecializationMutation__
 *
 * To run a mutation, you first call `useCreateMemberSpecializationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMemberSpecializationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMemberSpecializationMutation, { data, loading, error }] = useCreateMemberSpecializationMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateMemberSpecializationMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateMemberSpecializationMutation, Types.CreateMemberSpecializationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateMemberSpecializationMutation, Types.CreateMemberSpecializationMutationVariables>(CreateMemberSpecializationDocument, options);
      }
export type CreateMemberSpecializationMutationHookResult = ReturnType<typeof useCreateMemberSpecializationMutation>;
export type CreateMemberSpecializationMutationResult = Apollo.MutationResult<Types.CreateMemberSpecializationMutation>;
export type CreateMemberSpecializationMutationOptions = Apollo.BaseMutationOptions<Types.CreateMemberSpecializationMutation, Types.CreateMemberSpecializationMutationVariables>;
export const CreateTagDocument = gql`
    mutation CreateTag($companyId: ID!, $data: ITag!) {
  createTag(companyId: $companyId, data: $data) {
    ...TagData
  }
}
    ${TagDataFragmentDoc}`;
export type CreateTagMutationFn = Apollo.MutationFunction<Types.CreateTagMutation, Types.CreateTagMutationVariables>;

/**
 * __useCreateTagMutation__
 *
 * To run a mutation, you first call `useCreateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagMutation, { data, loading, error }] = useCreateTagMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTagMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateTagMutation, Types.CreateTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateTagMutation, Types.CreateTagMutationVariables>(CreateTagDocument, options);
      }
export type CreateTagMutationHookResult = ReturnType<typeof useCreateTagMutation>;
export type CreateTagMutationResult = Apollo.MutationResult<Types.CreateTagMutation>;
export type CreateTagMutationOptions = Apollo.BaseMutationOptions<Types.CreateTagMutation, Types.CreateTagMutationVariables>;
export const CreateVendorDocument = gql`
    mutation CreateVendor($companyId: ID!, $data: IVendor!) {
  createVendor(companyId: $companyId, data: $data) {
    id
    name
  }
}
    `;
export type CreateVendorMutationFn = Apollo.MutationFunction<Types.CreateVendorMutation, Types.CreateVendorMutationVariables>;

/**
 * __useCreateVendorMutation__
 *
 * To run a mutation, you first call `useCreateVendorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVendorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVendorMutation, { data, loading, error }] = useCreateVendorMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateVendorMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateVendorMutation, Types.CreateVendorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateVendorMutation, Types.CreateVendorMutationVariables>(CreateVendorDocument, options);
      }
export type CreateVendorMutationHookResult = ReturnType<typeof useCreateVendorMutation>;
export type CreateVendorMutationResult = Apollo.MutationResult<Types.CreateVendorMutation>;
export type CreateVendorMutationOptions = Apollo.BaseMutationOptions<Types.CreateVendorMutation, Types.CreateVendorMutationVariables>;
export const DeactivateUserDocument = gql`
    mutation DeactivateUser($companyId: ID!, $memberId: ID!) {
  deactivateUser(companyId: $companyId, memberId: $memberId) {
    id
    deactivatedAt
    status
  }
}
    `;
export type DeactivateUserMutationFn = Apollo.MutationFunction<Types.DeactivateUserMutation, Types.DeactivateUserMutationVariables>;

/**
 * __useDeactivateUserMutation__
 *
 * To run a mutation, you first call `useDeactivateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateUserMutation, { data, loading, error }] = useDeactivateUserMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useDeactivateUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeactivateUserMutation, Types.DeactivateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeactivateUserMutation, Types.DeactivateUserMutationVariables>(DeactivateUserDocument, options);
      }
export type DeactivateUserMutationHookResult = ReturnType<typeof useDeactivateUserMutation>;
export type DeactivateUserMutationResult = Apollo.MutationResult<Types.DeactivateUserMutation>;
export type DeactivateUserMutationOptions = Apollo.BaseMutationOptions<Types.DeactivateUserMutation, Types.DeactivateUserMutationVariables>;
export const DeleteAssignmentDocument = gql`
    mutation DeleteAssignment($assignmentsIds: [ID!]!, $companyId: ID!) {
  deleteAssignment(assignmentsIds: $assignmentsIds, companyId: $companyId) {
    id
  }
}
    `;
export type DeleteAssignmentMutationFn = Apollo.MutationFunction<Types.DeleteAssignmentMutation, Types.DeleteAssignmentMutationVariables>;

/**
 * __useDeleteAssignmentMutation__
 *
 * To run a mutation, you first call `useDeleteAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssignmentMutation, { data, loading, error }] = useDeleteAssignmentMutation({
 *   variables: {
 *      assignmentsIds: // value for 'assignmentsIds'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useDeleteAssignmentMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteAssignmentMutation, Types.DeleteAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteAssignmentMutation, Types.DeleteAssignmentMutationVariables>(DeleteAssignmentDocument, options);
      }
export type DeleteAssignmentMutationHookResult = ReturnType<typeof useDeleteAssignmentMutation>;
export type DeleteAssignmentMutationResult = Apollo.MutationResult<Types.DeleteAssignmentMutation>;
export type DeleteAssignmentMutationOptions = Apollo.BaseMutationOptions<Types.DeleteAssignmentMutation, Types.DeleteAssignmentMutationVariables>;
export const DeleteBillableLeaveRuleDocument = gql`
    mutation DeleteBillableLeaveRule($billableLeaveRuleId: ID!, $companyId: ID!, $projectId: ID!) {
  deleteBillableLeaveRule(
    billableLeaveRuleId: $billableLeaveRuleId
    companyId: $companyId
    projectId: $projectId
  ) {
    id
  }
}
    `;
export type DeleteBillableLeaveRuleMutationFn = Apollo.MutationFunction<Types.DeleteBillableLeaveRuleMutation, Types.DeleteBillableLeaveRuleMutationVariables>;

/**
 * __useDeleteBillableLeaveRuleMutation__
 *
 * To run a mutation, you first call `useDeleteBillableLeaveRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBillableLeaveRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBillableLeaveRuleMutation, { data, loading, error }] = useDeleteBillableLeaveRuleMutation({
 *   variables: {
 *      billableLeaveRuleId: // value for 'billableLeaveRuleId'
 *      companyId: // value for 'companyId'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useDeleteBillableLeaveRuleMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteBillableLeaveRuleMutation, Types.DeleteBillableLeaveRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteBillableLeaveRuleMutation, Types.DeleteBillableLeaveRuleMutationVariables>(DeleteBillableLeaveRuleDocument, options);
      }
export type DeleteBillableLeaveRuleMutationHookResult = ReturnType<typeof useDeleteBillableLeaveRuleMutation>;
export type DeleteBillableLeaveRuleMutationResult = Apollo.MutationResult<Types.DeleteBillableLeaveRuleMutation>;
export type DeleteBillableLeaveRuleMutationOptions = Apollo.BaseMutationOptions<Types.DeleteBillableLeaveRuleMutation, Types.DeleteBillableLeaveRuleMutationVariables>;
export const DeleteCompanyIntegrationDocument = gql`
    mutation DeleteCompanyIntegration($companyId: ID!, $companyIntegrationId: ID!) {
  deleteCompanyIntegration(
    companyId: $companyId
    companyIntegrationId: $companyIntegrationId
  ) {
    id
  }
}
    `;
export type DeleteCompanyIntegrationMutationFn = Apollo.MutationFunction<Types.DeleteCompanyIntegrationMutation, Types.DeleteCompanyIntegrationMutationVariables>;

/**
 * __useDeleteCompanyIntegrationMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyIntegrationMutation, { data, loading, error }] = useDeleteCompanyIntegrationMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      companyIntegrationId: // value for 'companyIntegrationId'
 *   },
 * });
 */
export function useDeleteCompanyIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteCompanyIntegrationMutation, Types.DeleteCompanyIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteCompanyIntegrationMutation, Types.DeleteCompanyIntegrationMutationVariables>(DeleteCompanyIntegrationDocument, options);
      }
export type DeleteCompanyIntegrationMutationHookResult = ReturnType<typeof useDeleteCompanyIntegrationMutation>;
export type DeleteCompanyIntegrationMutationResult = Apollo.MutationResult<Types.DeleteCompanyIntegrationMutation>;
export type DeleteCompanyIntegrationMutationOptions = Apollo.BaseMutationOptions<Types.DeleteCompanyIntegrationMutation, Types.DeleteCompanyIntegrationMutationVariables>;
export const DeleteCostRateDocument = gql`
    mutation DeleteCostRate($companyId: ID!, $costRateId: ID!) {
  deleteCostRate(companyId: $companyId, costRateId: $costRateId) {
    id
  }
}
    `;
export type DeleteCostRateMutationFn = Apollo.MutationFunction<Types.DeleteCostRateMutation, Types.DeleteCostRateMutationVariables>;

/**
 * __useDeleteCostRateMutation__
 *
 * To run a mutation, you first call `useDeleteCostRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCostRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCostRateMutation, { data, loading, error }] = useDeleteCostRateMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      costRateId: // value for 'costRateId'
 *   },
 * });
 */
export function useDeleteCostRateMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteCostRateMutation, Types.DeleteCostRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteCostRateMutation, Types.DeleteCostRateMutationVariables>(DeleteCostRateDocument, options);
      }
export type DeleteCostRateMutationHookResult = ReturnType<typeof useDeleteCostRateMutation>;
export type DeleteCostRateMutationResult = Apollo.MutationResult<Types.DeleteCostRateMutation>;
export type DeleteCostRateMutationOptions = Apollo.BaseMutationOptions<Types.DeleteCostRateMutation, Types.DeleteCostRateMutationVariables>;
export const DeleteExpenseDocument = gql`
    mutation DeleteExpense($companyId: ID!, $expenseId: ID!) {
  deleteExpense(companyId: $companyId, expenseId: $expenseId) {
    id
  }
}
    `;
export type DeleteExpenseMutationFn = Apollo.MutationFunction<Types.DeleteExpenseMutation, Types.DeleteExpenseMutationVariables>;

/**
 * __useDeleteExpenseMutation__
 *
 * To run a mutation, you first call `useDeleteExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExpenseMutation, { data, loading, error }] = useDeleteExpenseMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      expenseId: // value for 'expenseId'
 *   },
 * });
 */
export function useDeleteExpenseMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteExpenseMutation, Types.DeleteExpenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteExpenseMutation, Types.DeleteExpenseMutationVariables>(DeleteExpenseDocument, options);
      }
export type DeleteExpenseMutationHookResult = ReturnType<typeof useDeleteExpenseMutation>;
export type DeleteExpenseMutationResult = Apollo.MutationResult<Types.DeleteExpenseMutation>;
export type DeleteExpenseMutationOptions = Apollo.BaseMutationOptions<Types.DeleteExpenseMutation, Types.DeleteExpenseMutationVariables>;
export const DeleteExpenseTypeDocument = gql`
    mutation DeleteExpenseType($companyId: ID!, $expenseTypeId: ID!) {
  deleteExpenseType(companyId: $companyId, expenseTypeId: $expenseTypeId) {
    id
  }
}
    `;
export type DeleteExpenseTypeMutationFn = Apollo.MutationFunction<Types.DeleteExpenseTypeMutation, Types.DeleteExpenseTypeMutationVariables>;

/**
 * __useDeleteExpenseTypeMutation__
 *
 * To run a mutation, you first call `useDeleteExpenseTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExpenseTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExpenseTypeMutation, { data, loading, error }] = useDeleteExpenseTypeMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      expenseTypeId: // value for 'expenseTypeId'
 *   },
 * });
 */
export function useDeleteExpenseTypeMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteExpenseTypeMutation, Types.DeleteExpenseTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteExpenseTypeMutation, Types.DeleteExpenseTypeMutationVariables>(DeleteExpenseTypeDocument, options);
      }
export type DeleteExpenseTypeMutationHookResult = ReturnType<typeof useDeleteExpenseTypeMutation>;
export type DeleteExpenseTypeMutationResult = Apollo.MutationResult<Types.DeleteExpenseTypeMutation>;
export type DeleteExpenseTypeMutationOptions = Apollo.BaseMutationOptions<Types.DeleteExpenseTypeMutation, Types.DeleteExpenseTypeMutationVariables>;
export const DeleteExternalRateDocument = gql`
    mutation DeleteExternalRate($companyId: ID!, $externalRateId: ID!, $projectId: ID!) {
  deleteExternalRate(
    companyId: $companyId
    externalRateId: $externalRateId
    projectId: $projectId
  ) {
    id
  }
}
    `;
export type DeleteExternalRateMutationFn = Apollo.MutationFunction<Types.DeleteExternalRateMutation, Types.DeleteExternalRateMutationVariables>;

/**
 * __useDeleteExternalRateMutation__
 *
 * To run a mutation, you first call `useDeleteExternalRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExternalRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExternalRateMutation, { data, loading, error }] = useDeleteExternalRateMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      externalRateId: // value for 'externalRateId'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useDeleteExternalRateMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteExternalRateMutation, Types.DeleteExternalRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteExternalRateMutation, Types.DeleteExternalRateMutationVariables>(DeleteExternalRateDocument, options);
      }
export type DeleteExternalRateMutationHookResult = ReturnType<typeof useDeleteExternalRateMutation>;
export type DeleteExternalRateMutationResult = Apollo.MutationResult<Types.DeleteExternalRateMutation>;
export type DeleteExternalRateMutationOptions = Apollo.BaseMutationOptions<Types.DeleteExternalRateMutation, Types.DeleteExternalRateMutationVariables>;
export const DeleteHolidayDocument = gql`
    mutation DeleteHoliday($companyId: ID!, $holidayId: ID!) {
  deleteHoliday(companyId: $companyId, holidayId: $holidayId) {
    id
  }
}
    `;
export type DeleteHolidayMutationFn = Apollo.MutationFunction<Types.DeleteHolidayMutation, Types.DeleteHolidayMutationVariables>;

/**
 * __useDeleteHolidayMutation__
 *
 * To run a mutation, you first call `useDeleteHolidayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteHolidayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteHolidayMutation, { data, loading, error }] = useDeleteHolidayMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      holidayId: // value for 'holidayId'
 *   },
 * });
 */
export function useDeleteHolidayMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteHolidayMutation, Types.DeleteHolidayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteHolidayMutation, Types.DeleteHolidayMutationVariables>(DeleteHolidayDocument, options);
      }
export type DeleteHolidayMutationHookResult = ReturnType<typeof useDeleteHolidayMutation>;
export type DeleteHolidayMutationResult = Apollo.MutationResult<Types.DeleteHolidayMutation>;
export type DeleteHolidayMutationOptions = Apollo.BaseMutationOptions<Types.DeleteHolidayMutation, Types.DeleteHolidayMutationVariables>;
export const DeleteInvitationDocument = gql`
    mutation DeleteInvitation($companyId: ID!, $deleteInvitationId: String!) {
  deleteInvitation(companyId: $companyId, id: $deleteInvitationId) {
    id
  }
}
    `;
export type DeleteInvitationMutationFn = Apollo.MutationFunction<Types.DeleteInvitationMutation, Types.DeleteInvitationMutationVariables>;

/**
 * __useDeleteInvitationMutation__
 *
 * To run a mutation, you first call `useDeleteInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvitationMutation, { data, loading, error }] = useDeleteInvitationMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      deleteInvitationId: // value for 'deleteInvitationId'
 *   },
 * });
 */
export function useDeleteInvitationMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteInvitationMutation, Types.DeleteInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteInvitationMutation, Types.DeleteInvitationMutationVariables>(DeleteInvitationDocument, options);
      }
export type DeleteInvitationMutationHookResult = ReturnType<typeof useDeleteInvitationMutation>;
export type DeleteInvitationMutationResult = Apollo.MutationResult<Types.DeleteInvitationMutation>;
export type DeleteInvitationMutationOptions = Apollo.BaseMutationOptions<Types.DeleteInvitationMutation, Types.DeleteInvitationMutationVariables>;
export const DeleteLeaveDocument = gql`
    mutation DeleteLeave($companyId: ID!, $leaveId: ID!) {
  deleteLeave(companyId: $companyId, leaveId: $leaveId) {
    id
  }
}
    `;
export type DeleteLeaveMutationFn = Apollo.MutationFunction<Types.DeleteLeaveMutation, Types.DeleteLeaveMutationVariables>;

/**
 * __useDeleteLeaveMutation__
 *
 * To run a mutation, you first call `useDeleteLeaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLeaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLeaveMutation, { data, loading, error }] = useDeleteLeaveMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      leaveId: // value for 'leaveId'
 *   },
 * });
 */
export function useDeleteLeaveMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteLeaveMutation, Types.DeleteLeaveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteLeaveMutation, Types.DeleteLeaveMutationVariables>(DeleteLeaveDocument, options);
      }
export type DeleteLeaveMutationHookResult = ReturnType<typeof useDeleteLeaveMutation>;
export type DeleteLeaveMutationResult = Apollo.MutationResult<Types.DeleteLeaveMutation>;
export type DeleteLeaveMutationOptions = Apollo.BaseMutationOptions<Types.DeleteLeaveMutation, Types.DeleteLeaveMutationVariables>;
export const DeleteProjectCommissionDocument = gql`
    mutation DeleteProjectCommission($companyId: ID!, $projectCommissionId: ID!, $projectId: ID!) {
  deleteProjectCommission(
    companyId: $companyId
    projectCommissionId: $projectCommissionId
    projectId: $projectId
  ) {
    id
  }
}
    `;
export type DeleteProjectCommissionMutationFn = Apollo.MutationFunction<Types.DeleteProjectCommissionMutation, Types.DeleteProjectCommissionMutationVariables>;

/**
 * __useDeleteProjectCommissionMutation__
 *
 * To run a mutation, you first call `useDeleteProjectCommissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectCommissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectCommissionMutation, { data, loading, error }] = useDeleteProjectCommissionMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      projectCommissionId: // value for 'projectCommissionId'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useDeleteProjectCommissionMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteProjectCommissionMutation, Types.DeleteProjectCommissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteProjectCommissionMutation, Types.DeleteProjectCommissionMutationVariables>(DeleteProjectCommissionDocument, options);
      }
export type DeleteProjectCommissionMutationHookResult = ReturnType<typeof useDeleteProjectCommissionMutation>;
export type DeleteProjectCommissionMutationResult = Apollo.MutationResult<Types.DeleteProjectCommissionMutation>;
export type DeleteProjectCommissionMutationOptions = Apollo.BaseMutationOptions<Types.DeleteProjectCommissionMutation, Types.DeleteProjectCommissionMutationVariables>;
export const DeleteProjectDocumentDocument = gql`
    mutation DeleteProjectDocument($companyId: ID!, $projectId: ID!, $projectDocumentId: ID!) {
  deleteProjectDocument(
    companyId: $companyId
    projectId: $projectId
    projectDocumentId: $projectDocumentId
  ) {
    id
  }
}
    `;
export type DeleteProjectDocumentMutationFn = Apollo.MutationFunction<Types.DeleteProjectDocumentMutation, Types.DeleteProjectDocumentMutationVariables>;

/**
 * __useDeleteProjectDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteProjectDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectDocumentMutation, { data, loading, error }] = useDeleteProjectDocumentMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      projectId: // value for 'projectId'
 *      projectDocumentId: // value for 'projectDocumentId'
 *   },
 * });
 */
export function useDeleteProjectDocumentMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteProjectDocumentMutation, Types.DeleteProjectDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteProjectDocumentMutation, Types.DeleteProjectDocumentMutationVariables>(DeleteProjectDocumentDocument, options);
      }
export type DeleteProjectDocumentMutationHookResult = ReturnType<typeof useDeleteProjectDocumentMutation>;
export type DeleteProjectDocumentMutationResult = Apollo.MutationResult<Types.DeleteProjectDocumentMutation>;
export type DeleteProjectDocumentMutationOptions = Apollo.BaseMutationOptions<Types.DeleteProjectDocumentMutation, Types.DeleteProjectDocumentMutationVariables>;
export const DeleteProjectMembershipDocument = gql`
    mutation DeleteProjectMembership($companyId: ID!, $projectId: ID!, $projectMembershipId: ID!) {
  deleteProjectMembership(
    companyId: $companyId
    projectId: $projectId
    projectMembershipId: $projectMembershipId
  ) {
    id
  }
}
    `;
export type DeleteProjectMembershipMutationFn = Apollo.MutationFunction<Types.DeleteProjectMembershipMutation, Types.DeleteProjectMembershipMutationVariables>;

/**
 * __useDeleteProjectMembershipMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMembershipMutation, { data, loading, error }] = useDeleteProjectMembershipMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      projectId: // value for 'projectId'
 *      projectMembershipId: // value for 'projectMembershipId'
 *   },
 * });
 */
export function useDeleteProjectMembershipMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteProjectMembershipMutation, Types.DeleteProjectMembershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteProjectMembershipMutation, Types.DeleteProjectMembershipMutationVariables>(DeleteProjectMembershipDocument, options);
      }
export type DeleteProjectMembershipMutationHookResult = ReturnType<typeof useDeleteProjectMembershipMutation>;
export type DeleteProjectMembershipMutationResult = Apollo.MutationResult<Types.DeleteProjectMembershipMutation>;
export type DeleteProjectMembershipMutationOptions = Apollo.BaseMutationOptions<Types.DeleteProjectMembershipMutation, Types.DeleteProjectMembershipMutationVariables>;
export const DeleteProjectMilestoneDocument = gql`
    mutation DeleteProjectMilestone($projectMilestoneId: ID!, $companyId: ID!) {
  deleteProjectMilestone(
    projectMilestoneId: $projectMilestoneId
    companyId: $companyId
  ) {
    id
  }
}
    `;
export type DeleteProjectMilestoneMutationFn = Apollo.MutationFunction<Types.DeleteProjectMilestoneMutation, Types.DeleteProjectMilestoneMutationVariables>;

/**
 * __useDeleteProjectMilestoneMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMilestoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMilestoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMilestoneMutation, { data, loading, error }] = useDeleteProjectMilestoneMutation({
 *   variables: {
 *      projectMilestoneId: // value for 'projectMilestoneId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useDeleteProjectMilestoneMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteProjectMilestoneMutation, Types.DeleteProjectMilestoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteProjectMilestoneMutation, Types.DeleteProjectMilestoneMutationVariables>(DeleteProjectMilestoneDocument, options);
      }
export type DeleteProjectMilestoneMutationHookResult = ReturnType<typeof useDeleteProjectMilestoneMutation>;
export type DeleteProjectMilestoneMutationResult = Apollo.MutationResult<Types.DeleteProjectMilestoneMutation>;
export type DeleteProjectMilestoneMutationOptions = Apollo.BaseMutationOptions<Types.DeleteProjectMilestoneMutation, Types.DeleteProjectMilestoneMutationVariables>;
export const DeleteMemberRoleDocument = gql`
    mutation DeleteMemberRole($companyId: ID!, $memberRoleId: ID!) {
  deleteRole(companyId: $companyId, memberRoleId: $memberRoleId) {
    id
  }
}
    `;
export type DeleteMemberRoleMutationFn = Apollo.MutationFunction<Types.DeleteMemberRoleMutation, Types.DeleteMemberRoleMutationVariables>;

/**
 * __useDeleteMemberRoleMutation__
 *
 * To run a mutation, you first call `useDeleteMemberRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMemberRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMemberRoleMutation, { data, loading, error }] = useDeleteMemberRoleMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      memberRoleId: // value for 'memberRoleId'
 *   },
 * });
 */
export function useDeleteMemberRoleMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteMemberRoleMutation, Types.DeleteMemberRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteMemberRoleMutation, Types.DeleteMemberRoleMutationVariables>(DeleteMemberRoleDocument, options);
      }
export type DeleteMemberRoleMutationHookResult = ReturnType<typeof useDeleteMemberRoleMutation>;
export type DeleteMemberRoleMutationResult = Apollo.MutationResult<Types.DeleteMemberRoleMutation>;
export type DeleteMemberRoleMutationOptions = Apollo.BaseMutationOptions<Types.DeleteMemberRoleMutation, Types.DeleteMemberRoleMutationVariables>;
export const DeleteReportDocument = gql`
    mutation DeleteReport($companyId: ID!, $projectId: ID!, $reportId: ID!) {
  deleteReport(companyId: $companyId, projectId: $projectId, reportId: $reportId) {
    ...ReportData
  }
}
    ${ReportDataFragmentDoc}`;
export type DeleteReportMutationFn = Apollo.MutationFunction<Types.DeleteReportMutation, Types.DeleteReportMutationVariables>;

/**
 * __useDeleteReportMutation__
 *
 * To run a mutation, you first call `useDeleteReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReportMutation, { data, loading, error }] = useDeleteReportMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      projectId: // value for 'projectId'
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useDeleteReportMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteReportMutation, Types.DeleteReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteReportMutation, Types.DeleteReportMutationVariables>(DeleteReportDocument, options);
      }
export type DeleteReportMutationHookResult = ReturnType<typeof useDeleteReportMutation>;
export type DeleteReportMutationResult = Apollo.MutationResult<Types.DeleteReportMutation>;
export type DeleteReportMutationOptions = Apollo.BaseMutationOptions<Types.DeleteReportMutation, Types.DeleteReportMutationVariables>;
export const DeleteRequestsDocument = gql`
    mutation DeleteRequests($companyId: ID!, $requestsId: [ID!]!) {
  deleteRequests(companyId: $companyId, requestsId: $requestsId) {
    id
    status
  }
}
    `;
export type DeleteRequestsMutationFn = Apollo.MutationFunction<Types.DeleteRequestsMutation, Types.DeleteRequestsMutationVariables>;

/**
 * __useDeleteRequestsMutation__
 *
 * To run a mutation, you first call `useDeleteRequestsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRequestsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRequestsMutation, { data, loading, error }] = useDeleteRequestsMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      requestsId: // value for 'requestsId'
 *   },
 * });
 */
export function useDeleteRequestsMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteRequestsMutation, Types.DeleteRequestsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteRequestsMutation, Types.DeleteRequestsMutationVariables>(DeleteRequestsDocument, options);
      }
export type DeleteRequestsMutationHookResult = ReturnType<typeof useDeleteRequestsMutation>;
export type DeleteRequestsMutationResult = Apollo.MutationResult<Types.DeleteRequestsMutation>;
export type DeleteRequestsMutationOptions = Apollo.BaseMutationOptions<Types.DeleteRequestsMutation, Types.DeleteRequestsMutationVariables>;
export const DeleteSeniorityDocument = gql`
    mutation DeleteSeniority($companyId: ID!, $seniorityId: ID!) {
  deleteSeniority(companyId: $companyId, seniorityId: $seniorityId) {
    id
  }
}
    `;
export type DeleteSeniorityMutationFn = Apollo.MutationFunction<Types.DeleteSeniorityMutation, Types.DeleteSeniorityMutationVariables>;

/**
 * __useDeleteSeniorityMutation__
 *
 * To run a mutation, you first call `useDeleteSeniorityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSeniorityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSeniorityMutation, { data, loading, error }] = useDeleteSeniorityMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      seniorityId: // value for 'seniorityId'
 *   },
 * });
 */
export function useDeleteSeniorityMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteSeniorityMutation, Types.DeleteSeniorityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteSeniorityMutation, Types.DeleteSeniorityMutationVariables>(DeleteSeniorityDocument, options);
      }
export type DeleteSeniorityMutationHookResult = ReturnType<typeof useDeleteSeniorityMutation>;
export type DeleteSeniorityMutationResult = Apollo.MutationResult<Types.DeleteSeniorityMutation>;
export type DeleteSeniorityMutationOptions = Apollo.BaseMutationOptions<Types.DeleteSeniorityMutation, Types.DeleteSeniorityMutationVariables>;
export const DeleteMemberSpecializationDocument = gql`
    mutation DeleteMemberSpecialization($companyId: ID!, $memberSpecializationId: ID!) {
  deleteSpecialization(
    companyId: $companyId
    memberSpecializationId: $memberSpecializationId
  ) {
    id
  }
}
    `;
export type DeleteMemberSpecializationMutationFn = Apollo.MutationFunction<Types.DeleteMemberSpecializationMutation, Types.DeleteMemberSpecializationMutationVariables>;

/**
 * __useDeleteMemberSpecializationMutation__
 *
 * To run a mutation, you first call `useDeleteMemberSpecializationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMemberSpecializationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMemberSpecializationMutation, { data, loading, error }] = useDeleteMemberSpecializationMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      memberSpecializationId: // value for 'memberSpecializationId'
 *   },
 * });
 */
export function useDeleteMemberSpecializationMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteMemberSpecializationMutation, Types.DeleteMemberSpecializationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteMemberSpecializationMutation, Types.DeleteMemberSpecializationMutationVariables>(DeleteMemberSpecializationDocument, options);
      }
export type DeleteMemberSpecializationMutationHookResult = ReturnType<typeof useDeleteMemberSpecializationMutation>;
export type DeleteMemberSpecializationMutationResult = Apollo.MutationResult<Types.DeleteMemberSpecializationMutation>;
export type DeleteMemberSpecializationMutationOptions = Apollo.BaseMutationOptions<Types.DeleteMemberSpecializationMutation, Types.DeleteMemberSpecializationMutationVariables>;
export const DeleteTagDocument = gql`
    mutation DeleteTag($companyId: ID!, $tagId: ID!) {
  deleteTag(companyId: $companyId, tagId: $tagId) {
    id
  }
}
    `;
export type DeleteTagMutationFn = Apollo.MutationFunction<Types.DeleteTagMutation, Types.DeleteTagMutationVariables>;

/**
 * __useDeleteTagMutation__
 *
 * To run a mutation, you first call `useDeleteTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTagMutation, { data, loading, error }] = useDeleteTagMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      tagId: // value for 'tagId'
 *   },
 * });
 */
export function useDeleteTagMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteTagMutation, Types.DeleteTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteTagMutation, Types.DeleteTagMutationVariables>(DeleteTagDocument, options);
      }
export type DeleteTagMutationHookResult = ReturnType<typeof useDeleteTagMutation>;
export type DeleteTagMutationResult = Apollo.MutationResult<Types.DeleteTagMutation>;
export type DeleteTagMutationOptions = Apollo.BaseMutationOptions<Types.DeleteTagMutation, Types.DeleteTagMutationVariables>;
export const EditAssignmentDocument = gql`
    mutation editAssignment($companyId: ID!, $assignmentId: ID!, $data: IAssignment!) {
  editAssignment(companyId: $companyId, assignmentId: $assignmentId, data: $data) {
    id
    project {
      id
      name
    }
    member {
      id
      first_name
      last_name
    }
    role {
      id
    }
    bill_amount_calculation_type
    startDate
    endDate
    allocationTimeAmount
    seniority
    seniorityId
    billable
  }
}
    `;
export type EditAssignmentMutationFn = Apollo.MutationFunction<Types.EditAssignmentMutation, Types.EditAssignmentMutationVariables>;

/**
 * __useEditAssignmentMutation__
 *
 * To run a mutation, you first call `useEditAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAssignmentMutation, { data, loading, error }] = useEditAssignmentMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      assignmentId: // value for 'assignmentId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditAssignmentMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditAssignmentMutation, Types.EditAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditAssignmentMutation, Types.EditAssignmentMutationVariables>(EditAssignmentDocument, options);
      }
export type EditAssignmentMutationHookResult = ReturnType<typeof useEditAssignmentMutation>;
export type EditAssignmentMutationResult = Apollo.MutationResult<Types.EditAssignmentMutation>;
export type EditAssignmentMutationOptions = Apollo.BaseMutationOptions<Types.EditAssignmentMutation, Types.EditAssignmentMutationVariables>;
export const EditAssignmentsDocument = gql`
    mutation EditAssignments($assignmentsIds: [ID!]!, $companyId: ID!, $data: IEditAssignments!) {
  editAssignments(
    assignmentsIds: $assignmentsIds
    companyId: $companyId
    data: $data
  ) {
    ...AssignmentData
  }
}
    ${AssignmentDataFragmentDoc}`;
export type EditAssignmentsMutationFn = Apollo.MutationFunction<Types.EditAssignmentsMutation, Types.EditAssignmentsMutationVariables>;

/**
 * __useEditAssignmentsMutation__
 *
 * To run a mutation, you first call `useEditAssignmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAssignmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAssignmentsMutation, { data, loading, error }] = useEditAssignmentsMutation({
 *   variables: {
 *      assignmentsIds: // value for 'assignmentsIds'
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditAssignmentsMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditAssignmentsMutation, Types.EditAssignmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditAssignmentsMutation, Types.EditAssignmentsMutationVariables>(EditAssignmentsDocument, options);
      }
export type EditAssignmentsMutationHookResult = ReturnType<typeof useEditAssignmentsMutation>;
export type EditAssignmentsMutationResult = Apollo.MutationResult<Types.EditAssignmentsMutation>;
export type EditAssignmentsMutationOptions = Apollo.BaseMutationOptions<Types.EditAssignmentsMutation, Types.EditAssignmentsMutationVariables>;
export const EditBillableLeaveRuleDocument = gql`
    mutation EditBillableLeaveRule($billableLeaveRuleId: ID!, $companyId: ID!, $data: IBillableLeaveRule!, $projectId: ID!) {
  editBillableLeaveRule(
    billableLeaveRuleId: $billableLeaveRuleId
    companyId: $companyId
    data: $data
    projectId: $projectId
  ) {
    ...BillableLeaveRuleData
  }
}
    ${BillableLeaveRuleDataFragmentDoc}`;
export type EditBillableLeaveRuleMutationFn = Apollo.MutationFunction<Types.EditBillableLeaveRuleMutation, Types.EditBillableLeaveRuleMutationVariables>;

/**
 * __useEditBillableLeaveRuleMutation__
 *
 * To run a mutation, you first call `useEditBillableLeaveRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditBillableLeaveRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editBillableLeaveRuleMutation, { data, loading, error }] = useEditBillableLeaveRuleMutation({
 *   variables: {
 *      billableLeaveRuleId: // value for 'billableLeaveRuleId'
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useEditBillableLeaveRuleMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditBillableLeaveRuleMutation, Types.EditBillableLeaveRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditBillableLeaveRuleMutation, Types.EditBillableLeaveRuleMutationVariables>(EditBillableLeaveRuleDocument, options);
      }
export type EditBillableLeaveRuleMutationHookResult = ReturnType<typeof useEditBillableLeaveRuleMutation>;
export type EditBillableLeaveRuleMutationResult = Apollo.MutationResult<Types.EditBillableLeaveRuleMutation>;
export type EditBillableLeaveRuleMutationOptions = Apollo.BaseMutationOptions<Types.EditBillableLeaveRuleMutation, Types.EditBillableLeaveRuleMutationVariables>;
export const EditCompanyIntegrationOptionsDocument = gql`
    mutation EditCompanyIntegrationOptions($companyId: ID!, $companyIntegrationId: ID!, $integrationOptions: [IIntegrationSyncOption!]!) {
  editCompanyIntegrationOptions(
    companyId: $companyId
    companyIntegrationId: $companyIntegrationId
    integrationOptions: $integrationOptions
  ) {
    ...CompanyIntegrationSyncData
  }
}
    ${CompanyIntegrationSyncDataFragmentDoc}`;
export type EditCompanyIntegrationOptionsMutationFn = Apollo.MutationFunction<Types.EditCompanyIntegrationOptionsMutation, Types.EditCompanyIntegrationOptionsMutationVariables>;

/**
 * __useEditCompanyIntegrationOptionsMutation__
 *
 * To run a mutation, you first call `useEditCompanyIntegrationOptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCompanyIntegrationOptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCompanyIntegrationOptionsMutation, { data, loading, error }] = useEditCompanyIntegrationOptionsMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      companyIntegrationId: // value for 'companyIntegrationId'
 *      integrationOptions: // value for 'integrationOptions'
 *   },
 * });
 */
export function useEditCompanyIntegrationOptionsMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditCompanyIntegrationOptionsMutation, Types.EditCompanyIntegrationOptionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditCompanyIntegrationOptionsMutation, Types.EditCompanyIntegrationOptionsMutationVariables>(EditCompanyIntegrationOptionsDocument, options);
      }
export type EditCompanyIntegrationOptionsMutationHookResult = ReturnType<typeof useEditCompanyIntegrationOptionsMutation>;
export type EditCompanyIntegrationOptionsMutationResult = Apollo.MutationResult<Types.EditCompanyIntegrationOptionsMutation>;
export type EditCompanyIntegrationOptionsMutationOptions = Apollo.BaseMutationOptions<Types.EditCompanyIntegrationOptionsMutation, Types.EditCompanyIntegrationOptionsMutationVariables>;
export const EditCompanyIntegrationStatusDocument = gql`
    mutation EditCompanyIntegrationStatus($companyId: ID!, $companyIntegrationId: ID!, $status: IntegrationStatus!, $dateFormat: String) {
  editCompanyIntegrationStatus(
    companyId: $companyId
    companyIntegrationId: $companyIntegrationId
    status: $status
    dateFormat: $dateFormat
  ) {
    ...CompanyIntegrationData
  }
}
    ${CompanyIntegrationDataFragmentDoc}`;
export type EditCompanyIntegrationStatusMutationFn = Apollo.MutationFunction<Types.EditCompanyIntegrationStatusMutation, Types.EditCompanyIntegrationStatusMutationVariables>;

/**
 * __useEditCompanyIntegrationStatusMutation__
 *
 * To run a mutation, you first call `useEditCompanyIntegrationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCompanyIntegrationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCompanyIntegrationStatusMutation, { data, loading, error }] = useEditCompanyIntegrationStatusMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      companyIntegrationId: // value for 'companyIntegrationId'
 *      status: // value for 'status'
 *      dateFormat: // value for 'dateFormat'
 *   },
 * });
 */
export function useEditCompanyIntegrationStatusMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditCompanyIntegrationStatusMutation, Types.EditCompanyIntegrationStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditCompanyIntegrationStatusMutation, Types.EditCompanyIntegrationStatusMutationVariables>(EditCompanyIntegrationStatusDocument, options);
      }
export type EditCompanyIntegrationStatusMutationHookResult = ReturnType<typeof useEditCompanyIntegrationStatusMutation>;
export type EditCompanyIntegrationStatusMutationResult = Apollo.MutationResult<Types.EditCompanyIntegrationStatusMutation>;
export type EditCompanyIntegrationStatusMutationOptions = Apollo.BaseMutationOptions<Types.EditCompanyIntegrationStatusMutation, Types.EditCompanyIntegrationStatusMutationVariables>;
export const EditExpenseTypeDocument = gql`
    mutation EditExpenseType($companyId: ID!, $data: IExpenseType!, $expenseTypeId: ID!) {
  editExpenseType(
    companyId: $companyId
    data: $data
    expenseTypeId: $expenseTypeId
  ) {
    id
    name
  }
}
    `;
export type EditExpenseTypeMutationFn = Apollo.MutationFunction<Types.EditExpenseTypeMutation, Types.EditExpenseTypeMutationVariables>;

/**
 * __useEditExpenseTypeMutation__
 *
 * To run a mutation, you first call `useEditExpenseTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditExpenseTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editExpenseTypeMutation, { data, loading, error }] = useEditExpenseTypeMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *      expenseTypeId: // value for 'expenseTypeId'
 *   },
 * });
 */
export function useEditExpenseTypeMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditExpenseTypeMutation, Types.EditExpenseTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditExpenseTypeMutation, Types.EditExpenseTypeMutationVariables>(EditExpenseTypeDocument, options);
      }
export type EditExpenseTypeMutationHookResult = ReturnType<typeof useEditExpenseTypeMutation>;
export type EditExpenseTypeMutationResult = Apollo.MutationResult<Types.EditExpenseTypeMutation>;
export type EditExpenseTypeMutationOptions = Apollo.BaseMutationOptions<Types.EditExpenseTypeMutation, Types.EditExpenseTypeMutationVariables>;
export const EditExternalRateDocument = gql`
    mutation EditExternalRate($companyId: ID!, $data: IExternalRate!, $externalRateId: ID!, $projectId: ID!) {
  editExternalRate(
    companyId: $companyId
    data: $data
    externalRateId: $externalRateId
    projectId: $projectId
  ) {
    ...ExternalRateData
  }
}
    ${ExternalRateDataFragmentDoc}`;
export type EditExternalRateMutationFn = Apollo.MutationFunction<Types.EditExternalRateMutation, Types.EditExternalRateMutationVariables>;

/**
 * __useEditExternalRateMutation__
 *
 * To run a mutation, you first call `useEditExternalRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditExternalRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editExternalRateMutation, { data, loading, error }] = useEditExternalRateMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *      externalRateId: // value for 'externalRateId'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useEditExternalRateMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditExternalRateMutation, Types.EditExternalRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditExternalRateMutation, Types.EditExternalRateMutationVariables>(EditExternalRateDocument, options);
      }
export type EditExternalRateMutationHookResult = ReturnType<typeof useEditExternalRateMutation>;
export type EditExternalRateMutationResult = Apollo.MutationResult<Types.EditExternalRateMutation>;
export type EditExternalRateMutationOptions = Apollo.BaseMutationOptions<Types.EditExternalRateMutation, Types.EditExternalRateMutationVariables>;
export const EditExternalRateSeniorityDocument = gql`
    mutation EditExternalRateSeniority($companyId: ID!, $data: IEditExternalRateSeniority!) {
  editExternalRateSeniority(companyId: $companyId, data: $data) {
    ...ExternalRateData
  }
}
    ${ExternalRateDataFragmentDoc}`;
export type EditExternalRateSeniorityMutationFn = Apollo.MutationFunction<Types.EditExternalRateSeniorityMutation, Types.EditExternalRateSeniorityMutationVariables>;

/**
 * __useEditExternalRateSeniorityMutation__
 *
 * To run a mutation, you first call `useEditExternalRateSeniorityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditExternalRateSeniorityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editExternalRateSeniorityMutation, { data, loading, error }] = useEditExternalRateSeniorityMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditExternalRateSeniorityMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditExternalRateSeniorityMutation, Types.EditExternalRateSeniorityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditExternalRateSeniorityMutation, Types.EditExternalRateSeniorityMutationVariables>(EditExternalRateSeniorityDocument, options);
      }
export type EditExternalRateSeniorityMutationHookResult = ReturnType<typeof useEditExternalRateSeniorityMutation>;
export type EditExternalRateSeniorityMutationResult = Apollo.MutationResult<Types.EditExternalRateSeniorityMutation>;
export type EditExternalRateSeniorityMutationOptions = Apollo.BaseMutationOptions<Types.EditExternalRateSeniorityMutation, Types.EditExternalRateSeniorityMutationVariables>;
export const EditHolidayDocument = gql`
    mutation EditHoliday($companyId: ID!, $data: IHoliday!, $holidayId: ID!) {
  editHoliday(companyId: $companyId, data: $data, holidayId: $holidayId) {
    id
    name
    date
  }
}
    `;
export type EditHolidayMutationFn = Apollo.MutationFunction<Types.EditHolidayMutation, Types.EditHolidayMutationVariables>;

/**
 * __useEditHolidayMutation__
 *
 * To run a mutation, you first call `useEditHolidayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditHolidayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editHolidayMutation, { data, loading, error }] = useEditHolidayMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *      holidayId: // value for 'holidayId'
 *   },
 * });
 */
export function useEditHolidayMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditHolidayMutation, Types.EditHolidayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditHolidayMutation, Types.EditHolidayMutationVariables>(EditHolidayDocument, options);
      }
export type EditHolidayMutationHookResult = ReturnType<typeof useEditHolidayMutation>;
export type EditHolidayMutationResult = Apollo.MutationResult<Types.EditHolidayMutation>;
export type EditHolidayMutationOptions = Apollo.BaseMutationOptions<Types.EditHolidayMutation, Types.EditHolidayMutationVariables>;
export const EditLeaveDocument = gql`
    mutation editLeave($companyId: ID!, $leaveId: ID!, $data: ILeave!) {
  editLeave(companyId: $companyId, leaveId: $leaveId, data: $data) {
    id
    memberId
    startDate
    endDate
    leaveType {
      id
    }
    notes
  }
}
    `;
export type EditLeaveMutationFn = Apollo.MutationFunction<Types.EditLeaveMutation, Types.EditLeaveMutationVariables>;

/**
 * __useEditLeaveMutation__
 *
 * To run a mutation, you first call `useEditLeaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditLeaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editLeaveMutation, { data, loading, error }] = useEditLeaveMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      leaveId: // value for 'leaveId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditLeaveMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditLeaveMutation, Types.EditLeaveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditLeaveMutation, Types.EditLeaveMutationVariables>(EditLeaveDocument, options);
      }
export type EditLeaveMutationHookResult = ReturnType<typeof useEditLeaveMutation>;
export type EditLeaveMutationResult = Apollo.MutationResult<Types.EditLeaveMutation>;
export type EditLeaveMutationOptions = Apollo.BaseMutationOptions<Types.EditLeaveMutation, Types.EditLeaveMutationVariables>;
export const EditProjectCommissionDocument = gql`
    mutation EditProjectCommission($companyId: ID!, $data: IProjectCommission!, $projectCommissionId: ID!, $projectId: ID!) {
  editProjectCommission(
    companyId: $companyId
    data: $data
    projectCommissionId: $projectCommissionId
    projectId: $projectId
  ) {
    ...ProjectCommissionData
  }
}
    ${ProjectCommissionDataFragmentDoc}`;
export type EditProjectCommissionMutationFn = Apollo.MutationFunction<Types.EditProjectCommissionMutation, Types.EditProjectCommissionMutationVariables>;

/**
 * __useEditProjectCommissionMutation__
 *
 * To run a mutation, you first call `useEditProjectCommissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProjectCommissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProjectCommissionMutation, { data, loading, error }] = useEditProjectCommissionMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *      projectCommissionId: // value for 'projectCommissionId'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useEditProjectCommissionMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditProjectCommissionMutation, Types.EditProjectCommissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditProjectCommissionMutation, Types.EditProjectCommissionMutationVariables>(EditProjectCommissionDocument, options);
      }
export type EditProjectCommissionMutationHookResult = ReturnType<typeof useEditProjectCommissionMutation>;
export type EditProjectCommissionMutationResult = Apollo.MutationResult<Types.EditProjectCommissionMutation>;
export type EditProjectCommissionMutationOptions = Apollo.BaseMutationOptions<Types.EditProjectCommissionMutation, Types.EditProjectCommissionMutationVariables>;
export const EditProjectDocumentTagsDocument = gql`
    mutation EditProjectDocumentTags($companyId: ID!, $data: IProjectDocumentTags!) {
  editProjectDocumentTags(companyId: $companyId, data: $data) {
    id
    projectDocumentId
    tagId
    created_at
    updated_at
  }
}
    `;
export type EditProjectDocumentTagsMutationFn = Apollo.MutationFunction<Types.EditProjectDocumentTagsMutation, Types.EditProjectDocumentTagsMutationVariables>;

/**
 * __useEditProjectDocumentTagsMutation__
 *
 * To run a mutation, you first call `useEditProjectDocumentTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProjectDocumentTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProjectDocumentTagsMutation, { data, loading, error }] = useEditProjectDocumentTagsMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditProjectDocumentTagsMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditProjectDocumentTagsMutation, Types.EditProjectDocumentTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditProjectDocumentTagsMutation, Types.EditProjectDocumentTagsMutationVariables>(EditProjectDocumentTagsDocument, options);
      }
export type EditProjectDocumentTagsMutationHookResult = ReturnType<typeof useEditProjectDocumentTagsMutation>;
export type EditProjectDocumentTagsMutationResult = Apollo.MutationResult<Types.EditProjectDocumentTagsMutation>;
export type EditProjectDocumentTagsMutationOptions = Apollo.BaseMutationOptions<Types.EditProjectDocumentTagsMutation, Types.EditProjectDocumentTagsMutationVariables>;
export const EditProjectMembershipDocument = gql`
    mutation EditProjectMembership($companyId: ID!, $data: IEditProjectMembership!, $projectId: ID!, $projectMembershipId: ID!) {
  editProjectMembership(
    companyId: $companyId
    data: $data
    projectId: $projectId
    projectMembershipId: $projectMembershipId
  ) {
    ...ProjectMembershipData
  }
}
    ${ProjectMembershipDataFragmentDoc}`;
export type EditProjectMembershipMutationFn = Apollo.MutationFunction<Types.EditProjectMembershipMutation, Types.EditProjectMembershipMutationVariables>;

/**
 * __useEditProjectMembershipMutation__
 *
 * To run a mutation, you first call `useEditProjectMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProjectMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProjectMembershipMutation, { data, loading, error }] = useEditProjectMembershipMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *      projectId: // value for 'projectId'
 *      projectMembershipId: // value for 'projectMembershipId'
 *   },
 * });
 */
export function useEditProjectMembershipMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditProjectMembershipMutation, Types.EditProjectMembershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditProjectMembershipMutation, Types.EditProjectMembershipMutationVariables>(EditProjectMembershipDocument, options);
      }
export type EditProjectMembershipMutationHookResult = ReturnType<typeof useEditProjectMembershipMutation>;
export type EditProjectMembershipMutationResult = Apollo.MutationResult<Types.EditProjectMembershipMutation>;
export type EditProjectMembershipMutationOptions = Apollo.BaseMutationOptions<Types.EditProjectMembershipMutation, Types.EditProjectMembershipMutationVariables>;
export const EditProjectMilestoneDocument = gql`
    mutation EditProjectMilestone($companyId: ID!, $data: IProjectMilestone!, $projectMilestoneId: ID!) {
  editProjectMilestone(
    companyId: $companyId
    data: $data
    projectMilestoneId: $projectMilestoneId
  ) {
    ...ProjectMilestoneData
  }
}
    ${ProjectMilestoneDataFragmentDoc}`;
export type EditProjectMilestoneMutationFn = Apollo.MutationFunction<Types.EditProjectMilestoneMutation, Types.EditProjectMilestoneMutationVariables>;

/**
 * __useEditProjectMilestoneMutation__
 *
 * To run a mutation, you first call `useEditProjectMilestoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProjectMilestoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProjectMilestoneMutation, { data, loading, error }] = useEditProjectMilestoneMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *      projectMilestoneId: // value for 'projectMilestoneId'
 *   },
 * });
 */
export function useEditProjectMilestoneMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditProjectMilestoneMutation, Types.EditProjectMilestoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditProjectMilestoneMutation, Types.EditProjectMilestoneMutationVariables>(EditProjectMilestoneDocument, options);
      }
export type EditProjectMilestoneMutationHookResult = ReturnType<typeof useEditProjectMilestoneMutation>;
export type EditProjectMilestoneMutationResult = Apollo.MutationResult<Types.EditProjectMilestoneMutation>;
export type EditProjectMilestoneMutationOptions = Apollo.BaseMutationOptions<Types.EditProjectMilestoneMutation, Types.EditProjectMilestoneMutationVariables>;
export const EditProjectPmFieldDocument = gql`
    mutation EditProjectPMField($companyId: ID!, $data: IMemberProjectMembership!, $memberId: ID!) {
  editProjectPMField(companyId: $companyId, data: $data, memberId: $memberId) {
    id
    name
    color
    type
    pmFinAccess {
      accessLevel
      endDate
      startDate
    }
    pm {
      id
      first_name
      last_name
      color
    }
  }
}
    `;
export type EditProjectPmFieldMutationFn = Apollo.MutationFunction<Types.EditProjectPmFieldMutation, Types.EditProjectPmFieldMutationVariables>;

/**
 * __useEditProjectPmFieldMutation__
 *
 * To run a mutation, you first call `useEditProjectPmFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProjectPmFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProjectPmFieldMutation, { data, loading, error }] = useEditProjectPmFieldMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useEditProjectPmFieldMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditProjectPmFieldMutation, Types.EditProjectPmFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditProjectPmFieldMutation, Types.EditProjectPmFieldMutationVariables>(EditProjectPmFieldDocument, options);
      }
export type EditProjectPmFieldMutationHookResult = ReturnType<typeof useEditProjectPmFieldMutation>;
export type EditProjectPmFieldMutationResult = Apollo.MutationResult<Types.EditProjectPmFieldMutation>;
export type EditProjectPmFieldMutationOptions = Apollo.BaseMutationOptions<Types.EditProjectPmFieldMutation, Types.EditProjectPmFieldMutationVariables>;
export const EditMemberRoleDocument = gql`
    mutation EditMemberRole($companyId: ID!, $memberRoleId: ID!, $data: IRole!) {
  editRole(companyId: $companyId, memberRoleId: $memberRoleId, data: $data) {
    id
  }
}
    `;
export type EditMemberRoleMutationFn = Apollo.MutationFunction<Types.EditMemberRoleMutation, Types.EditMemberRoleMutationVariables>;

/**
 * __useEditMemberRoleMutation__
 *
 * To run a mutation, you first call `useEditMemberRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMemberRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMemberRoleMutation, { data, loading, error }] = useEditMemberRoleMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      memberRoleId: // value for 'memberRoleId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditMemberRoleMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditMemberRoleMutation, Types.EditMemberRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditMemberRoleMutation, Types.EditMemberRoleMutationVariables>(EditMemberRoleDocument, options);
      }
export type EditMemberRoleMutationHookResult = ReturnType<typeof useEditMemberRoleMutation>;
export type EditMemberRoleMutationResult = Apollo.MutationResult<Types.EditMemberRoleMutation>;
export type EditMemberRoleMutationOptions = Apollo.BaseMutationOptions<Types.EditMemberRoleMutation, Types.EditMemberRoleMutationVariables>;
export const EditReportDocument = gql`
    mutation EditReport($companyId: ID!, $data: IEditReport!, $projectId: ID!, $reportId: ID!) {
  editReport(
    companyId: $companyId
    data: $data
    projectId: $projectId
    reportId: $reportId
  ) {
    ...ReportData
  }
}
    ${ReportDataFragmentDoc}`;
export type EditReportMutationFn = Apollo.MutationFunction<Types.EditReportMutation, Types.EditReportMutationVariables>;

/**
 * __useEditReportMutation__
 *
 * To run a mutation, you first call `useEditReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editReportMutation, { data, loading, error }] = useEditReportMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *      projectId: // value for 'projectId'
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useEditReportMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditReportMutation, Types.EditReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditReportMutation, Types.EditReportMutationVariables>(EditReportDocument, options);
      }
export type EditReportMutationHookResult = ReturnType<typeof useEditReportMutation>;
export type EditReportMutationResult = Apollo.MutationResult<Types.EditReportMutation>;
export type EditReportMutationOptions = Apollo.BaseMutationOptions<Types.EditReportMutation, Types.EditReportMutationVariables>;
export const EditReportMemberLogsDocument = gql`
    mutation EditReportMemberLogs($companyId: ID!, $data: IReportMemberLogs!, $reportMemberId: ID!) {
  editReportMemberLogs(
    companyId: $companyId
    data: $data
    reportMemberId: $reportMemberId
  ) {
    id
    reportMemberEntity {
      ...ReportMemberEntityData
    }
  }
}
    ${ReportMemberEntityDataFragmentDoc}`;
export type EditReportMemberLogsMutationFn = Apollo.MutationFunction<Types.EditReportMemberLogsMutation, Types.EditReportMemberLogsMutationVariables>;

/**
 * __useEditReportMemberLogsMutation__
 *
 * To run a mutation, you first call `useEditReportMemberLogsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditReportMemberLogsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editReportMemberLogsMutation, { data, loading, error }] = useEditReportMemberLogsMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *      reportMemberId: // value for 'reportMemberId'
 *   },
 * });
 */
export function useEditReportMemberLogsMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditReportMemberLogsMutation, Types.EditReportMemberLogsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditReportMemberLogsMutation, Types.EditReportMemberLogsMutationVariables>(EditReportMemberLogsDocument, options);
      }
export type EditReportMemberLogsMutationHookResult = ReturnType<typeof useEditReportMemberLogsMutation>;
export type EditReportMemberLogsMutationResult = Apollo.MutationResult<Types.EditReportMemberLogsMutation>;
export type EditReportMemberLogsMutationOptions = Apollo.BaseMutationOptions<Types.EditReportMemberLogsMutation, Types.EditReportMemberLogsMutationVariables>;
export const EditReportBillableDaysDocument = gql`
    mutation EditReportBillableDays($companyId: ID!, $data: [IReportBillableDays!]!, $reportId: ID!) {
  editReportBillableDays(companyId: $companyId, data: $data, reportId: $reportId) {
    ...ReportMemberData
  }
}
    ${ReportMemberDataFragmentDoc}`;
export type EditReportBillableDaysMutationFn = Apollo.MutationFunction<Types.EditReportBillableDaysMutation, Types.EditReportBillableDaysMutationVariables>;

/**
 * __useEditReportBillableDaysMutation__
 *
 * To run a mutation, you first call `useEditReportBillableDaysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditReportBillableDaysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editReportBillableDaysMutation, { data, loading, error }] = useEditReportBillableDaysMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useEditReportBillableDaysMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditReportBillableDaysMutation, Types.EditReportBillableDaysMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditReportBillableDaysMutation, Types.EditReportBillableDaysMutationVariables>(EditReportBillableDaysDocument, options);
      }
export type EditReportBillableDaysMutationHookResult = ReturnType<typeof useEditReportBillableDaysMutation>;
export type EditReportBillableDaysMutationResult = Apollo.MutationResult<Types.EditReportBillableDaysMutation>;
export type EditReportBillableDaysMutationOptions = Apollo.BaseMutationOptions<Types.EditReportBillableDaysMutation, Types.EditReportBillableDaysMutationVariables>;
export const EditReportMembersDocument = gql`
    mutation EditReportMembers($companyId: ID!, $reportId: ID!, $includedMembers: [ID!]!) {
  editReportMembers(
    companyId: $companyId
    reportId: $reportId
    includedMembers: $includedMembers
  ) {
    ...ReportMemberData
  }
}
    ${ReportMemberDataFragmentDoc}`;
export type EditReportMembersMutationFn = Apollo.MutationFunction<Types.EditReportMembersMutation, Types.EditReportMembersMutationVariables>;

/**
 * __useEditReportMembersMutation__
 *
 * To run a mutation, you first call `useEditReportMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditReportMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editReportMembersMutation, { data, loading, error }] = useEditReportMembersMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      reportId: // value for 'reportId'
 *      includedMembers: // value for 'includedMembers'
 *   },
 * });
 */
export function useEditReportMembersMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditReportMembersMutation, Types.EditReportMembersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditReportMembersMutation, Types.EditReportMembersMutationVariables>(EditReportMembersDocument, options);
      }
export type EditReportMembersMutationHookResult = ReturnType<typeof useEditReportMembersMutation>;
export type EditReportMembersMutationResult = Apollo.MutationResult<Types.EditReportMembersMutation>;
export type EditReportMembersMutationOptions = Apollo.BaseMutationOptions<Types.EditReportMembersMutation, Types.EditReportMembersMutationVariables>;
export const EditRequestDocument = gql`
    mutation EditRequest($companyId: ID!, $data: ICreateRequest!, $requestId: ID!) {
  editRequest(companyId: $companyId, data: $data, requestId: $requestId) {
    ...RequestData
  }
}
    ${RequestDataFragmentDoc}`;
export type EditRequestMutationFn = Apollo.MutationFunction<Types.EditRequestMutation, Types.EditRequestMutationVariables>;

/**
 * __useEditRequestMutation__
 *
 * To run a mutation, you first call `useEditRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRequestMutation, { data, loading, error }] = useEditRequestMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useEditRequestMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditRequestMutation, Types.EditRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditRequestMutation, Types.EditRequestMutationVariables>(EditRequestDocument, options);
      }
export type EditRequestMutationHookResult = ReturnType<typeof useEditRequestMutation>;
export type EditRequestMutationResult = Apollo.MutationResult<Types.EditRequestMutation>;
export type EditRequestMutationOptions = Apollo.BaseMutationOptions<Types.EditRequestMutation, Types.EditRequestMutationVariables>;
export const EditRequestsStatusDocument = gql`
    mutation EditRequestsStatus($companyId: ID!, $data: IRequest!, $requestsId: [ID!]!) {
  editRequestsStatus(companyId: $companyId, data: $data, requestsId: $requestsId) {
    ...RequestData
  }
}
    ${RequestDataFragmentDoc}`;
export type EditRequestsStatusMutationFn = Apollo.MutationFunction<Types.EditRequestsStatusMutation, Types.EditRequestsStatusMutationVariables>;

/**
 * __useEditRequestsStatusMutation__
 *
 * To run a mutation, you first call `useEditRequestsStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRequestsStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRequestsStatusMutation, { data, loading, error }] = useEditRequestsStatusMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *      requestsId: // value for 'requestsId'
 *   },
 * });
 */
export function useEditRequestsStatusMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditRequestsStatusMutation, Types.EditRequestsStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditRequestsStatusMutation, Types.EditRequestsStatusMutationVariables>(EditRequestsStatusDocument, options);
      }
export type EditRequestsStatusMutationHookResult = ReturnType<typeof useEditRequestsStatusMutation>;
export type EditRequestsStatusMutationResult = Apollo.MutationResult<Types.EditRequestsStatusMutation>;
export type EditRequestsStatusMutationOptions = Apollo.BaseMutationOptions<Types.EditRequestsStatusMutation, Types.EditRequestsStatusMutationVariables>;
export const EditSeniorityDocument = gql`
    mutation EditSeniority($companyId: ID!, $seniorityId: ID!, $data: IMemberSeniority!) {
  editSeniority(companyId: $companyId, seniorityId: $seniorityId, data: $data) {
    id
  }
}
    `;
export type EditSeniorityMutationFn = Apollo.MutationFunction<Types.EditSeniorityMutation, Types.EditSeniorityMutationVariables>;

/**
 * __useEditSeniorityMutation__
 *
 * To run a mutation, you first call `useEditSeniorityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSeniorityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSeniorityMutation, { data, loading, error }] = useEditSeniorityMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      seniorityId: // value for 'seniorityId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditSeniorityMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditSeniorityMutation, Types.EditSeniorityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditSeniorityMutation, Types.EditSeniorityMutationVariables>(EditSeniorityDocument, options);
      }
export type EditSeniorityMutationHookResult = ReturnType<typeof useEditSeniorityMutation>;
export type EditSeniorityMutationResult = Apollo.MutationResult<Types.EditSeniorityMutation>;
export type EditSeniorityMutationOptions = Apollo.BaseMutationOptions<Types.EditSeniorityMutation, Types.EditSeniorityMutationVariables>;
export const EditMemberSpecializationDocument = gql`
    mutation EditMemberSpecialization($companyId: ID!, $memberSpecializationId: ID!, $data: ISpecialization!) {
  editSpecialization(
    companyId: $companyId
    memberSpecializationId: $memberSpecializationId
    data: $data
  ) {
    id
  }
}
    `;
export type EditMemberSpecializationMutationFn = Apollo.MutationFunction<Types.EditMemberSpecializationMutation, Types.EditMemberSpecializationMutationVariables>;

/**
 * __useEditMemberSpecializationMutation__
 *
 * To run a mutation, you first call `useEditMemberSpecializationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMemberSpecializationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMemberSpecializationMutation, { data, loading, error }] = useEditMemberSpecializationMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      memberSpecializationId: // value for 'memberSpecializationId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditMemberSpecializationMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditMemberSpecializationMutation, Types.EditMemberSpecializationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditMemberSpecializationMutation, Types.EditMemberSpecializationMutationVariables>(EditMemberSpecializationDocument, options);
      }
export type EditMemberSpecializationMutationHookResult = ReturnType<typeof useEditMemberSpecializationMutation>;
export type EditMemberSpecializationMutationResult = Apollo.MutationResult<Types.EditMemberSpecializationMutation>;
export type EditMemberSpecializationMutationOptions = Apollo.BaseMutationOptions<Types.EditMemberSpecializationMutation, Types.EditMemberSpecializationMutationVariables>;
export const EditTagDocument = gql`
    mutation EditTag($companyId: ID!, $tagId: ID!, $data: ITag!) {
  editTag(companyId: $companyId, tagId: $tagId, data: $data) {
    ...TagData
  }
}
    ${TagDataFragmentDoc}`;
export type EditTagMutationFn = Apollo.MutationFunction<Types.EditTagMutation, Types.EditTagMutationVariables>;

/**
 * __useEditTagMutation__
 *
 * To run a mutation, you first call `useEditTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTagMutation, { data, loading, error }] = useEditTagMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      tagId: // value for 'tagId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditTagMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditTagMutation, Types.EditTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditTagMutation, Types.EditTagMutationVariables>(EditTagDocument, options);
      }
export type EditTagMutationHookResult = ReturnType<typeof useEditTagMutation>;
export type EditTagMutationResult = Apollo.MutationResult<Types.EditTagMutation>;
export type EditTagMutationOptions = Apollo.BaseMutationOptions<Types.EditTagMutation, Types.EditTagMutationVariables>;
export const EditTeamMemberDocument = gql`
    mutation EditTeamMember($companyId: ID!, $memberId: ID!, $data: IMember!) {
  editTeamMember(companyId: $companyId, memberId: $memberId, data: $data) {
    id
    first_name
    last_name
    email
    employment_type
    job_title
    memberSpecialization
    memberSpecializationId
    member_role {
      id
      name
    }
    job_title
    member_seniority {
      id
      name
    }
    capacity
    costRateManagers {
      id
      first_name
      last_name
    }
    reportingToId
    reportingTo {
      id
      first_name
      last_name
    }
    join_date
    exit_date
    created_at
    updated_at
  }
}
    `;
export type EditTeamMemberMutationFn = Apollo.MutationFunction<Types.EditTeamMemberMutation, Types.EditTeamMemberMutationVariables>;

/**
 * __useEditTeamMemberMutation__
 *
 * To run a mutation, you first call `useEditTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTeamMemberMutation, { data, loading, error }] = useEditTeamMemberMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      memberId: // value for 'memberId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditTeamMemberMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditTeamMemberMutation, Types.EditTeamMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditTeamMemberMutation, Types.EditTeamMemberMutationVariables>(EditTeamMemberDocument, options);
      }
export type EditTeamMemberMutationHookResult = ReturnType<typeof useEditTeamMemberMutation>;
export type EditTeamMemberMutationResult = Apollo.MutationResult<Types.EditTeamMemberMutation>;
export type EditTeamMemberMutationOptions = Apollo.BaseMutationOptions<Types.EditTeamMemberMutation, Types.EditTeamMemberMutationVariables>;
export const EditTeamMembersPmDocument = gql`
    mutation EditTeamMembersPM($companyId: ID!, $data: IMembersPM!) {
  editTeamMembersPM(companyId: $companyId, data: $data) {
    id
  }
}
    `;
export type EditTeamMembersPmMutationFn = Apollo.MutationFunction<Types.EditTeamMembersPmMutation, Types.EditTeamMembersPmMutationVariables>;

/**
 * __useEditTeamMembersPmMutation__
 *
 * To run a mutation, you first call `useEditTeamMembersPmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTeamMembersPmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTeamMembersPmMutation, { data, loading, error }] = useEditTeamMembersPmMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditTeamMembersPmMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditTeamMembersPmMutation, Types.EditTeamMembersPmMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditTeamMembersPmMutation, Types.EditTeamMembersPmMutationVariables>(EditTeamMembersPmDocument, options);
      }
export type EditTeamMembersPmMutationHookResult = ReturnType<typeof useEditTeamMembersPmMutation>;
export type EditTeamMembersPmMutationResult = Apollo.MutationResult<Types.EditTeamMembersPmMutation>;
export type EditTeamMembersPmMutationOptions = Apollo.BaseMutationOptions<Types.EditTeamMembersPmMutation, Types.EditTeamMembersPmMutationVariables>;
export const ImportDataDocument = gql`
    mutation ImportData($companyId: ID!, $data: IImportData!) {
  importData(companyId: $companyId, data: $data) {
    file
    message
    success
  }
}
    `;
export type ImportDataMutationFn = Apollo.MutationFunction<Types.ImportDataMutation, Types.ImportDataMutationVariables>;

/**
 * __useImportDataMutation__
 *
 * To run a mutation, you first call `useImportDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importDataMutation, { data, loading, error }] = useImportDataMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useImportDataMutation(baseOptions?: Apollo.MutationHookOptions<Types.ImportDataMutation, Types.ImportDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ImportDataMutation, Types.ImportDataMutationVariables>(ImportDataDocument, options);
      }
export type ImportDataMutationHookResult = ReturnType<typeof useImportDataMutation>;
export type ImportDataMutationResult = Apollo.MutationResult<Types.ImportDataMutation>;
export type ImportDataMutationOptions = Apollo.BaseMutationOptions<Types.ImportDataMutation, Types.ImportDataMutationVariables>;
export const InviteUserDocument = gql`
    mutation InviteUser($companyId: ID!, $email: String!, $role: EditableMemberType!) {
  invite(companyId: $companyId, email: $email, role: $role) {
    id
    email
    status
    created_at
    updated_at
  }
}
    `;
export type InviteUserMutationFn = Apollo.MutationFunction<Types.InviteUserMutation, Types.InviteUserMutationVariables>;

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      email: // value for 'email'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useInviteUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.InviteUserMutation, Types.InviteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InviteUserMutation, Types.InviteUserMutationVariables>(InviteUserDocument, options);
      }
export type InviteUserMutationHookResult = ReturnType<typeof useInviteUserMutation>;
export type InviteUserMutationResult = Apollo.MutationResult<Types.InviteUserMutation>;
export type InviteUserMutationOptions = Apollo.BaseMutationOptions<Types.InviteUserMutation, Types.InviteUserMutationVariables>;
export const AcceptInvitationDocument = gql`
    mutation AcceptInvitation($id: ID!, $secret: String!, $password: String!, $firstName: String!, $lastName: String!) {
  acceptInvitation(
    id: $id
    secret: $secret
    password: $password
    first_name: $firstName
    last_name: $lastName
  ) {
    token
    me {
      id
      member {
        id
        email
        first_name
        last_name
      }
      company {
        id
        name
      }
    }
  }
}
    `;
export type AcceptInvitationMutationFn = Apollo.MutationFunction<Types.AcceptInvitationMutation, Types.AcceptInvitationMutationVariables>;

/**
 * __useAcceptInvitationMutation__
 *
 * To run a mutation, you first call `useAcceptInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInvitationMutation, { data, loading, error }] = useAcceptInvitationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      secret: // value for 'secret'
 *      password: // value for 'password'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *   },
 * });
 */
export function useAcceptInvitationMutation(baseOptions?: Apollo.MutationHookOptions<Types.AcceptInvitationMutation, Types.AcceptInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AcceptInvitationMutation, Types.AcceptInvitationMutationVariables>(AcceptInvitationDocument, options);
      }
export type AcceptInvitationMutationHookResult = ReturnType<typeof useAcceptInvitationMutation>;
export type AcceptInvitationMutationResult = Apollo.MutationResult<Types.AcceptInvitationMutation>;
export type AcceptInvitationMutationOptions = Apollo.BaseMutationOptions<Types.AcceptInvitationMutation, Types.AcceptInvitationMutationVariables>;
export const ManualCompanyIntegrationSyncDocument = gql`
    mutation ManualCompanyIntegrationSync($companyId: ID!, $companyIntegrationId: ID!) {
  manualCompanyIntegrationSync(
    companyId: $companyId
    companyIntegrationId: $companyIntegrationId
  ) {
    ...CompanyIntegrationSyncData
  }
}
    ${CompanyIntegrationSyncDataFragmentDoc}`;
export type ManualCompanyIntegrationSyncMutationFn = Apollo.MutationFunction<Types.ManualCompanyIntegrationSyncMutation, Types.ManualCompanyIntegrationSyncMutationVariables>;

/**
 * __useManualCompanyIntegrationSyncMutation__
 *
 * To run a mutation, you first call `useManualCompanyIntegrationSyncMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManualCompanyIntegrationSyncMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manualCompanyIntegrationSyncMutation, { data, loading, error }] = useManualCompanyIntegrationSyncMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      companyIntegrationId: // value for 'companyIntegrationId'
 *   },
 * });
 */
export function useManualCompanyIntegrationSyncMutation(baseOptions?: Apollo.MutationHookOptions<Types.ManualCompanyIntegrationSyncMutation, Types.ManualCompanyIntegrationSyncMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ManualCompanyIntegrationSyncMutation, Types.ManualCompanyIntegrationSyncMutationVariables>(ManualCompanyIntegrationSyncDocument, options);
      }
export type ManualCompanyIntegrationSyncMutationHookResult = ReturnType<typeof useManualCompanyIntegrationSyncMutation>;
export type ManualCompanyIntegrationSyncMutationResult = Apollo.MutationResult<Types.ManualCompanyIntegrationSyncMutation>;
export type ManualCompanyIntegrationSyncMutationOptions = Apollo.BaseMutationOptions<Types.ManualCompanyIntegrationSyncMutation, Types.ManualCompanyIntegrationSyncMutationVariables>;
export const EditMemberDocument = gql`
    mutation EditMember($memberId: ID!, $companyId: ID!, $role: EditableMemberType!) {
  editMember(memberId: $memberId, companyId: $companyId, role: $role) {
    ...MemberData
  }
}
    ${MemberDataFragmentDoc}`;
export type EditMemberMutationFn = Apollo.MutationFunction<Types.EditMemberMutation, Types.EditMemberMutationVariables>;

/**
 * __useEditMemberMutation__
 *
 * To run a mutation, you first call `useEditMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMemberMutation, { data, loading, error }] = useEditMemberMutation({
 *   variables: {
 *      memberId: // value for 'memberId'
 *      companyId: // value for 'companyId'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useEditMemberMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditMemberMutation, Types.EditMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditMemberMutation, Types.EditMemberMutationVariables>(EditMemberDocument, options);
      }
export type EditMemberMutationHookResult = ReturnType<typeof useEditMemberMutation>;
export type EditMemberMutationResult = Apollo.MutationResult<Types.EditMemberMutation>;
export type EditMemberMutationOptions = Apollo.BaseMutationOptions<Types.EditMemberMutation, Types.EditMemberMutationVariables>;
export const CreateProjectDocument = gql`
    mutation CreateProject($companyId: ID!, $data: IProject!, $withCommission: Boolean!) {
  createProject(companyId: $companyId, data: $data) {
    id
    name
    client {
      id
      name
    }
    pm {
      id
      first_name
      last_name
    }
    type
    unit
    pmFinAccess {
      ...PmProjectMembershipData
    }
    cost_budget_amount
    start_date
    end_date
    created_at
    updated_at
    currentCommission(withCommission: $withCommission)
  }
}
    ${PmProjectMembershipDataFragmentDoc}`;
export type CreateProjectMutationFn = Apollo.MutationFunction<Types.CreateProjectMutation, Types.CreateProjectMutationVariables>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *      withCommission: // value for 'withCommission'
 *   },
 * });
 */
export function useCreateProjectMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateProjectMutation, Types.CreateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateProjectMutation, Types.CreateProjectMutationVariables>(CreateProjectDocument, options);
      }
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;
export type CreateProjectMutationResult = Apollo.MutationResult<Types.CreateProjectMutation>;
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<Types.CreateProjectMutation, Types.CreateProjectMutationVariables>;
export const RefreshInvitationDocument = gql`
    mutation RefreshInvitation($companyId: ID!, $id: String!) {
  refreshInvitation(companyId: $companyId, id: $id) {
    id
  }
}
    `;
export type RefreshInvitationMutationFn = Apollo.MutationFunction<Types.RefreshInvitationMutation, Types.RefreshInvitationMutationVariables>;

/**
 * __useRefreshInvitationMutation__
 *
 * To run a mutation, you first call `useRefreshInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshInvitationMutation, { data, loading, error }] = useRefreshInvitationMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRefreshInvitationMutation(baseOptions?: Apollo.MutationHookOptions<Types.RefreshInvitationMutation, Types.RefreshInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RefreshInvitationMutation, Types.RefreshInvitationMutationVariables>(RefreshInvitationDocument, options);
      }
export type RefreshInvitationMutationHookResult = ReturnType<typeof useRefreshInvitationMutation>;
export type RefreshInvitationMutationResult = Apollo.MutationResult<Types.RefreshInvitationMutation>;
export type RefreshInvitationMutationOptions = Apollo.BaseMutationOptions<Types.RefreshInvitationMutation, Types.RefreshInvitationMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($email: String!) {
  resetPassword(email: $email)
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<Types.ResetPasswordMutation, Types.ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<Types.ResetPasswordMutation, Types.ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ResetPasswordMutation, Types.ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<Types.ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<Types.ResetPasswordMutation, Types.ResetPasswordMutationVariables>;
export const RestoreMemberDocument = gql`
    mutation RestoreMember($companyId: ID!, $memberId: ID!) {
  restoreMember(companyId: $companyId, memberId: $memberId) {
    id
  }
}
    `;
export type RestoreMemberMutationFn = Apollo.MutationFunction<Types.RestoreMemberMutation, Types.RestoreMemberMutationVariables>;

/**
 * __useRestoreMemberMutation__
 *
 * To run a mutation, you first call `useRestoreMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreMemberMutation, { data, loading, error }] = useRestoreMemberMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useRestoreMemberMutation(baseOptions?: Apollo.MutationHookOptions<Types.RestoreMemberMutation, Types.RestoreMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RestoreMemberMutation, Types.RestoreMemberMutationVariables>(RestoreMemberDocument, options);
      }
export type RestoreMemberMutationHookResult = ReturnType<typeof useRestoreMemberMutation>;
export type RestoreMemberMutationResult = Apollo.MutationResult<Types.RestoreMemberMutation>;
export type RestoreMemberMutationOptions = Apollo.BaseMutationOptions<Types.RestoreMemberMutation, Types.RestoreMemberMutationVariables>;
export const RestoreProjectDocument = gql`
    mutation RestoreProject($companyId: ID!, $projectId: ID!) {
  restoreProject(companyId: $companyId, projectId: $projectId) {
    id
  }
}
    `;
export type RestoreProjectMutationFn = Apollo.MutationFunction<Types.RestoreProjectMutation, Types.RestoreProjectMutationVariables>;

/**
 * __useRestoreProjectMutation__
 *
 * To run a mutation, you first call `useRestoreProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreProjectMutation, { data, loading, error }] = useRestoreProjectMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useRestoreProjectMutation(baseOptions?: Apollo.MutationHookOptions<Types.RestoreProjectMutation, Types.RestoreProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RestoreProjectMutation, Types.RestoreProjectMutationVariables>(RestoreProjectDocument, options);
      }
export type RestoreProjectMutationHookResult = ReturnType<typeof useRestoreProjectMutation>;
export type RestoreProjectMutationResult = Apollo.MutationResult<Types.RestoreProjectMutation>;
export type RestoreProjectMutationOptions = Apollo.BaseMutationOptions<Types.RestoreProjectMutation, Types.RestoreProjectMutationVariables>;
export const RestoreUserDocument = gql`
    mutation RestoreUser($companyId: ID!, $memberId: ID!) {
  restoreUser(companyId: $companyId, memberId: $memberId) {
    id
    deactivatedAt
    status
  }
}
    `;
export type RestoreUserMutationFn = Apollo.MutationFunction<Types.RestoreUserMutation, Types.RestoreUserMutationVariables>;

/**
 * __useRestoreUserMutation__
 *
 * To run a mutation, you first call `useRestoreUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreUserMutation, { data, loading, error }] = useRestoreUserMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useRestoreUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.RestoreUserMutation, Types.RestoreUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RestoreUserMutation, Types.RestoreUserMutationVariables>(RestoreUserDocument, options);
      }
export type RestoreUserMutationHookResult = ReturnType<typeof useRestoreUserMutation>;
export type RestoreUserMutationResult = Apollo.MutationResult<Types.RestoreUserMutation>;
export type RestoreUserMutationOptions = Apollo.BaseMutationOptions<Types.RestoreUserMutation, Types.RestoreUserMutationVariables>;
export const SetReportCustomizationDocument = gql`
    mutation SetReportCustomization($companyId: ID!, $data: IReportCustomization!, $projectId: ID!, $reportId: ID!) {
  setReportCustomization(
    companyId: $companyId
    data: $data
    projectId: $projectId
    reportId: $reportId
  ) {
    billableDaysNotation
    created_at
    id
    leavesNotation
    leaveTypesDisplay
    projectId
    reportColumns
  }
}
    `;
export type SetReportCustomizationMutationFn = Apollo.MutationFunction<Types.SetReportCustomizationMutation, Types.SetReportCustomizationMutationVariables>;

/**
 * __useSetReportCustomizationMutation__
 *
 * To run a mutation, you first call `useSetReportCustomizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetReportCustomizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setReportCustomizationMutation, { data, loading, error }] = useSetReportCustomizationMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *      projectId: // value for 'projectId'
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useSetReportCustomizationMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetReportCustomizationMutation, Types.SetReportCustomizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetReportCustomizationMutation, Types.SetReportCustomizationMutationVariables>(SetReportCustomizationDocument, options);
      }
export type SetReportCustomizationMutationHookResult = ReturnType<typeof useSetReportCustomizationMutation>;
export type SetReportCustomizationMutationResult = Apollo.MutationResult<Types.SetReportCustomizationMutation>;
export type SetReportCustomizationMutationOptions = Apollo.BaseMutationOptions<Types.SetReportCustomizationMutation, Types.SetReportCustomizationMutationVariables>;
export const SetUpReportExpensesDocument = gql`
    mutation SetUpReportExpenses($companyId: ID!, $expensesId: [ID!]!, $reportId: ID!) {
  setUpReportExpenses(
    companyId: $companyId
    expensesId: $expensesId
    reportId: $reportId
  ) {
    ...ExpenseData
  }
}
    ${ExpenseDataFragmentDoc}`;
export type SetUpReportExpensesMutationFn = Apollo.MutationFunction<Types.SetUpReportExpensesMutation, Types.SetUpReportExpensesMutationVariables>;

/**
 * __useSetUpReportExpensesMutation__
 *
 * To run a mutation, you first call `useSetUpReportExpensesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUpReportExpensesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUpReportExpensesMutation, { data, loading, error }] = useSetUpReportExpensesMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      expensesId: // value for 'expensesId'
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useSetUpReportExpensesMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetUpReportExpensesMutation, Types.SetUpReportExpensesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetUpReportExpensesMutation, Types.SetUpReportExpensesMutationVariables>(SetUpReportExpensesDocument, options);
      }
export type SetUpReportExpensesMutationHookResult = ReturnType<typeof useSetUpReportExpensesMutation>;
export type SetUpReportExpensesMutationResult = Apollo.MutationResult<Types.SetUpReportExpensesMutation>;
export type SetUpReportExpensesMutationOptions = Apollo.BaseMutationOptions<Types.SetUpReportExpensesMutation, Types.SetUpReportExpensesMutationVariables>;
export const SetUpReportMilestonesDocument = gql`
    mutation SetUpReportMilestones($companyId: ID!, $milestonesId: [ID!]!, $reportId: ID!) {
  setUpReportMilestones(
    companyId: $companyId
    milestonesId: $milestonesId
    reportId: $reportId
  ) {
    ...ProjectMilestoneData
  }
}
    ${ProjectMilestoneDataFragmentDoc}`;
export type SetUpReportMilestonesMutationFn = Apollo.MutationFunction<Types.SetUpReportMilestonesMutation, Types.SetUpReportMilestonesMutationVariables>;

/**
 * __useSetUpReportMilestonesMutation__
 *
 * To run a mutation, you first call `useSetUpReportMilestonesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUpReportMilestonesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUpReportMilestonesMutation, { data, loading, error }] = useSetUpReportMilestonesMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      milestonesId: // value for 'milestonesId'
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useSetUpReportMilestonesMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetUpReportMilestonesMutation, Types.SetUpReportMilestonesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetUpReportMilestonesMutation, Types.SetUpReportMilestonesMutationVariables>(SetUpReportMilestonesDocument, options);
      }
export type SetUpReportMilestonesMutationHookResult = ReturnType<typeof useSetUpReportMilestonesMutation>;
export type SetUpReportMilestonesMutationResult = Apollo.MutationResult<Types.SetUpReportMilestonesMutation>;
export type SetUpReportMilestonesMutationOptions = Apollo.BaseMutationOptions<Types.SetUpReportMilestonesMutation, Types.SetUpReportMilestonesMutationVariables>;
export const CreateMemberDocument = gql`
    mutation CreateMember($companyId: ID!, $data: IMember!) {
  createMember(companyId: $companyId, data: $data) {
    id
    email
    first_name
    last_name
    status
    employment_type
    job_title
    member_seniority {
      id
    }
    memberSpecializationId
    reportingToId
    join_date
    created_at
    updated_at
  }
}
    `;
export type CreateMemberMutationFn = Apollo.MutationFunction<Types.CreateMemberMutation, Types.CreateMemberMutationVariables>;

/**
 * __useCreateMemberMutation__
 *
 * To run a mutation, you first call `useCreateMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMemberMutation, { data, loading, error }] = useCreateMemberMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateMemberMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateMemberMutation, Types.CreateMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateMemberMutation, Types.CreateMemberMutationVariables>(CreateMemberDocument, options);
      }
export type CreateMemberMutationHookResult = ReturnType<typeof useCreateMemberMutation>;
export type CreateMemberMutationResult = Apollo.MutationResult<Types.CreateMemberMutation>;
export type CreateMemberMutationOptions = Apollo.BaseMutationOptions<Types.CreateMemberMutation, Types.CreateMemberMutationVariables>;
export const UploadFeedbackFileDocument = gql`
    mutation UploadFeedbackFile($file: Upload!) {
  uploadFeedbackFile(file: $file)
}
    `;
export type UploadFeedbackFileMutationFn = Apollo.MutationFunction<Types.UploadFeedbackFileMutation, Types.UploadFeedbackFileMutationVariables>;

/**
 * __useUploadFeedbackFileMutation__
 *
 * To run a mutation, you first call `useUploadFeedbackFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFeedbackFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFeedbackFileMutation, { data, loading, error }] = useUploadFeedbackFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadFeedbackFileMutation(baseOptions?: Apollo.MutationHookOptions<Types.UploadFeedbackFileMutation, Types.UploadFeedbackFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UploadFeedbackFileMutation, Types.UploadFeedbackFileMutationVariables>(UploadFeedbackFileDocument, options);
      }
export type UploadFeedbackFileMutationHookResult = ReturnType<typeof useUploadFeedbackFileMutation>;
export type UploadFeedbackFileMutationResult = Apollo.MutationResult<Types.UploadFeedbackFileMutation>;
export type UploadFeedbackFileMutationOptions = Apollo.BaseMutationOptions<Types.UploadFeedbackFileMutation, Types.UploadFeedbackFileMutationVariables>;
export const ActivityHistoriesDocument = gql`
    query ActivityHistories($companyId: ID!, $historyFilter: HistoryFilter, $limit: Int, $offset: Int) {
  activityHistories(
    companyId: $companyId
    historyFilter: $historyFilter
    limit: $limit
    offset: $offset
  ) {
    ...ActivityHistoriesData
  }
}
    ${ActivityHistoriesDataFragmentDoc}`;

/**
 * __useActivityHistoriesQuery__
 *
 * To run a query within a React component, call `useActivityHistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityHistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityHistoriesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      historyFilter: // value for 'historyFilter'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useActivityHistoriesQuery(baseOptions: Apollo.QueryHookOptions<Types.ActivityHistoriesQuery, Types.ActivityHistoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ActivityHistoriesQuery, Types.ActivityHistoriesQueryVariables>(ActivityHistoriesDocument, options);
      }
export function useActivityHistoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ActivityHistoriesQuery, Types.ActivityHistoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ActivityHistoriesQuery, Types.ActivityHistoriesQueryVariables>(ActivityHistoriesDocument, options);
        }
export type ActivityHistoriesQueryHookResult = ReturnType<typeof useActivityHistoriesQuery>;
export type ActivityHistoriesLazyQueryHookResult = ReturnType<typeof useActivityHistoriesLazyQuery>;
export type ActivityHistoriesQueryResult = Apollo.QueryResult<Types.ActivityHistoriesQuery, Types.ActivityHistoriesQueryVariables>;
export const CountActivityHistoryLogsDocument = gql`
    query CountActivityHistoryLogs($companyId: ID!, $historyFilter: HistoryFilter) {
  countActivityHistoryLogs(companyId: $companyId, historyFilter: $historyFilter)
}
    `;

/**
 * __useCountActivityHistoryLogsQuery__
 *
 * To run a query within a React component, call `useCountActivityHistoryLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountActivityHistoryLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountActivityHistoryLogsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      historyFilter: // value for 'historyFilter'
 *   },
 * });
 */
export function useCountActivityHistoryLogsQuery(baseOptions: Apollo.QueryHookOptions<Types.CountActivityHistoryLogsQuery, Types.CountActivityHistoryLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CountActivityHistoryLogsQuery, Types.CountActivityHistoryLogsQueryVariables>(CountActivityHistoryLogsDocument, options);
      }
export function useCountActivityHistoryLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CountActivityHistoryLogsQuery, Types.CountActivityHistoryLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CountActivityHistoryLogsQuery, Types.CountActivityHistoryLogsQueryVariables>(CountActivityHistoryLogsDocument, options);
        }
export type CountActivityHistoryLogsQueryHookResult = ReturnType<typeof useCountActivityHistoryLogsQuery>;
export type CountActivityHistoryLogsLazyQueryHookResult = ReturnType<typeof useCountActivityHistoryLogsLazyQuery>;
export type CountActivityHistoryLogsQueryResult = Apollo.QueryResult<Types.CountActivityHistoryLogsQuery, Types.CountActivityHistoryLogsQueryVariables>;
export const MembersDocument = gql`
    query members($companyId: ID!, $paginationAssignmentData: IPaginationAssignment) {
  members(
    companyId: $companyId
    paginationAssignmentData: $paginationAssignmentData
  ) {
    id
    first_name
    last_name
    job_title
    reportingToId
    archived_at
    color
    leaves {
      id
      memberId
      memberFirstName
      memberLastName
      startDate
      endDate
      leaveType {
        id
      }
      notes
    }
    costRateManagersIds
    assignments {
      id
      project {
        id
        name
        type
        pm {
          id
          first_name
          last_name
          color
        }
      }
      member {
        id
        first_name
        last_name
        job_title
        color
        leaves {
          id
          memberId
          startDate
          endDate
        }
      }
      role {
        id
        name
      }
      startDate
      endDate
      allocationTimeAmount
    }
  }
}
    `;

/**
 * __useMembersQuery__
 *
 * To run a query within a React component, call `useMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembersQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      paginationAssignmentData: // value for 'paginationAssignmentData'
 *   },
 * });
 */
export function useMembersQuery(baseOptions: Apollo.QueryHookOptions<Types.MembersQuery, Types.MembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MembersQuery, Types.MembersQueryVariables>(MembersDocument, options);
      }
export function useMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MembersQuery, Types.MembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MembersQuery, Types.MembersQueryVariables>(MembersDocument, options);
        }
export type MembersQueryHookResult = ReturnType<typeof useMembersQuery>;
export type MembersLazyQueryHookResult = ReturnType<typeof useMembersLazyQuery>;
export type MembersQueryResult = Apollo.QueryResult<Types.MembersQuery, Types.MembersQueryVariables>;
export const AssignmentDocument = gql`
    query assignment($companyId: ID!, $assignmentId: ID!) {
  assignment(companyId: $companyId, assignmentId: $assignmentId) {
    ...AssignmentData
  }
}
    ${AssignmentDataFragmentDoc}`;

/**
 * __useAssignmentQuery__
 *
 * To run a query within a React component, call `useAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignmentQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      assignmentId: // value for 'assignmentId'
 *   },
 * });
 */
export function useAssignmentQuery(baseOptions: Apollo.QueryHookOptions<Types.AssignmentQuery, Types.AssignmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AssignmentQuery, Types.AssignmentQueryVariables>(AssignmentDocument, options);
      }
export function useAssignmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AssignmentQuery, Types.AssignmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AssignmentQuery, Types.AssignmentQueryVariables>(AssignmentDocument, options);
        }
export type AssignmentQueryHookResult = ReturnType<typeof useAssignmentQuery>;
export type AssignmentLazyQueryHookResult = ReturnType<typeof useAssignmentLazyQuery>;
export type AssignmentQueryResult = Apollo.QueryResult<Types.AssignmentQuery, Types.AssignmentQueryVariables>;
export const AssignmentsDocument = gql`
    query assignments($companyId: ID!, $data: IAssignmentQuery!, $filters: IAssignmentFilters, $withProjects: Boolean! = false) {
  assignments(companyId: $companyId, data: $data, filters: $filters) {
    id
    seniority
    seniorityId
    role {
      id
      name
    }
    member {
      id
      first_name
      last_name
      avatar_url
      color
    }
    project @include(if: $withProjects) {
      color
      name
      id
    }
  }
}
    `;

/**
 * __useAssignmentsQuery__
 *
 * To run a query within a React component, call `useAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignmentsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *      filters: // value for 'filters'
 *      withProjects: // value for 'withProjects'
 *   },
 * });
 */
export function useAssignmentsQuery(baseOptions: Apollo.QueryHookOptions<Types.AssignmentsQuery, Types.AssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AssignmentsQuery, Types.AssignmentsQueryVariables>(AssignmentsDocument, options);
      }
export function useAssignmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AssignmentsQuery, Types.AssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AssignmentsQuery, Types.AssignmentsQueryVariables>(AssignmentsDocument, options);
        }
export type AssignmentsQueryHookResult = ReturnType<typeof useAssignmentsQuery>;
export type AssignmentsLazyQueryHookResult = ReturnType<typeof useAssignmentsLazyQuery>;
export type AssignmentsQueryResult = Apollo.QueryResult<Types.AssignmentsQuery, Types.AssignmentsQueryVariables>;
export const BenchReportDocument = gql`
    query BenchReport($companyId: ID!, $data: IBenchReportData!, $filters: InsightFilters) {
  benchReport(companyId: $companyId, data: $data, filters: $filters) {
    ...BenchReportData
  }
}
    ${BenchReportDataFragmentDoc}`;

/**
 * __useBenchReportQuery__
 *
 * To run a query within a React component, call `useBenchReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useBenchReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBenchReportQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useBenchReportQuery(baseOptions: Apollo.QueryHookOptions<Types.BenchReportQuery, Types.BenchReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BenchReportQuery, Types.BenchReportQueryVariables>(BenchReportDocument, options);
      }
export function useBenchReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BenchReportQuery, Types.BenchReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BenchReportQuery, Types.BenchReportQueryVariables>(BenchReportDocument, options);
        }
export type BenchReportQueryHookResult = ReturnType<typeof useBenchReportQuery>;
export type BenchReportLazyQueryHookResult = ReturnType<typeof useBenchReportLazyQuery>;
export type BenchReportQueryResult = Apollo.QueryResult<Types.BenchReportQuery, Types.BenchReportQueryVariables>;
export const BillableLeavesRulesDocument = gql`
    query BillableLeavesRules($companyId: ID!, $projectId: ID!) {
  billableLeavesRules(companyId: $companyId, projectId: $projectId) {
    ...BillableLeaveRuleData
  }
}
    ${BillableLeaveRuleDataFragmentDoc}`;

/**
 * __useBillableLeavesRulesQuery__
 *
 * To run a query within a React component, call `useBillableLeavesRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillableLeavesRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillableLeavesRulesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useBillableLeavesRulesQuery(baseOptions: Apollo.QueryHookOptions<Types.BillableLeavesRulesQuery, Types.BillableLeavesRulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BillableLeavesRulesQuery, Types.BillableLeavesRulesQueryVariables>(BillableLeavesRulesDocument, options);
      }
export function useBillableLeavesRulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BillableLeavesRulesQuery, Types.BillableLeavesRulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BillableLeavesRulesQuery, Types.BillableLeavesRulesQueryVariables>(BillableLeavesRulesDocument, options);
        }
export type BillableLeavesRulesQueryHookResult = ReturnType<typeof useBillableLeavesRulesQuery>;
export type BillableLeavesRulesLazyQueryHookResult = ReturnType<typeof useBillableLeavesRulesLazyQuery>;
export type BillableLeavesRulesQueryResult = Apollo.QueryResult<Types.BillableLeavesRulesQuery, Types.BillableLeavesRulesQueryVariables>;
export const BillableLeaveRuleBalanceDocument = gql`
    query BillableLeaveRuleBalance($companyId: ID!, $data: IBillableLeaveRuleBalance!) {
  billableLeaveRuleBalance(companyId: $companyId, data: $data) {
    ...RuleMembersBalanceData
  }
}
    ${RuleMembersBalanceDataFragmentDoc}`;

/**
 * __useBillableLeaveRuleBalanceQuery__
 *
 * To run a query within a React component, call `useBillableLeaveRuleBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillableLeaveRuleBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillableLeaveRuleBalanceQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useBillableLeaveRuleBalanceQuery(baseOptions: Apollo.QueryHookOptions<Types.BillableLeaveRuleBalanceQuery, Types.BillableLeaveRuleBalanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BillableLeaveRuleBalanceQuery, Types.BillableLeaveRuleBalanceQueryVariables>(BillableLeaveRuleBalanceDocument, options);
      }
export function useBillableLeaveRuleBalanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BillableLeaveRuleBalanceQuery, Types.BillableLeaveRuleBalanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BillableLeaveRuleBalanceQuery, Types.BillableLeaveRuleBalanceQueryVariables>(BillableLeaveRuleBalanceDocument, options);
        }
export type BillableLeaveRuleBalanceQueryHookResult = ReturnType<typeof useBillableLeaveRuleBalanceQuery>;
export type BillableLeaveRuleBalanceLazyQueryHookResult = ReturnType<typeof useBillableLeaveRuleBalanceLazyQuery>;
export type BillableLeaveRuleBalanceQueryResult = Apollo.QueryResult<Types.BillableLeaveRuleBalanceQuery, Types.BillableLeaveRuleBalanceQueryVariables>;
export const BillableLeaveRuleMonthBalanceDocument = gql`
    query BillableLeaveRuleMonthBalance($companyId: ID!, $memberId: ID!, $data: IBillableLeaveRuleMonthBalance!) {
  billableLeaveRuleMonthBalance(
    companyId: $companyId
    memberId: $memberId
    data: $data
  ) {
    ...BillableLeaveRuleMonthBalanceData
  }
}
    ${BillableLeaveRuleMonthBalanceDataFragmentDoc}`;

/**
 * __useBillableLeaveRuleMonthBalanceQuery__
 *
 * To run a query within a React component, call `useBillableLeaveRuleMonthBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillableLeaveRuleMonthBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillableLeaveRuleMonthBalanceQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      memberId: // value for 'memberId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useBillableLeaveRuleMonthBalanceQuery(baseOptions: Apollo.QueryHookOptions<Types.BillableLeaveRuleMonthBalanceQuery, Types.BillableLeaveRuleMonthBalanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BillableLeaveRuleMonthBalanceQuery, Types.BillableLeaveRuleMonthBalanceQueryVariables>(BillableLeaveRuleMonthBalanceDocument, options);
      }
export function useBillableLeaveRuleMonthBalanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BillableLeaveRuleMonthBalanceQuery, Types.BillableLeaveRuleMonthBalanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BillableLeaveRuleMonthBalanceQuery, Types.BillableLeaveRuleMonthBalanceQueryVariables>(BillableLeaveRuleMonthBalanceDocument, options);
        }
export type BillableLeaveRuleMonthBalanceQueryHookResult = ReturnType<typeof useBillableLeaveRuleMonthBalanceQuery>;
export type BillableLeaveRuleMonthBalanceLazyQueryHookResult = ReturnType<typeof useBillableLeaveRuleMonthBalanceLazyQuery>;
export type BillableLeaveRuleMonthBalanceQueryResult = Apollo.QueryResult<Types.BillableLeaveRuleMonthBalanceQuery, Types.BillableLeaveRuleMonthBalanceQueryVariables>;
export const SetMemberBalanceAccrualMonthDocument = gql`
    mutation SetMemberBalanceAccrualMonth($accrualDate: String!, $billableLeaveRuleId: ID!, $companyId: ID!, $memberId: ID!) {
  setMemberBalanceAccrualMonth(
    accrualDate: $accrualDate
    billableLeaveRuleId: $billableLeaveRuleId
    companyId: $companyId
    memberId: $memberId
  ) {
    ...MemberBalanceAccrualMonthData
  }
}
    ${MemberBalanceAccrualMonthDataFragmentDoc}`;
export type SetMemberBalanceAccrualMonthMutationFn = Apollo.MutationFunction<Types.SetMemberBalanceAccrualMonthMutation, Types.SetMemberBalanceAccrualMonthMutationVariables>;

/**
 * __useSetMemberBalanceAccrualMonthMutation__
 *
 * To run a mutation, you first call `useSetMemberBalanceAccrualMonthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMemberBalanceAccrualMonthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMemberBalanceAccrualMonthMutation, { data, loading, error }] = useSetMemberBalanceAccrualMonthMutation({
 *   variables: {
 *      accrualDate: // value for 'accrualDate'
 *      billableLeaveRuleId: // value for 'billableLeaveRuleId'
 *      companyId: // value for 'companyId'
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useSetMemberBalanceAccrualMonthMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetMemberBalanceAccrualMonthMutation, Types.SetMemberBalanceAccrualMonthMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetMemberBalanceAccrualMonthMutation, Types.SetMemberBalanceAccrualMonthMutationVariables>(SetMemberBalanceAccrualMonthDocument, options);
      }
export type SetMemberBalanceAccrualMonthMutationHookResult = ReturnType<typeof useSetMemberBalanceAccrualMonthMutation>;
export type SetMemberBalanceAccrualMonthMutationResult = Apollo.MutationResult<Types.SetMemberBalanceAccrualMonthMutation>;
export type SetMemberBalanceAccrualMonthMutationOptions = Apollo.BaseMutationOptions<Types.SetMemberBalanceAccrualMonthMutation, Types.SetMemberBalanceAccrualMonthMutationVariables>;
export const DeleteMemberBalanceAccrualMonthDocument = gql`
    mutation DeleteMemberBalanceAccrualMonth($billableLeaveRuleId: ID!, $companyId: ID!, $memberId: ID!) {
  deleteMemberBalanceAccrualMonth(
    billableLeaveRuleId: $billableLeaveRuleId
    companyId: $companyId
    memberId: $memberId
  ) {
    ...MemberBalanceAccrualMonthData
  }
}
    ${MemberBalanceAccrualMonthDataFragmentDoc}`;
export type DeleteMemberBalanceAccrualMonthMutationFn = Apollo.MutationFunction<Types.DeleteMemberBalanceAccrualMonthMutation, Types.DeleteMemberBalanceAccrualMonthMutationVariables>;

/**
 * __useDeleteMemberBalanceAccrualMonthMutation__
 *
 * To run a mutation, you first call `useDeleteMemberBalanceAccrualMonthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMemberBalanceAccrualMonthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMemberBalanceAccrualMonthMutation, { data, loading, error }] = useDeleteMemberBalanceAccrualMonthMutation({
 *   variables: {
 *      billableLeaveRuleId: // value for 'billableLeaveRuleId'
 *      companyId: // value for 'companyId'
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useDeleteMemberBalanceAccrualMonthMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteMemberBalanceAccrualMonthMutation, Types.DeleteMemberBalanceAccrualMonthMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteMemberBalanceAccrualMonthMutation, Types.DeleteMemberBalanceAccrualMonthMutationVariables>(DeleteMemberBalanceAccrualMonthDocument, options);
      }
export type DeleteMemberBalanceAccrualMonthMutationHookResult = ReturnType<typeof useDeleteMemberBalanceAccrualMonthMutation>;
export type DeleteMemberBalanceAccrualMonthMutationResult = Apollo.MutationResult<Types.DeleteMemberBalanceAccrualMonthMutation>;
export type DeleteMemberBalanceAccrualMonthMutationOptions = Apollo.BaseMutationOptions<Types.DeleteMemberBalanceAccrualMonthMutation, Types.DeleteMemberBalanceAccrualMonthMutationVariables>;
export const CommentsDocument = gql`
    query Comments($companyId: ID!, $entityId: String!, $entityType: CommentDomains!) {
  comments(companyId: $companyId, entityId: $entityId, entityType: $entityType) {
    ...CommentData
  }
}
    ${CommentDataFragmentDoc}`;

/**
 * __useCommentsQuery__
 *
 * To run a query within a React component, call `useCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      entityId: // value for 'entityId'
 *      entityType: // value for 'entityType'
 *   },
 * });
 */
export function useCommentsQuery(baseOptions: Apollo.QueryHookOptions<Types.CommentsQuery, Types.CommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CommentsQuery, Types.CommentsQueryVariables>(CommentsDocument, options);
      }
export function useCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CommentsQuery, Types.CommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CommentsQuery, Types.CommentsQueryVariables>(CommentsDocument, options);
        }
export type CommentsQueryHookResult = ReturnType<typeof useCommentsQuery>;
export type CommentsLazyQueryHookResult = ReturnType<typeof useCommentsLazyQuery>;
export type CommentsQueryResult = Apollo.QueryResult<Types.CommentsQuery, Types.CommentsQueryVariables>;
export const EditCommentDocument = gql`
    mutation EditComment($commentId: ID!, $companyId: ID!, $note: String!) {
  editComment(commentId: $commentId, companyId: $companyId, note: $note) {
    ...CommentData
  }
}
    ${CommentDataFragmentDoc}`;
export type EditCommentMutationFn = Apollo.MutationFunction<Types.EditCommentMutation, Types.EditCommentMutationVariables>;

/**
 * __useEditCommentMutation__
 *
 * To run a mutation, you first call `useEditCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCommentMutation, { data, loading, error }] = useEditCommentMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *      companyId: // value for 'companyId'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useEditCommentMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditCommentMutation, Types.EditCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditCommentMutation, Types.EditCommentMutationVariables>(EditCommentDocument, options);
      }
export type EditCommentMutationHookResult = ReturnType<typeof useEditCommentMutation>;
export type EditCommentMutationResult = Apollo.MutationResult<Types.EditCommentMutation>;
export type EditCommentMutationOptions = Apollo.BaseMutationOptions<Types.EditCommentMutation, Types.EditCommentMutationVariables>;
export const CreateCommentDocument = gql`
    mutation CreateComment($companyId: ID!, $data: ICreateComment!) {
  createComment(companyId: $companyId, data: $data) {
    ...CommentData
  }
}
    ${CommentDataFragmentDoc}`;
export type CreateCommentMutationFn = Apollo.MutationFunction<Types.CreateCommentMutation, Types.CreateCommentMutationVariables>;

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCommentMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateCommentMutation, Types.CreateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateCommentMutation, Types.CreateCommentMutationVariables>(CreateCommentDocument, options);
      }
export type CreateCommentMutationHookResult = ReturnType<typeof useCreateCommentMutation>;
export type CreateCommentMutationResult = Apollo.MutationResult<Types.CreateCommentMutation>;
export type CreateCommentMutationOptions = Apollo.BaseMutationOptions<Types.CreateCommentMutation, Types.CreateCommentMutationVariables>;
export const DeleteCommentDocument = gql`
    mutation DeleteComment($commentId: ID!, $companyId: ID!) {
  deleteComment(commentId: $commentId, companyId: $companyId) {
    id
    entityId
  }
}
    `;
export type DeleteCommentMutationFn = Apollo.MutationFunction<Types.DeleteCommentMutation, Types.DeleteCommentMutationVariables>;

/**
 * __useDeleteCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentMutation, { data, loading, error }] = useDeleteCommentMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useDeleteCommentMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteCommentMutation, Types.DeleteCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteCommentMutation, Types.DeleteCommentMutationVariables>(DeleteCommentDocument, options);
      }
export type DeleteCommentMutationHookResult = ReturnType<typeof useDeleteCommentMutation>;
export type DeleteCommentMutationResult = Apollo.MutationResult<Types.DeleteCommentMutation>;
export type DeleteCommentMutationOptions = Apollo.BaseMutationOptions<Types.DeleteCommentMutation, Types.DeleteCommentMutationVariables>;
export const CountPendingRequestDocument = gql`
    query CountPendingRequest($companyId: ID!) {
  countPendingRequest(companyId: $companyId)
}
    `;

/**
 * __useCountPendingRequestQuery__
 *
 * To run a query within a React component, call `useCountPendingRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountPendingRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountPendingRequestQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCountPendingRequestQuery(baseOptions: Apollo.QueryHookOptions<Types.CountPendingRequestQuery, Types.CountPendingRequestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CountPendingRequestQuery, Types.CountPendingRequestQueryVariables>(CountPendingRequestDocument, options);
      }
export function useCountPendingRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CountPendingRequestQuery, Types.CountPendingRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CountPendingRequestQuery, Types.CountPendingRequestQueryVariables>(CountPendingRequestDocument, options);
        }
export type CountPendingRequestQueryHookResult = ReturnType<typeof useCountPendingRequestQuery>;
export type CountPendingRequestLazyQueryHookResult = ReturnType<typeof useCountPendingRequestLazyQuery>;
export type CountPendingRequestQueryResult = Apollo.QueryResult<Types.CountPendingRequestQuery, Types.CountPendingRequestQueryVariables>;
export const CurrenciesDocument = gql`
    query Currencies($companyId: ID!) {
  currencies(companyId: $companyId) {
    id
    name
    code
  }
}
    `;

/**
 * __useCurrenciesQuery__
 *
 * To run a query within a React component, call `useCurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrenciesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCurrenciesQuery(baseOptions: Apollo.QueryHookOptions<Types.CurrenciesQuery, Types.CurrenciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CurrenciesQuery, Types.CurrenciesQueryVariables>(CurrenciesDocument, options);
      }
export function useCurrenciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CurrenciesQuery, Types.CurrenciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CurrenciesQuery, Types.CurrenciesQueryVariables>(CurrenciesDocument, options);
        }
export type CurrenciesQueryHookResult = ReturnType<typeof useCurrenciesQuery>;
export type CurrenciesLazyQueryHookResult = ReturnType<typeof useCurrenciesLazyQuery>;
export type CurrenciesQueryResult = Apollo.QueryResult<Types.CurrenciesQuery, Types.CurrenciesQueryVariables>;
export const ExchangeRateDocument = gql`
    query ExchangeRate($companyId: ID!, $baseCurrencyId: ID!, $exchangeCurrencyId: ID!) {
  exchangeRate(
    companyId: $companyId
    baseCurrencyId: $baseCurrencyId
    exchangeCurrencyId: $exchangeCurrencyId
  ) {
    ...ExchangeRateData
  }
}
    ${ExchangeRateDataFragmentDoc}`;

/**
 * __useExchangeRateQuery__
 *
 * To run a query within a React component, call `useExchangeRateQuery` and pass it any options that fit your needs.
 * When your component renders, `useExchangeRateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExchangeRateQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      baseCurrencyId: // value for 'baseCurrencyId'
 *      exchangeCurrencyId: // value for 'exchangeCurrencyId'
 *   },
 * });
 */
export function useExchangeRateQuery(baseOptions: Apollo.QueryHookOptions<Types.ExchangeRateQuery, Types.ExchangeRateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ExchangeRateQuery, Types.ExchangeRateQueryVariables>(ExchangeRateDocument, options);
      }
export function useExchangeRateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ExchangeRateQuery, Types.ExchangeRateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ExchangeRateQuery, Types.ExchangeRateQueryVariables>(ExchangeRateDocument, options);
        }
export type ExchangeRateQueryHookResult = ReturnType<typeof useExchangeRateQuery>;
export type ExchangeRateLazyQueryHookResult = ReturnType<typeof useExchangeRateLazyQuery>;
export type ExchangeRateQueryResult = Apollo.QueryResult<Types.ExchangeRateQuery, Types.ExchangeRateQueryVariables>;
export const ExpenseTypesDocument = gql`
    query ExpenseTypes($companyId: ID!) {
  expenseTypes(companyId: $companyId) {
    id
    name
  }
}
    `;

/**
 * __useExpenseTypesQuery__
 *
 * To run a query within a React component, call `useExpenseTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpenseTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpenseTypesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useExpenseTypesQuery(baseOptions: Apollo.QueryHookOptions<Types.ExpenseTypesQuery, Types.ExpenseTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ExpenseTypesQuery, Types.ExpenseTypesQueryVariables>(ExpenseTypesDocument, options);
      }
export function useExpenseTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ExpenseTypesQuery, Types.ExpenseTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ExpenseTypesQuery, Types.ExpenseTypesQueryVariables>(ExpenseTypesDocument, options);
        }
export type ExpenseTypesQueryHookResult = ReturnType<typeof useExpenseTypesQuery>;
export type ExpenseTypesLazyQueryHookResult = ReturnType<typeof useExpenseTypesLazyQuery>;
export type ExpenseTypesQueryResult = Apollo.QueryResult<Types.ExpenseTypesQuery, Types.ExpenseTypesQueryVariables>;
export const ExportImportExampleDocument = gql`
    query ExportImportExample($companyId: ID!, $type: ImportType!) {
  exportImportExample(companyId: $companyId, type: $type)
}
    `;

/**
 * __useExportImportExampleQuery__
 *
 * To run a query within a React component, call `useExportImportExampleQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportImportExampleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportImportExampleQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useExportImportExampleQuery(baseOptions: Apollo.QueryHookOptions<Types.ExportImportExampleQuery, Types.ExportImportExampleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ExportImportExampleQuery, Types.ExportImportExampleQueryVariables>(ExportImportExampleDocument, options);
      }
export function useExportImportExampleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ExportImportExampleQuery, Types.ExportImportExampleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ExportImportExampleQuery, Types.ExportImportExampleQueryVariables>(ExportImportExampleDocument, options);
        }
export type ExportImportExampleQueryHookResult = ReturnType<typeof useExportImportExampleQuery>;
export type ExportImportExampleLazyQueryHookResult = ReturnType<typeof useExportImportExampleLazyQuery>;
export type ExportImportExampleQueryResult = Apollo.QueryResult<Types.ExportImportExampleQuery, Types.ExportImportExampleQueryVariables>;
export const ExportMembersHoursDocument = gql`
    query ExportMembersHours($companyId: ID!, $interval: IPaginationInterval!, $includedTabs: [MembersHoursReportTabs!]) {
  membersHours(
    companyId: $companyId
    interval: $interval
    includedTabs: $includedTabs
  )
}
    `;

/**
 * __useExportMembersHoursQuery__
 *
 * To run a query within a React component, call `useExportMembersHoursQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportMembersHoursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportMembersHoursQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      interval: // value for 'interval'
 *      includedTabs: // value for 'includedTabs'
 *   },
 * });
 */
export function useExportMembersHoursQuery(baseOptions: Apollo.QueryHookOptions<Types.ExportMembersHoursQuery, Types.ExportMembersHoursQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ExportMembersHoursQuery, Types.ExportMembersHoursQueryVariables>(ExportMembersHoursDocument, options);
      }
export function useExportMembersHoursLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ExportMembersHoursQuery, Types.ExportMembersHoursQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ExportMembersHoursQuery, Types.ExportMembersHoursQueryVariables>(ExportMembersHoursDocument, options);
        }
export type ExportMembersHoursQueryHookResult = ReturnType<typeof useExportMembersHoursQuery>;
export type ExportMembersHoursLazyQueryHookResult = ReturnType<typeof useExportMembersHoursLazyQuery>;
export type ExportMembersHoursQueryResult = Apollo.QueryResult<Types.ExportMembersHoursQuery, Types.ExportMembersHoursQueryVariables>;
export const ExportProjectDocument = gql`
    query ExportProject($companyId: ID!, $projectId: ID!, $data: IExportProject) {
  exportProject(companyId: $companyId, projectId: $projectId, data: $data)
}
    `;

/**
 * __useExportProjectQuery__
 *
 * To run a query within a React component, call `useExportProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportProjectQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      projectId: // value for 'projectId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useExportProjectQuery(baseOptions: Apollo.QueryHookOptions<Types.ExportProjectQuery, Types.ExportProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ExportProjectQuery, Types.ExportProjectQueryVariables>(ExportProjectDocument, options);
      }
export function useExportProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ExportProjectQuery, Types.ExportProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ExportProjectQuery, Types.ExportProjectQueryVariables>(ExportProjectDocument, options);
        }
export type ExportProjectQueryHookResult = ReturnType<typeof useExportProjectQuery>;
export type ExportProjectLazyQueryHookResult = ReturnType<typeof useExportProjectLazyQuery>;
export type ExportProjectQueryResult = Apollo.QueryResult<Types.ExportProjectQuery, Types.ExportProjectQueryVariables>;
export const ExportProjectReportDocument = gql`
    query ExportProjectReport($companyId: ID!, $projectId: ID!, $data: IExportProject) {
  exportProjectReport(companyId: $companyId, projectId: $projectId, data: $data)
}
    `;

/**
 * __useExportProjectReportQuery__
 *
 * To run a query within a React component, call `useExportProjectReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportProjectReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportProjectReportQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      projectId: // value for 'projectId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useExportProjectReportQuery(baseOptions: Apollo.QueryHookOptions<Types.ExportProjectReportQuery, Types.ExportProjectReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ExportProjectReportQuery, Types.ExportProjectReportQueryVariables>(ExportProjectReportDocument, options);
      }
export function useExportProjectReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ExportProjectReportQuery, Types.ExportProjectReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ExportProjectReportQuery, Types.ExportProjectReportQueryVariables>(ExportProjectReportDocument, options);
        }
export type ExportProjectReportQueryHookResult = ReturnType<typeof useExportProjectReportQuery>;
export type ExportProjectReportLazyQueryHookResult = ReturnType<typeof useExportProjectReportLazyQuery>;
export type ExportProjectReportQueryResult = Apollo.QueryResult<Types.ExportProjectReportQuery, Types.ExportProjectReportQueryVariables>;
export const ExportProjectsReportDocument = gql`
    query ExportProjectsReport($companyId: ID!, $data: IPaginationInterval!) {
  projectsAdminReport(companyId: $companyId, data: $data)
}
    `;

/**
 * __useExportProjectsReportQuery__
 *
 * To run a query within a React component, call `useExportProjectsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportProjectsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportProjectsReportQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useExportProjectsReportQuery(baseOptions: Apollo.QueryHookOptions<Types.ExportProjectsReportQuery, Types.ExportProjectsReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ExportProjectsReportQuery, Types.ExportProjectsReportQueryVariables>(ExportProjectsReportDocument, options);
      }
export function useExportProjectsReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ExportProjectsReportQuery, Types.ExportProjectsReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ExportProjectsReportQuery, Types.ExportProjectsReportQueryVariables>(ExportProjectsReportDocument, options);
        }
export type ExportProjectsReportQueryHookResult = ReturnType<typeof useExportProjectsReportQuery>;
export type ExportProjectsReportLazyQueryHookResult = ReturnType<typeof useExportProjectsReportLazyQuery>;
export type ExportProjectsReportQueryResult = Apollo.QueryResult<Types.ExportProjectsReportQuery, Types.ExportProjectsReportQueryVariables>;
export const ExternalRatesDocument = gql`
    query ExternalRates($companyId: ID!, $projectId: ID!, $isPast: Boolean) {
  externalRates(companyId: $companyId, projectId: $projectId, isPast: $isPast) {
    ...ExternalRateData
  }
}
    ${ExternalRateDataFragmentDoc}`;

/**
 * __useExternalRatesQuery__
 *
 * To run a query within a React component, call `useExternalRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalRatesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      projectId: // value for 'projectId'
 *      isPast: // value for 'isPast'
 *   },
 * });
 */
export function useExternalRatesQuery(baseOptions: Apollo.QueryHookOptions<Types.ExternalRatesQuery, Types.ExternalRatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ExternalRatesQuery, Types.ExternalRatesQueryVariables>(ExternalRatesDocument, options);
      }
export function useExternalRatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ExternalRatesQuery, Types.ExternalRatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ExternalRatesQuery, Types.ExternalRatesQueryVariables>(ExternalRatesDocument, options);
        }
export type ExternalRatesQueryHookResult = ReturnType<typeof useExternalRatesQuery>;
export type ExternalRatesLazyQueryHookResult = ReturnType<typeof useExternalRatesLazyQuery>;
export type ExternalRatesQueryResult = Apollo.QueryResult<Types.ExternalRatesQuery, Types.ExternalRatesQueryVariables>;
export const HolidaysDocument = gql`
    query Holidays($companyId: ID!) {
  holidays(companyId: $companyId) {
    id
    date
    name
  }
}
    `;

/**
 * __useHolidaysQuery__
 *
 * To run a query within a React component, call `useHolidaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useHolidaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHolidaysQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useHolidaysQuery(baseOptions: Apollo.QueryHookOptions<Types.HolidaysQuery, Types.HolidaysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.HolidaysQuery, Types.HolidaysQueryVariables>(HolidaysDocument, options);
      }
export function useHolidaysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.HolidaysQuery, Types.HolidaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.HolidaysQuery, Types.HolidaysQueryVariables>(HolidaysDocument, options);
        }
export type HolidaysQueryHookResult = ReturnType<typeof useHolidaysQuery>;
export type HolidaysLazyQueryHookResult = ReturnType<typeof useHolidaysLazyQuery>;
export type HolidaysQueryResult = Apollo.QueryResult<Types.HolidaysQuery, Types.HolidaysQueryVariables>;
export const CompanyIntegrationsDocument = gql`
    query CompanyIntegrations($companyId: ID!) {
  companyIntegrations(companyId: $companyId) {
    ...CompanyIntegrationData
  }
}
    ${CompanyIntegrationDataFragmentDoc}`;

/**
 * __useCompanyIntegrationsQuery__
 *
 * To run a query within a React component, call `useCompanyIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyIntegrationsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyIntegrationsQuery(baseOptions: Apollo.QueryHookOptions<Types.CompanyIntegrationsQuery, Types.CompanyIntegrationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyIntegrationsQuery, Types.CompanyIntegrationsQueryVariables>(CompanyIntegrationsDocument, options);
      }
export function useCompanyIntegrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyIntegrationsQuery, Types.CompanyIntegrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyIntegrationsQuery, Types.CompanyIntegrationsQueryVariables>(CompanyIntegrationsDocument, options);
        }
export type CompanyIntegrationsQueryHookResult = ReturnType<typeof useCompanyIntegrationsQuery>;
export type CompanyIntegrationsLazyQueryHookResult = ReturnType<typeof useCompanyIntegrationsLazyQuery>;
export type CompanyIntegrationsQueryResult = Apollo.QueryResult<Types.CompanyIntegrationsQuery, Types.CompanyIntegrationsQueryVariables>;
export const CompanyIntegrationDocument = gql`
    query CompanyIntegration($companyId: ID!, $integrationId: ID!) {
  companyIntegration(companyId: $companyId, integrationId: $integrationId) {
    ...CompanyIntegrationData
  }
}
    ${CompanyIntegrationDataFragmentDoc}`;

/**
 * __useCompanyIntegrationQuery__
 *
 * To run a query within a React component, call `useCompanyIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyIntegrationQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useCompanyIntegrationQuery(baseOptions: Apollo.QueryHookOptions<Types.CompanyIntegrationQuery, Types.CompanyIntegrationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyIntegrationQuery, Types.CompanyIntegrationQueryVariables>(CompanyIntegrationDocument, options);
      }
export function useCompanyIntegrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyIntegrationQuery, Types.CompanyIntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyIntegrationQuery, Types.CompanyIntegrationQueryVariables>(CompanyIntegrationDocument, options);
        }
export type CompanyIntegrationQueryHookResult = ReturnType<typeof useCompanyIntegrationQuery>;
export type CompanyIntegrationLazyQueryHookResult = ReturnType<typeof useCompanyIntegrationLazyQuery>;
export type CompanyIntegrationQueryResult = Apollo.QueryResult<Types.CompanyIntegrationQuery, Types.CompanyIntegrationQueryVariables>;
export const AvailableIntegrationsDocument = gql`
    query AvailableIntegrations($companyId: ID!) {
  availableIntegrations(companyId: $companyId) {
    ...AvailableIntegrationData
  }
}
    ${AvailableIntegrationDataFragmentDoc}`;

/**
 * __useAvailableIntegrationsQuery__
 *
 * To run a query within a React component, call `useAvailableIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableIntegrationsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useAvailableIntegrationsQuery(baseOptions: Apollo.QueryHookOptions<Types.AvailableIntegrationsQuery, Types.AvailableIntegrationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AvailableIntegrationsQuery, Types.AvailableIntegrationsQueryVariables>(AvailableIntegrationsDocument, options);
      }
export function useAvailableIntegrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AvailableIntegrationsQuery, Types.AvailableIntegrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AvailableIntegrationsQuery, Types.AvailableIntegrationsQueryVariables>(AvailableIntegrationsDocument, options);
        }
export type AvailableIntegrationsQueryHookResult = ReturnType<typeof useAvailableIntegrationsQuery>;
export type AvailableIntegrationsLazyQueryHookResult = ReturnType<typeof useAvailableIntegrationsLazyQuery>;
export type AvailableIntegrationsQueryResult = Apollo.QueryResult<Types.AvailableIntegrationsQuery, Types.AvailableIntegrationsQueryVariables>;
export const InvitationsDocument = gql`
    query Invitations($companyId: ID!) {
  invitations(companyId: $companyId) {
    id
    email
    status
    created_at
  }
}
    `;

/**
 * __useInvitationsQuery__
 *
 * To run a query within a React component, call `useInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvitationsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useInvitationsQuery(baseOptions: Apollo.QueryHookOptions<Types.InvitationsQuery, Types.InvitationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.InvitationsQuery, Types.InvitationsQueryVariables>(InvitationsDocument, options);
      }
export function useInvitationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.InvitationsQuery, Types.InvitationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.InvitationsQuery, Types.InvitationsQueryVariables>(InvitationsDocument, options);
        }
export type InvitationsQueryHookResult = ReturnType<typeof useInvitationsQuery>;
export type InvitationsLazyQueryHookResult = ReturnType<typeof useInvitationsLazyQuery>;
export type InvitationsQueryResult = Apollo.QueryResult<Types.InvitationsQuery, Types.InvitationsQueryVariables>;
export const LeaveTypesDocument = gql`
    query LeaveTypes($companyId: ID!) {
  leaveTypes(companyId: $companyId) {
    ...LeaveTypeData
  }
}
    ${LeaveTypeDataFragmentDoc}`;

/**
 * __useLeaveTypesQuery__
 *
 * To run a query within a React component, call `useLeaveTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeaveTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeaveTypesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useLeaveTypesQuery(baseOptions: Apollo.QueryHookOptions<Types.LeaveTypesQuery, Types.LeaveTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.LeaveTypesQuery, Types.LeaveTypesQueryVariables>(LeaveTypesDocument, options);
      }
export function useLeaveTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.LeaveTypesQuery, Types.LeaveTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.LeaveTypesQuery, Types.LeaveTypesQueryVariables>(LeaveTypesDocument, options);
        }
export type LeaveTypesQueryHookResult = ReturnType<typeof useLeaveTypesQuery>;
export type LeaveTypesLazyQueryHookResult = ReturnType<typeof useLeaveTypesLazyQuery>;
export type LeaveTypesQueryResult = Apollo.QueryResult<Types.LeaveTypesQuery, Types.LeaveTypesQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    ...MemberData
  }
}
    ${MemberDataFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<Types.MeQuery, Types.MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MeQuery, Types.MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MeQuery, Types.MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MeQuery, Types.MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<Types.MeQuery, Types.MeQueryVariables>;
export const MemberProjectsDistributionDocument = gql`
    query MemberProjectsDistribution($companyId: ID!, $interval: IPaginationInterval!, $memberId: ID!) {
  memberProjectsDistribution(
    companyId: $companyId
    interval: $interval
    memberId: $memberId
  ) {
    ...MemberProjectDistributionData
  }
}
    ${MemberProjectDistributionDataFragmentDoc}`;

/**
 * __useMemberProjectsDistributionQuery__
 *
 * To run a query within a React component, call `useMemberProjectsDistributionQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberProjectsDistributionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberProjectsDistributionQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      interval: // value for 'interval'
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useMemberProjectsDistributionQuery(baseOptions: Apollo.QueryHookOptions<Types.MemberProjectsDistributionQuery, Types.MemberProjectsDistributionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MemberProjectsDistributionQuery, Types.MemberProjectsDistributionQueryVariables>(MemberProjectsDistributionDocument, options);
      }
export function useMemberProjectsDistributionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MemberProjectsDistributionQuery, Types.MemberProjectsDistributionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MemberProjectsDistributionQuery, Types.MemberProjectsDistributionQueryVariables>(MemberProjectsDistributionDocument, options);
        }
export type MemberProjectsDistributionQueryHookResult = ReturnType<typeof useMemberProjectsDistributionQuery>;
export type MemberProjectsDistributionLazyQueryHookResult = ReturnType<typeof useMemberProjectsDistributionLazyQuery>;
export type MemberProjectsDistributionQueryResult = Apollo.QueryResult<Types.MemberProjectsDistributionQuery, Types.MemberProjectsDistributionQueryVariables>;
export const MembersIntegrationStatusesDocument = gql`
    query MembersIntegrationStatuses($companyId: ID!, $isArchived: Boolean) {
  membersIntegrationStatuses(companyId: $companyId, isArchived: $isArchived) {
    ...MemberIntegrationStatusesData
  }
}
    ${MemberIntegrationStatusesDataFragmentDoc}`;

/**
 * __useMembersIntegrationStatusesQuery__
 *
 * To run a query within a React component, call `useMembersIntegrationStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembersIntegrationStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembersIntegrationStatusesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      isArchived: // value for 'isArchived'
 *   },
 * });
 */
export function useMembersIntegrationStatusesQuery(baseOptions: Apollo.QueryHookOptions<Types.MembersIntegrationStatusesQuery, Types.MembersIntegrationStatusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MembersIntegrationStatusesQuery, Types.MembersIntegrationStatusesQueryVariables>(MembersIntegrationStatusesDocument, options);
      }
export function useMembersIntegrationStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MembersIntegrationStatusesQuery, Types.MembersIntegrationStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MembersIntegrationStatusesQuery, Types.MembersIntegrationStatusesQueryVariables>(MembersIntegrationStatusesDocument, options);
        }
export type MembersIntegrationStatusesQueryHookResult = ReturnType<typeof useMembersIntegrationStatusesQuery>;
export type MembersIntegrationStatusesLazyQueryHookResult = ReturnType<typeof useMembersIntegrationStatusesLazyQuery>;
export type MembersIntegrationStatusesQueryResult = Apollo.QueryResult<Types.MembersIntegrationStatusesQuery, Types.MembersIntegrationStatusesQueryVariables>;
export const MemberIntegrationStatusesDocument = gql`
    query MemberIntegrationStatuses($companyId: ID!, $memberId: ID!) {
  memberIntegrationStatuses(companyId: $companyId, memberId: $memberId) {
    ...MemberIntegrationStatusesData
  }
}
    ${MemberIntegrationStatusesDataFragmentDoc}`;

/**
 * __useMemberIntegrationStatusesQuery__
 *
 * To run a query within a React component, call `useMemberIntegrationStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberIntegrationStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberIntegrationStatusesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useMemberIntegrationStatusesQuery(baseOptions: Apollo.QueryHookOptions<Types.MemberIntegrationStatusesQuery, Types.MemberIntegrationStatusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MemberIntegrationStatusesQuery, Types.MemberIntegrationStatusesQueryVariables>(MemberIntegrationStatusesDocument, options);
      }
export function useMemberIntegrationStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MemberIntegrationStatusesQuery, Types.MemberIntegrationStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MemberIntegrationStatusesQuery, Types.MemberIntegrationStatusesQueryVariables>(MemberIntegrationStatusesDocument, options);
        }
export type MemberIntegrationStatusesQueryHookResult = ReturnType<typeof useMemberIntegrationStatusesQuery>;
export type MemberIntegrationStatusesLazyQueryHookResult = ReturnType<typeof useMemberIntegrationStatusesLazyQuery>;
export type MemberIntegrationStatusesQueryResult = Apollo.QueryResult<Types.MemberIntegrationStatusesQuery, Types.MemberIntegrationStatusesQueryVariables>;
export const MembersListDataDocument = gql`
    query MembersListData($companyId: ID!, $filterData: IFilterData, $isArchived: Boolean) {
  membersListData(
    companyId: $companyId
    filterData: $filterData
    isArchived: $isArchived
  ) {
    ...MemberListDetailsData
  }
}
    ${MemberListDetailsDataFragmentDoc}`;

/**
 * __useMembersListDataQuery__
 *
 * To run a query within a React component, call `useMembersListDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembersListDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembersListDataQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      filterData: // value for 'filterData'
 *      isArchived: // value for 'isArchived'
 *   },
 * });
 */
export function useMembersListDataQuery(baseOptions: Apollo.QueryHookOptions<Types.MembersListDataQuery, Types.MembersListDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MembersListDataQuery, Types.MembersListDataQueryVariables>(MembersListDataDocument, options);
      }
export function useMembersListDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MembersListDataQuery, Types.MembersListDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MembersListDataQuery, Types.MembersListDataQueryVariables>(MembersListDataDocument, options);
        }
export type MembersListDataQueryHookResult = ReturnType<typeof useMembersListDataQuery>;
export type MembersListDataLazyQueryHookResult = ReturnType<typeof useMembersListDataLazyQuery>;
export type MembersListDataQueryResult = Apollo.QueryResult<Types.MembersListDataQuery, Types.MembersListDataQueryVariables>;
export const ActorPermissionsDocument = gql`
    query ActorPermissions($companyId: ID!, $projectId: ID) {
  actorPermissions(companyId: $companyId, projectId: $projectId) {
    ...permissionData
  }
}
    ${PermissionDataFragmentDoc}`;

/**
 * __useActorPermissionsQuery__
 *
 * To run a query within a React component, call `useActorPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActorPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActorPermissionsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useActorPermissionsQuery(baseOptions: Apollo.QueryHookOptions<Types.ActorPermissionsQuery, Types.ActorPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ActorPermissionsQuery, Types.ActorPermissionsQueryVariables>(ActorPermissionsDocument, options);
      }
export function useActorPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ActorPermissionsQuery, Types.ActorPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ActorPermissionsQuery, Types.ActorPermissionsQueryVariables>(ActorPermissionsDocument, options);
        }
export type ActorPermissionsQueryHookResult = ReturnType<typeof useActorPermissionsQuery>;
export type ActorPermissionsLazyQueryHookResult = ReturnType<typeof useActorPermissionsLazyQuery>;
export type ActorPermissionsQueryResult = Apollo.QueryResult<Types.ActorPermissionsQuery, Types.ActorPermissionsQueryVariables>;
export const ProjectAssignmentsDocument = gql`
    query ProjectAssignments($companyId: ID!, $projectId: ID!) {
  project(companyId: $companyId, projectId: $projectId) {
    id
    unit
    type
    fee_amount
    assignments {
      id
      role {
        id
        name
      }
      project {
        id
        unit
      }
      member {
        id
        first_name
        last_name
        job_title
        color
        cost_rate {
          id
          start_date
          end_date
          rate_amount
          rate_currency
        }
        leaves {
          id
          memberId
          startDate
          endDate
        }
      }
      role {
        id
        name
      }
      startDate
      endDate
      allocationTimeAmount
    }
  }
}
    `;

/**
 * __useProjectAssignmentsQuery__
 *
 * To run a query within a React component, call `useProjectAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectAssignmentsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectAssignmentsQuery(baseOptions: Apollo.QueryHookOptions<Types.ProjectAssignmentsQuery, Types.ProjectAssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProjectAssignmentsQuery, Types.ProjectAssignmentsQueryVariables>(ProjectAssignmentsDocument, options);
      }
export function useProjectAssignmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProjectAssignmentsQuery, Types.ProjectAssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProjectAssignmentsQuery, Types.ProjectAssignmentsQueryVariables>(ProjectAssignmentsDocument, options);
        }
export type ProjectAssignmentsQueryHookResult = ReturnType<typeof useProjectAssignmentsQuery>;
export type ProjectAssignmentsLazyQueryHookResult = ReturnType<typeof useProjectAssignmentsLazyQuery>;
export type ProjectAssignmentsQueryResult = Apollo.QueryResult<Types.ProjectAssignmentsQuery, Types.ProjectAssignmentsQueryVariables>;
export const ProjectClientDocument = gql`
    query projectClient($companyId: ID!, $externalRatesAccess: Boolean) {
  clients(companyId: $companyId, externalRatesAccess: $externalRatesAccess) {
    ...ClientData
  }
}
    ${ClientDataFragmentDoc}`;

/**
 * __useProjectClientQuery__
 *
 * To run a query within a React component, call `useProjectClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectClientQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      externalRatesAccess: // value for 'externalRatesAccess'
 *   },
 * });
 */
export function useProjectClientQuery(baseOptions: Apollo.QueryHookOptions<Types.ProjectClientQuery, Types.ProjectClientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProjectClientQuery, Types.ProjectClientQueryVariables>(ProjectClientDocument, options);
      }
export function useProjectClientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProjectClientQuery, Types.ProjectClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProjectClientQuery, Types.ProjectClientQueryVariables>(ProjectClientDocument, options);
        }
export type ProjectClientQueryHookResult = ReturnType<typeof useProjectClientQuery>;
export type ProjectClientLazyQueryHookResult = ReturnType<typeof useProjectClientLazyQuery>;
export type ProjectClientQueryResult = Apollo.QueryResult<Types.ProjectClientQuery, Types.ProjectClientQueryVariables>;
export const ProjectCommissionsDocument = gql`
    query ProjectCommissions($companyId: ID!, $projectId: ID!) {
  projectCommissions(companyId: $companyId, projectId: $projectId) {
    ...ProjectCommissionData
  }
}
    ${ProjectCommissionDataFragmentDoc}`;

/**
 * __useProjectCommissionsQuery__
 *
 * To run a query within a React component, call `useProjectCommissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectCommissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectCommissionsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectCommissionsQuery(baseOptions: Apollo.QueryHookOptions<Types.ProjectCommissionsQuery, Types.ProjectCommissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProjectCommissionsQuery, Types.ProjectCommissionsQueryVariables>(ProjectCommissionsDocument, options);
      }
export function useProjectCommissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProjectCommissionsQuery, Types.ProjectCommissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProjectCommissionsQuery, Types.ProjectCommissionsQueryVariables>(ProjectCommissionsDocument, options);
        }
export type ProjectCommissionsQueryHookResult = ReturnType<typeof useProjectCommissionsQuery>;
export type ProjectCommissionsLazyQueryHookResult = ReturnType<typeof useProjectCommissionsLazyQuery>;
export type ProjectCommissionsQueryResult = Apollo.QueryResult<Types.ProjectCommissionsQuery, Types.ProjectCommissionsQueryVariables>;
export const ProjectDocumentUrlDocument = gql`
    query ProjectDocumentUrl($companyId: ID!, $projectDocumentId: ID!, $download: Boolean) {
  projectDocumentUrl(
    companyId: $companyId
    projectDocumentId: $projectDocumentId
    download: $download
  )
}
    `;

/**
 * __useProjectDocumentUrlQuery__
 *
 * To run a query within a React component, call `useProjectDocumentUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectDocumentUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectDocumentUrlQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      projectDocumentId: // value for 'projectDocumentId'
 *      download: // value for 'download'
 *   },
 * });
 */
export function useProjectDocumentUrlQuery(baseOptions: Apollo.QueryHookOptions<Types.ProjectDocumentUrlQuery, Types.ProjectDocumentUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProjectDocumentUrlQuery, Types.ProjectDocumentUrlQueryVariables>(ProjectDocumentUrlDocument, options);
      }
export function useProjectDocumentUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProjectDocumentUrlQuery, Types.ProjectDocumentUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProjectDocumentUrlQuery, Types.ProjectDocumentUrlQueryVariables>(ProjectDocumentUrlDocument, options);
        }
export type ProjectDocumentUrlQueryHookResult = ReturnType<typeof useProjectDocumentUrlQuery>;
export type ProjectDocumentUrlLazyQueryHookResult = ReturnType<typeof useProjectDocumentUrlLazyQuery>;
export type ProjectDocumentUrlQueryResult = Apollo.QueryResult<Types.ProjectDocumentUrlQuery, Types.ProjectDocumentUrlQueryVariables>;
export const ProjectDocumentsDocument = gql`
    query ProjectDocuments($companyId: ID!, $projectId: ID, $searchByName: String, $filterData: IProjectDocumentFilters) {
  projectDocuments(
    companyId: $companyId
    projectId: $projectId
    searchByName: $searchByName
    filterData: $filterData
  ) {
    ...ProjectDocumentData
  }
}
    ${ProjectDocumentDataFragmentDoc}`;

/**
 * __useProjectDocumentsQuery__
 *
 * To run a query within a React component, call `useProjectDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectDocumentsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      projectId: // value for 'projectId'
 *      searchByName: // value for 'searchByName'
 *      filterData: // value for 'filterData'
 *   },
 * });
 */
export function useProjectDocumentsQuery(baseOptions: Apollo.QueryHookOptions<Types.ProjectDocumentsQuery, Types.ProjectDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProjectDocumentsQuery, Types.ProjectDocumentsQueryVariables>(ProjectDocumentsDocument, options);
      }
export function useProjectDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProjectDocumentsQuery, Types.ProjectDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProjectDocumentsQuery, Types.ProjectDocumentsQueryVariables>(ProjectDocumentsDocument, options);
        }
export type ProjectDocumentsQueryHookResult = ReturnType<typeof useProjectDocumentsQuery>;
export type ProjectDocumentsLazyQueryHookResult = ReturnType<typeof useProjectDocumentsLazyQuery>;
export type ProjectDocumentsQueryResult = Apollo.QueryResult<Types.ProjectDocumentsQuery, Types.ProjectDocumentsQueryVariables>;
export const ProjectManagersDocument = gql`
    query projectManagers($companyId: ID!) {
  users(companyId: $companyId) {
    id
    member {
      id
      first_name
      last_name
      is_pending
      archived_at
      color
    }
    status
    type
    deactivatedAt
  }
}
    `;

/**
 * __useProjectManagersQuery__
 *
 * To run a query within a React component, call `useProjectManagersQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectManagersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectManagersQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useProjectManagersQuery(baseOptions: Apollo.QueryHookOptions<Types.ProjectManagersQuery, Types.ProjectManagersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProjectManagersQuery, Types.ProjectManagersQueryVariables>(ProjectManagersDocument, options);
      }
export function useProjectManagersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProjectManagersQuery, Types.ProjectManagersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProjectManagersQuery, Types.ProjectManagersQueryVariables>(ProjectManagersDocument, options);
        }
export type ProjectManagersQueryHookResult = ReturnType<typeof useProjectManagersQuery>;
export type ProjectManagersLazyQueryHookResult = ReturnType<typeof useProjectManagersLazyQuery>;
export type ProjectManagersQueryResult = Apollo.QueryResult<Types.ProjectManagersQuery, Types.ProjectManagersQueryVariables>;
export const ProjectMembershipsDocument = gql`
    query ProjectMemberships($companyId: ID!, $data: IQueryMembership!) {
  projectMemberships(companyId: $companyId, data: $data) {
    ...ProjectMembershipData
  }
}
    ${ProjectMembershipDataFragmentDoc}`;

/**
 * __useProjectMembershipsQuery__
 *
 * To run a query within a React component, call `useProjectMembershipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectMembershipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectMembershipsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useProjectMembershipsQuery(baseOptions: Apollo.QueryHookOptions<Types.ProjectMembershipsQuery, Types.ProjectMembershipsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProjectMembershipsQuery, Types.ProjectMembershipsQueryVariables>(ProjectMembershipsDocument, options);
      }
export function useProjectMembershipsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProjectMembershipsQuery, Types.ProjectMembershipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProjectMembershipsQuery, Types.ProjectMembershipsQueryVariables>(ProjectMembershipsDocument, options);
        }
export type ProjectMembershipsQueryHookResult = ReturnType<typeof useProjectMembershipsQuery>;
export type ProjectMembershipsLazyQueryHookResult = ReturnType<typeof useProjectMembershipsLazyQuery>;
export type ProjectMembershipsQueryResult = Apollo.QueryResult<Types.ProjectMembershipsQuery, Types.ProjectMembershipsQueryVariables>;
export const ProjectMembershipDocument = gql`
    query ProjectMembership($companyId: ID!, $projectId: ID!, $memberId: ID!) {
  projectMembership(
    companyId: $companyId
    projectId: $projectId
    memberId: $memberId
  ) {
    endDate
    startDate
  }
}
    `;

/**
 * __useProjectMembershipQuery__
 *
 * To run a query within a React component, call `useProjectMembershipQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectMembershipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectMembershipQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      projectId: // value for 'projectId'
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useProjectMembershipQuery(baseOptions: Apollo.QueryHookOptions<Types.ProjectMembershipQuery, Types.ProjectMembershipQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProjectMembershipQuery, Types.ProjectMembershipQueryVariables>(ProjectMembershipDocument, options);
      }
export function useProjectMembershipLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProjectMembershipQuery, Types.ProjectMembershipQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProjectMembershipQuery, Types.ProjectMembershipQueryVariables>(ProjectMembershipDocument, options);
        }
export type ProjectMembershipQueryHookResult = ReturnType<typeof useProjectMembershipQuery>;
export type ProjectMembershipLazyQueryHookResult = ReturnType<typeof useProjectMembershipLazyQuery>;
export type ProjectMembershipQueryResult = Apollo.QueryResult<Types.ProjectMembershipQuery, Types.ProjectMembershipQueryVariables>;
export const ProjectMilestonesDocument = gql`
    query ProjectMilestones($companyId: ID!, $projectId: ID!) {
  projectMilestones(companyId: $companyId, projectId: $projectId) {
    ...ProjectMilestoneData
  }
}
    ${ProjectMilestoneDataFragmentDoc}`;

/**
 * __useProjectMilestonesQuery__
 *
 * To run a query within a React component, call `useProjectMilestonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectMilestonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectMilestonesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectMilestonesQuery(baseOptions: Apollo.QueryHookOptions<Types.ProjectMilestonesQuery, Types.ProjectMilestonesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProjectMilestonesQuery, Types.ProjectMilestonesQueryVariables>(ProjectMilestonesDocument, options);
      }
export function useProjectMilestonesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProjectMilestonesQuery, Types.ProjectMilestonesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProjectMilestonesQuery, Types.ProjectMilestonesQueryVariables>(ProjectMilestonesDocument, options);
        }
export type ProjectMilestonesQueryHookResult = ReturnType<typeof useProjectMilestonesQuery>;
export type ProjectMilestonesLazyQueryHookResult = ReturnType<typeof useProjectMilestonesLazyQuery>;
export type ProjectMilestonesQueryResult = Apollo.QueryResult<Types.ProjectMilestonesQuery, Types.ProjectMilestonesQueryVariables>;
export const ProjectMilestonesSectionDocument = gql`
    query ProjectMilestonesSection($companyId: ID!, $projectId: ID!, $companyCurrency: Boolean) {
  projectMilestonesSection(
    companyId: $companyId
    projectId: $projectId
    companyCurrency: $companyCurrency
  ) {
    ...ProjectMilestoneSectionData
  }
}
    ${ProjectMilestoneSectionDataFragmentDoc}`;

/**
 * __useProjectMilestonesSectionQuery__
 *
 * To run a query within a React component, call `useProjectMilestonesSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectMilestonesSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectMilestonesSectionQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      projectId: // value for 'projectId'
 *      companyCurrency: // value for 'companyCurrency'
 *   },
 * });
 */
export function useProjectMilestonesSectionQuery(baseOptions: Apollo.QueryHookOptions<Types.ProjectMilestonesSectionQuery, Types.ProjectMilestonesSectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProjectMilestonesSectionQuery, Types.ProjectMilestonesSectionQueryVariables>(ProjectMilestonesSectionDocument, options);
      }
export function useProjectMilestonesSectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProjectMilestonesSectionQuery, Types.ProjectMilestonesSectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProjectMilestonesSectionQuery, Types.ProjectMilestonesSectionQueryVariables>(ProjectMilestonesSectionDocument, options);
        }
export type ProjectMilestonesSectionQueryHookResult = ReturnType<typeof useProjectMilestonesSectionQuery>;
export type ProjectMilestonesSectionLazyQueryHookResult = ReturnType<typeof useProjectMilestonesSectionLazyQuery>;
export type ProjectMilestonesSectionQueryResult = Apollo.QueryResult<Types.ProjectMilestonesSectionQuery, Types.ProjectMilestonesSectionQueryVariables>;
export const ProjectRolesOrderDocument = gql`
    query ProjectRolesOrder($companyId: ID!, $projectId: ID!) {
  projectRolesOrder(companyId: $companyId, projectId: $projectId) {
    ...ProjectRoleOrderData
  }
}
    ${ProjectRoleOrderDataFragmentDoc}`;

/**
 * __useProjectRolesOrderQuery__
 *
 * To run a query within a React component, call `useProjectRolesOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectRolesOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectRolesOrderQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectRolesOrderQuery(baseOptions: Apollo.QueryHookOptions<Types.ProjectRolesOrderQuery, Types.ProjectRolesOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProjectRolesOrderQuery, Types.ProjectRolesOrderQueryVariables>(ProjectRolesOrderDocument, options);
      }
export function useProjectRolesOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProjectRolesOrderQuery, Types.ProjectRolesOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProjectRolesOrderQuery, Types.ProjectRolesOrderQueryVariables>(ProjectRolesOrderDocument, options);
        }
export type ProjectRolesOrderQueryHookResult = ReturnType<typeof useProjectRolesOrderQuery>;
export type ProjectRolesOrderLazyQueryHookResult = ReturnType<typeof useProjectRolesOrderLazyQuery>;
export type ProjectRolesOrderQueryResult = Apollo.QueryResult<Types.ProjectRolesOrderQuery, Types.ProjectRolesOrderQueryVariables>;
export const ChangeProjectRoleOrderDocument = gql`
    mutation ChangeProjectRoleOrder($companyId: ID!, $data: IProjectRoleOrder!) {
  changeProjectRoleOrder(companyId: $companyId, data: $data) {
    ...ProjectRoleOrderData
  }
}
    ${ProjectRoleOrderDataFragmentDoc}`;
export type ChangeProjectRoleOrderMutationFn = Apollo.MutationFunction<Types.ChangeProjectRoleOrderMutation, Types.ChangeProjectRoleOrderMutationVariables>;

/**
 * __useChangeProjectRoleOrderMutation__
 *
 * To run a mutation, you first call `useChangeProjectRoleOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeProjectRoleOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeProjectRoleOrderMutation, { data, loading, error }] = useChangeProjectRoleOrderMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useChangeProjectRoleOrderMutation(baseOptions?: Apollo.MutationHookOptions<Types.ChangeProjectRoleOrderMutation, Types.ChangeProjectRoleOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ChangeProjectRoleOrderMutation, Types.ChangeProjectRoleOrderMutationVariables>(ChangeProjectRoleOrderDocument, options);
      }
export type ChangeProjectRoleOrderMutationHookResult = ReturnType<typeof useChangeProjectRoleOrderMutation>;
export type ChangeProjectRoleOrderMutationResult = Apollo.MutationResult<Types.ChangeProjectRoleOrderMutation>;
export type ChangeProjectRoleOrderMutationOptions = Apollo.BaseMutationOptions<Types.ChangeProjectRoleOrderMutation, Types.ChangeProjectRoleOrderMutationVariables>;
export const ProjectsForAssignmentsDocument = gql`
    query ProjectsForAssignments($companyId: ID!, $isArchived: Boolean) {
  projects(companyId: $companyId, isArchived: $isArchived) {
    id
    name
    type
    archived_at
    unit
    feeCurrencyId
    color
    pm {
      id
    }
  }
}
    `;

/**
 * __useProjectsForAssignmentsQuery__
 *
 * To run a query within a React component, call `useProjectsForAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsForAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsForAssignmentsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      isArchived: // value for 'isArchived'
 *   },
 * });
 */
export function useProjectsForAssignmentsQuery(baseOptions: Apollo.QueryHookOptions<Types.ProjectsForAssignmentsQuery, Types.ProjectsForAssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProjectsForAssignmentsQuery, Types.ProjectsForAssignmentsQueryVariables>(ProjectsForAssignmentsDocument, options);
      }
export function useProjectsForAssignmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProjectsForAssignmentsQuery, Types.ProjectsForAssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProjectsForAssignmentsQuery, Types.ProjectsForAssignmentsQueryVariables>(ProjectsForAssignmentsDocument, options);
        }
export type ProjectsForAssignmentsQueryHookResult = ReturnType<typeof useProjectsForAssignmentsQuery>;
export type ProjectsForAssignmentsLazyQueryHookResult = ReturnType<typeof useProjectsForAssignmentsLazyQuery>;
export type ProjectsForAssignmentsQueryResult = Apollo.QueryResult<Types.ProjectsForAssignmentsQuery, Types.ProjectsForAssignmentsQueryVariables>;
export const ProjectsForAssignmentEditDocument = gql`
    query ProjectsForAssignmentEdit($companyId: ID!) {
  projects(companyId: $companyId) {
    id
    name
    type
    fee_currency
  }
}
    `;

/**
 * __useProjectsForAssignmentEditQuery__
 *
 * To run a query within a React component, call `useProjectsForAssignmentEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsForAssignmentEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsForAssignmentEditQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useProjectsForAssignmentEditQuery(baseOptions: Apollo.QueryHookOptions<Types.ProjectsForAssignmentEditQuery, Types.ProjectsForAssignmentEditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProjectsForAssignmentEditQuery, Types.ProjectsForAssignmentEditQueryVariables>(ProjectsForAssignmentEditDocument, options);
      }
export function useProjectsForAssignmentEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProjectsForAssignmentEditQuery, Types.ProjectsForAssignmentEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProjectsForAssignmentEditQuery, Types.ProjectsForAssignmentEditQueryVariables>(ProjectsForAssignmentEditDocument, options);
        }
export type ProjectsForAssignmentEditQueryHookResult = ReturnType<typeof useProjectsForAssignmentEditQuery>;
export type ProjectsForAssignmentEditLazyQueryHookResult = ReturnType<typeof useProjectsForAssignmentEditLazyQuery>;
export type ProjectsForAssignmentEditQueryResult = Apollo.QueryResult<Types.ProjectsForAssignmentEditQuery, Types.ProjectsForAssignmentEditQueryVariables>;
export const ProjectsListDataDocument = gql`
    query ProjectsListData($companyId: ID!, $filterData: IFilterProjectData, $isArchived: Boolean) {
  projectsListData(
    companyId: $companyId
    filterData: $filterData
    isArchived: $isArchived
  ) {
    ...ProjectListDetailsData
  }
}
    ${ProjectListDetailsDataFragmentDoc}`;

/**
 * __useProjectsListDataQuery__
 *
 * To run a query within a React component, call `useProjectsListDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsListDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsListDataQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      filterData: // value for 'filterData'
 *      isArchived: // value for 'isArchived'
 *   },
 * });
 */
export function useProjectsListDataQuery(baseOptions: Apollo.QueryHookOptions<Types.ProjectsListDataQuery, Types.ProjectsListDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProjectsListDataQuery, Types.ProjectsListDataQueryVariables>(ProjectsListDataDocument, options);
      }
export function useProjectsListDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProjectsListDataQuery, Types.ProjectsListDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProjectsListDataQuery, Types.ProjectsListDataQueryVariables>(ProjectsListDataDocument, options);
        }
export type ProjectsListDataQueryHookResult = ReturnType<typeof useProjectsListDataQuery>;
export type ProjectsListDataLazyQueryHookResult = ReturnType<typeof useProjectsListDataLazyQuery>;
export type ProjectsListDataQueryResult = Apollo.QueryResult<Types.ProjectsListDataQuery, Types.ProjectsListDataQueryVariables>;
export const ProjectsMarginsDocument = gql`
    query ProjectsMargins($companyId: ID!, $isArchived: Boolean) {
  projectsMargins(companyId: $companyId, isArchived: $isArchived) {
    ...ProjectMarginsData
  }
}
    ${ProjectMarginsDataFragmentDoc}`;

/**
 * __useProjectsMarginsQuery__
 *
 * To run a query within a React component, call `useProjectsMarginsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsMarginsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsMarginsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      isArchived: // value for 'isArchived'
 *   },
 * });
 */
export function useProjectsMarginsQuery(baseOptions: Apollo.QueryHookOptions<Types.ProjectsMarginsQuery, Types.ProjectsMarginsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProjectsMarginsQuery, Types.ProjectsMarginsQueryVariables>(ProjectsMarginsDocument, options);
      }
export function useProjectsMarginsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProjectsMarginsQuery, Types.ProjectsMarginsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProjectsMarginsQuery, Types.ProjectsMarginsQueryVariables>(ProjectsMarginsDocument, options);
        }
export type ProjectsMarginsQueryHookResult = ReturnType<typeof useProjectsMarginsQuery>;
export type ProjectsMarginsLazyQueryHookResult = ReturnType<typeof useProjectsMarginsLazyQuery>;
export type ProjectsMarginsQueryResult = Apollo.QueryResult<Types.ProjectsMarginsQuery, Types.ProjectsMarginsQueryVariables>;
export const ReportsDocument = gql`
    query Reports($companyId: ID!, $projectId: ID, $interval: IPaginationInterval) {
  reports(companyId: $companyId, projectId: $projectId, interval: $interval) {
    ...ReportItemData
  }
}
    ${ReportItemDataFragmentDoc}`;

/**
 * __useReportsQuery__
 *
 * To run a query within a React component, call `useReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      projectId: // value for 'projectId'
 *      interval: // value for 'interval'
 *   },
 * });
 */
export function useReportsQuery(baseOptions: Apollo.QueryHookOptions<Types.ReportsQuery, Types.ReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ReportsQuery, Types.ReportsQueryVariables>(ReportsDocument, options);
      }
export function useReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ReportsQuery, Types.ReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ReportsQuery, Types.ReportsQueryVariables>(ReportsDocument, options);
        }
export type ReportsQueryHookResult = ReturnType<typeof useReportsQuery>;
export type ReportsLazyQueryHookResult = ReturnType<typeof useReportsLazyQuery>;
export type ReportsQueryResult = Apollo.QueryResult<Types.ReportsQuery, Types.ReportsQueryVariables>;
export const ReportDocument = gql`
    query Report($companyId: ID!, $reportId: ID!) {
  report(companyId: $companyId, reportId: $reportId) {
    ...ReportDataWithClient
    milestones {
      ...ProjectMilestoneData
    }
  }
}
    ${ReportDataWithClientFragmentDoc}
${ProjectMilestoneDataFragmentDoc}`;

/**
 * __useReportQuery__
 *
 * To run a query within a React component, call `useReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useReportQuery(baseOptions: Apollo.QueryHookOptions<Types.ReportQuery, Types.ReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ReportQuery, Types.ReportQueryVariables>(ReportDocument, options);
      }
export function useReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ReportQuery, Types.ReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ReportQuery, Types.ReportQueryVariables>(ReportDocument, options);
        }
export type ReportQueryHookResult = ReturnType<typeof useReportQuery>;
export type ReportLazyQueryHookResult = ReturnType<typeof useReportLazyQuery>;
export type ReportQueryResult = Apollo.QueryResult<Types.ReportQuery, Types.ReportQueryVariables>;
export const ExportReportDocument = gql`
    query ExportReport($companyId: ID!, $reportId: ID!, $projectId: ID!, $exportType: ExportTarget) {
  exportReport(
    companyId: $companyId
    reportId: $reportId
    projectId: $projectId
    exportType: $exportType
  )
}
    `;

/**
 * __useExportReportQuery__
 *
 * To run a query within a React component, call `useExportReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportReportQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      reportId: // value for 'reportId'
 *      projectId: // value for 'projectId'
 *      exportType: // value for 'exportType'
 *   },
 * });
 */
export function useExportReportQuery(baseOptions: Apollo.QueryHookOptions<Types.ExportReportQuery, Types.ExportReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ExportReportQuery, Types.ExportReportQueryVariables>(ExportReportDocument, options);
      }
export function useExportReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ExportReportQuery, Types.ExportReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ExportReportQuery, Types.ExportReportQueryVariables>(ExportReportDocument, options);
        }
export type ExportReportQueryHookResult = ReturnType<typeof useExportReportQuery>;
export type ExportReportLazyQueryHookResult = ReturnType<typeof useExportReportLazyQuery>;
export type ExportReportQueryResult = Apollo.QueryResult<Types.ExportReportQuery, Types.ExportReportQueryVariables>;
export const RequestDocument = gql`
    query Request($companyId: ID!, $requestId: ID!) {
  request(companyId: $companyId, requestId: $requestId) {
    ...RequestData
  }
}
    ${RequestDataFragmentDoc}`;

/**
 * __useRequestQuery__
 *
 * To run a query within a React component, call `useRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useRequestQuery(baseOptions: Apollo.QueryHookOptions<Types.RequestQuery, Types.RequestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RequestQuery, Types.RequestQueryVariables>(RequestDocument, options);
      }
export function useRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RequestQuery, Types.RequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RequestQuery, Types.RequestQueryVariables>(RequestDocument, options);
        }
export type RequestQueryHookResult = ReturnType<typeof useRequestQuery>;
export type RequestLazyQueryHookResult = ReturnType<typeof useRequestLazyQuery>;
export type RequestQueryResult = Apollo.QueryResult<Types.RequestQuery, Types.RequestQueryVariables>;
export const RequestsDocument = gql`
    query Requests($companyId: ID!, $requestFilter: RequestFilter, $paginationData: IPaginationInterval, $limit: Int, $offset: Int) {
  requests(
    companyId: $companyId
    requestFilter: $requestFilter
    paginationData: $paginationData
    limit: $limit
    offset: $offset
  ) {
    ...RequestData
  }
}
    ${RequestDataFragmentDoc}`;

/**
 * __useRequestsQuery__
 *
 * To run a query within a React component, call `useRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      requestFilter: // value for 'requestFilter'
 *      paginationData: // value for 'paginationData'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useRequestsQuery(baseOptions: Apollo.QueryHookOptions<Types.RequestsQuery, Types.RequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RequestsQuery, Types.RequestsQueryVariables>(RequestsDocument, options);
      }
export function useRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RequestsQuery, Types.RequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RequestsQuery, Types.RequestsQueryVariables>(RequestsDocument, options);
        }
export type RequestsQueryHookResult = ReturnType<typeof useRequestsQuery>;
export type RequestsLazyQueryHookResult = ReturnType<typeof useRequestsLazyQuery>;
export type RequestsQueryResult = Apollo.QueryResult<Types.RequestsQuery, Types.RequestsQueryVariables>;
export const CountAllRequestsDocument = gql`
    query CountAllRequests($companyId: ID!, $requestFilter: RequestFilter) {
  countAllRequests(companyId: $companyId, requestFilter: $requestFilter)
}
    `;

/**
 * __useCountAllRequestsQuery__
 *
 * To run a query within a React component, call `useCountAllRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountAllRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountAllRequestsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      requestFilter: // value for 'requestFilter'
 *   },
 * });
 */
export function useCountAllRequestsQuery(baseOptions: Apollo.QueryHookOptions<Types.CountAllRequestsQuery, Types.CountAllRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CountAllRequestsQuery, Types.CountAllRequestsQueryVariables>(CountAllRequestsDocument, options);
      }
export function useCountAllRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CountAllRequestsQuery, Types.CountAllRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CountAllRequestsQuery, Types.CountAllRequestsQueryVariables>(CountAllRequestsDocument, options);
        }
export type CountAllRequestsQueryHookResult = ReturnType<typeof useCountAllRequestsQuery>;
export type CountAllRequestsLazyQueryHookResult = ReturnType<typeof useCountAllRequestsLazyQuery>;
export type CountAllRequestsQueryResult = Apollo.QueryResult<Types.CountAllRequestsQuery, Types.CountAllRequestsQueryVariables>;
export const RevenueChartDocument = gql`
    query RevenueChart($companyId: ID!, $projectId: ID!, $projectType: ProjectType!, $companyCurrency: Boolean, $data: IPaginationDashboard) {
  revenueChart(
    companyId: $companyId
    projectId: $projectId
    projectType: $projectType
    companyCurrency: $companyCurrency
    data: $data
  ) {
    exchangeRate
    exchangeRateCurrency
    projectCurrency
    revenueChart {
      ...RevenueChartData
    }
  }
}
    ${RevenueChartDataFragmentDoc}`;

/**
 * __useRevenueChartQuery__
 *
 * To run a query within a React component, call `useRevenueChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useRevenueChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRevenueChartQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      projectId: // value for 'projectId'
 *      projectType: // value for 'projectType'
 *      companyCurrency: // value for 'companyCurrency'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRevenueChartQuery(baseOptions: Apollo.QueryHookOptions<Types.RevenueChartQuery, Types.RevenueChartQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RevenueChartQuery, Types.RevenueChartQueryVariables>(RevenueChartDocument, options);
      }
export function useRevenueChartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RevenueChartQuery, Types.RevenueChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RevenueChartQuery, Types.RevenueChartQueryVariables>(RevenueChartDocument, options);
        }
export type RevenueChartQueryHookResult = ReturnType<typeof useRevenueChartQuery>;
export type RevenueChartLazyQueryHookResult = ReturnType<typeof useRevenueChartLazyQuery>;
export type RevenueChartQueryResult = Apollo.QueryResult<Types.RevenueChartQuery, Types.RevenueChartQueryVariables>;
export const RolesDocument = gql`
    query Roles($companyId: ID!) {
  roles(companyId: $companyId) {
    id
    name
  }
}
    `;

/**
 * __useRolesQuery__
 *
 * To run a query within a React component, call `useRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useRolesQuery(baseOptions: Apollo.QueryHookOptions<Types.RolesQuery, Types.RolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RolesQuery, Types.RolesQueryVariables>(RolesDocument, options);
      }
export function useRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RolesQuery, Types.RolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RolesQuery, Types.RolesQueryVariables>(RolesDocument, options);
        }
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesLazyQueryHookResult = ReturnType<typeof useRolesLazyQuery>;
export type RolesQueryResult = Apollo.QueryResult<Types.RolesQuery, Types.RolesQueryVariables>;
export const SenioritiesDocument = gql`
    query Seniorities($companyId: ID!) {
  seniorities(companyId: $companyId) {
    id
    name
  }
}
    `;

/**
 * __useSenioritiesQuery__
 *
 * To run a query within a React component, call `useSenioritiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSenioritiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSenioritiesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useSenioritiesQuery(baseOptions: Apollo.QueryHookOptions<Types.SenioritiesQuery, Types.SenioritiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SenioritiesQuery, Types.SenioritiesQueryVariables>(SenioritiesDocument, options);
      }
export function useSenioritiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SenioritiesQuery, Types.SenioritiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SenioritiesQuery, Types.SenioritiesQueryVariables>(SenioritiesDocument, options);
        }
export type SenioritiesQueryHookResult = ReturnType<typeof useSenioritiesQuery>;
export type SenioritiesLazyQueryHookResult = ReturnType<typeof useSenioritiesLazyQuery>;
export type SenioritiesQueryResult = Apollo.QueryResult<Types.SenioritiesQuery, Types.SenioritiesQueryVariables>;
export const SpecializationsDocument = gql`
    query Specializations($companyId: ID!) {
  specializations(companyId: $companyId) {
    id
    name
  }
}
    `;

/**
 * __useSpecializationsQuery__
 *
 * To run a query within a React component, call `useSpecializationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpecializationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpecializationsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useSpecializationsQuery(baseOptions: Apollo.QueryHookOptions<Types.SpecializationsQuery, Types.SpecializationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SpecializationsQuery, Types.SpecializationsQueryVariables>(SpecializationsDocument, options);
      }
export function useSpecializationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SpecializationsQuery, Types.SpecializationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SpecializationsQuery, Types.SpecializationsQueryVariables>(SpecializationsDocument, options);
        }
export type SpecializationsQueryHookResult = ReturnType<typeof useSpecializationsQuery>;
export type SpecializationsLazyQueryHookResult = ReturnType<typeof useSpecializationsLazyQuery>;
export type SpecializationsQueryResult = Apollo.QueryResult<Types.SpecializationsQuery, Types.SpecializationsQueryVariables>;
export const TagsDocument = gql`
    query Tags($companyId: ID!) {
  tags(companyId: $companyId) {
    ...TagData
  }
}
    ${TagDataFragmentDoc}`;

/**
 * __useTagsQuery__
 *
 * To run a query within a React component, call `useTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useTagsQuery(baseOptions: Apollo.QueryHookOptions<Types.TagsQuery, Types.TagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TagsQuery, Types.TagsQueryVariables>(TagsDocument, options);
      }
export function useTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TagsQuery, Types.TagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TagsQuery, Types.TagsQueryVariables>(TagsDocument, options);
        }
export type TagsQueryHookResult = ReturnType<typeof useTagsQuery>;
export type TagsLazyQueryHookResult = ReturnType<typeof useTagsLazyQuery>;
export type TagsQueryResult = Apollo.QueryResult<Types.TagsQuery, Types.TagsQueryVariables>;
export const TeamMembersDocument = gql`
    query TeamMembers($companyId: ID!, $filterData: IFilterData, $isArchived: Boolean) {
  members(companyId: $companyId, filterData: $filterData, isArchived: $isArchived) {
    ...TeamMemberData
  }
}
    ${TeamMemberDataFragmentDoc}`;

/**
 * __useTeamMembersQuery__
 *
 * To run a query within a React component, call `useTeamMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamMembersQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      filterData: // value for 'filterData'
 *      isArchived: // value for 'isArchived'
 *   },
 * });
 */
export function useTeamMembersQuery(baseOptions: Apollo.QueryHookOptions<Types.TeamMembersQuery, Types.TeamMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TeamMembersQuery, Types.TeamMembersQueryVariables>(TeamMembersDocument, options);
      }
export function useTeamMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TeamMembersQuery, Types.TeamMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TeamMembersQuery, Types.TeamMembersQueryVariables>(TeamMembersDocument, options);
        }
export type TeamMembersQueryHookResult = ReturnType<typeof useTeamMembersQuery>;
export type TeamMembersLazyQueryHookResult = ReturnType<typeof useTeamMembersLazyQuery>;
export type TeamMembersQueryResult = Apollo.QueryResult<Types.TeamMembersQuery, Types.TeamMembersQueryVariables>;
export const TeamMembersWithAssignmentsDocument = gql`
    query TeamMembersWithAssignments($companyId: ID!, $filters: IAssignmentFilters, $filterData: IFilterData, $isArchived: Boolean, $paginationAssignmentData: IPaginationAssignment) {
  members(
    companyId: $companyId
    filterData: $filterData
    isArchived: $isArchived
    paginationAssignmentData: $paginationAssignmentData
  ) {
    id
    first_name
    last_name
    color
    employment_type
    memberSpecialization
    assignments(filters: $filters) {
      projectId
    }
  }
}
    `;

/**
 * __useTeamMembersWithAssignmentsQuery__
 *
 * To run a query within a React component, call `useTeamMembersWithAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamMembersWithAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamMembersWithAssignmentsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      filters: // value for 'filters'
 *      filterData: // value for 'filterData'
 *      isArchived: // value for 'isArchived'
 *      paginationAssignmentData: // value for 'paginationAssignmentData'
 *   },
 * });
 */
export function useTeamMembersWithAssignmentsQuery(baseOptions: Apollo.QueryHookOptions<Types.TeamMembersWithAssignmentsQuery, Types.TeamMembersWithAssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TeamMembersWithAssignmentsQuery, Types.TeamMembersWithAssignmentsQueryVariables>(TeamMembersWithAssignmentsDocument, options);
      }
export function useTeamMembersWithAssignmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TeamMembersWithAssignmentsQuery, Types.TeamMembersWithAssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TeamMembersWithAssignmentsQuery, Types.TeamMembersWithAssignmentsQueryVariables>(TeamMembersWithAssignmentsDocument, options);
        }
export type TeamMembersWithAssignmentsQueryHookResult = ReturnType<typeof useTeamMembersWithAssignmentsQuery>;
export type TeamMembersWithAssignmentsLazyQueryHookResult = ReturnType<typeof useTeamMembersWithAssignmentsLazyQuery>;
export type TeamMembersWithAssignmentsQueryResult = Apollo.QueryResult<Types.TeamMembersWithAssignmentsQuery, Types.TeamMembersWithAssignmentsQueryVariables>;
export const TeamMembersForAssignmentsDocument = gql`
    query TeamMembersForAssignments($companyId: ID!, $isArchived: Boolean) {
  members(companyId: $companyId, isArchived: $isArchived) {
    ...MemberAssignmentData
  }
}
    ${MemberAssignmentDataFragmentDoc}`;

/**
 * __useTeamMembersForAssignmentsQuery__
 *
 * To run a query within a React component, call `useTeamMembersForAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamMembersForAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamMembersForAssignmentsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      isArchived: // value for 'isArchived'
 *   },
 * });
 */
export function useTeamMembersForAssignmentsQuery(baseOptions: Apollo.QueryHookOptions<Types.TeamMembersForAssignmentsQuery, Types.TeamMembersForAssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TeamMembersForAssignmentsQuery, Types.TeamMembersForAssignmentsQueryVariables>(TeamMembersForAssignmentsDocument, options);
      }
export function useTeamMembersForAssignmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TeamMembersForAssignmentsQuery, Types.TeamMembersForAssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TeamMembersForAssignmentsQuery, Types.TeamMembersForAssignmentsQueryVariables>(TeamMembersForAssignmentsDocument, options);
        }
export type TeamMembersForAssignmentsQueryHookResult = ReturnType<typeof useTeamMembersForAssignmentsQuery>;
export type TeamMembersForAssignmentsLazyQueryHookResult = ReturnType<typeof useTeamMembersForAssignmentsLazyQuery>;
export type TeamMembersForAssignmentsQueryResult = Apollo.QueryResult<Types.TeamMembersForAssignmentsQuery, Types.TeamMembersForAssignmentsQueryVariables>;
export const UsersDocument = gql`
    query Users($companyId: ID!, $withAccessCount: Boolean) {
  users(companyId: $companyId) {
    ...CompanyUserData
  }
}
    ${CompanyUserDataFragmentDoc}`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      withAccessCount: // value for 'withAccessCount'
 *   },
 * });
 */
export function useUsersQuery(baseOptions: Apollo.QueryHookOptions<Types.UsersQuery, Types.UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UsersQuery, Types.UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UsersQuery, Types.UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UsersQuery, Types.UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<Types.UsersQuery, Types.UsersQueryVariables>;
export const UtilizationReportDocument = gql`
    query UtilizationReport($companyId: ID!, $data: IPaginationInterval!, $columns: [UtilizationReportCustomColumns!]!, $filters: InsightFilters) {
  utilizationReport(
    companyId: $companyId
    data: $data
    columns: $columns
    filters: $filters
  ) {
    ...UtilizationReportData
  }
}
    ${UtilizationReportDataFragmentDoc}`;

/**
 * __useUtilizationReportQuery__
 *
 * To run a query within a React component, call `useUtilizationReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useUtilizationReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUtilizationReportQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *      columns: // value for 'columns'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useUtilizationReportQuery(baseOptions: Apollo.QueryHookOptions<Types.UtilizationReportQuery, Types.UtilizationReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UtilizationReportQuery, Types.UtilizationReportQueryVariables>(UtilizationReportDocument, options);
      }
export function useUtilizationReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UtilizationReportQuery, Types.UtilizationReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UtilizationReportQuery, Types.UtilizationReportQueryVariables>(UtilizationReportDocument, options);
        }
export type UtilizationReportQueryHookResult = ReturnType<typeof useUtilizationReportQuery>;
export type UtilizationReportLazyQueryHookResult = ReturnType<typeof useUtilizationReportLazyQuery>;
export type UtilizationReportQueryResult = Apollo.QueryResult<Types.UtilizationReportQuery, Types.UtilizationReportQueryVariables>;
export const VendorsDocument = gql`
    query Vendors($companyId: ID!) {
  vendors(companyId: $companyId) {
    id
    name
  }
}
    `;

/**
 * __useVendorsQuery__
 *
 * To run a query within a React component, call `useVendorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useVendorsQuery(baseOptions: Apollo.QueryHookOptions<Types.VendorsQuery, Types.VendorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.VendorsQuery, Types.VendorsQueryVariables>(VendorsDocument, options);
      }
export function useVendorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.VendorsQuery, Types.VendorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.VendorsQuery, Types.VendorsQueryVariables>(VendorsDocument, options);
        }
export type VendorsQueryHookResult = ReturnType<typeof useVendorsQuery>;
export type VendorsLazyQueryHookResult = ReturnType<typeof useVendorsLazyQuery>;
export type VendorsQueryResult = Apollo.QueryResult<Types.VendorsQuery, Types.VendorsQueryVariables>;
export const WorkingHoursInMonthDocument = gql`
    query WorkingHoursInMonth($companyId: ID!, $start: String!, $end: String!) {
  workingHoursInMonth(companyId: $companyId, start: $start, end: $end) {
    month
    hours
  }
}
    `;

/**
 * __useWorkingHoursInMonthQuery__
 *
 * To run a query within a React component, call `useWorkingHoursInMonthQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkingHoursInMonthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkingHoursInMonthQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useWorkingHoursInMonthQuery(baseOptions: Apollo.QueryHookOptions<Types.WorkingHoursInMonthQuery, Types.WorkingHoursInMonthQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WorkingHoursInMonthQuery, Types.WorkingHoursInMonthQueryVariables>(WorkingHoursInMonthDocument, options);
      }
export function useWorkingHoursInMonthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WorkingHoursInMonthQuery, Types.WorkingHoursInMonthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WorkingHoursInMonthQuery, Types.WorkingHoursInMonthQueryVariables>(WorkingHoursInMonthDocument, options);
        }
export type WorkingHoursInMonthQueryHookResult = ReturnType<typeof useWorkingHoursInMonthQuery>;
export type WorkingHoursInMonthLazyQueryHookResult = ReturnType<typeof useWorkingHoursInMonthLazyQuery>;
export type WorkingHoursInMonthQueryResult = Apollo.QueryResult<Types.WorkingHoursInMonthQuery, Types.WorkingHoursInMonthQueryVariables>;
export const SkillCategoriesDocument = gql`
    query SkillCategories($companyId: ID!, $withSkills: Boolean = false) {
  skillCategories(companyId: $companyId) {
    ...SkillCategoryData
  }
}
    ${SkillCategoryDataFragmentDoc}`;

/**
 * __useSkillCategoriesQuery__
 *
 * To run a query within a React component, call `useSkillCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSkillCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSkillCategoriesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      withSkills: // value for 'withSkills'
 *   },
 * });
 */
export function useSkillCategoriesQuery(baseOptions: Apollo.QueryHookOptions<Types.SkillCategoriesQuery, Types.SkillCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SkillCategoriesQuery, Types.SkillCategoriesQueryVariables>(SkillCategoriesDocument, options);
      }
export function useSkillCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SkillCategoriesQuery, Types.SkillCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SkillCategoriesQuery, Types.SkillCategoriesQueryVariables>(SkillCategoriesDocument, options);
        }
export type SkillCategoriesQueryHookResult = ReturnType<typeof useSkillCategoriesQuery>;
export type SkillCategoriesLazyQueryHookResult = ReturnType<typeof useSkillCategoriesLazyQuery>;
export type SkillCategoriesQueryResult = Apollo.QueryResult<Types.SkillCategoriesQuery, Types.SkillCategoriesQueryVariables>;
export const CreateSkillCategoryDocument = gql`
    mutation CreateSkillCategory($companyId: ID!, $data: ISkillCategory!, $withSkills: Boolean = false) {
  createSkillCategory(companyId: $companyId, data: $data) {
    ...SkillCategoryData
  }
}
    ${SkillCategoryDataFragmentDoc}`;
export type CreateSkillCategoryMutationFn = Apollo.MutationFunction<Types.CreateSkillCategoryMutation, Types.CreateSkillCategoryMutationVariables>;

/**
 * __useCreateSkillCategoryMutation__
 *
 * To run a mutation, you first call `useCreateSkillCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSkillCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSkillCategoryMutation, { data, loading, error }] = useCreateSkillCategoryMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *      withSkills: // value for 'withSkills'
 *   },
 * });
 */
export function useCreateSkillCategoryMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateSkillCategoryMutation, Types.CreateSkillCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateSkillCategoryMutation, Types.CreateSkillCategoryMutationVariables>(CreateSkillCategoryDocument, options);
      }
export type CreateSkillCategoryMutationHookResult = ReturnType<typeof useCreateSkillCategoryMutation>;
export type CreateSkillCategoryMutationResult = Apollo.MutationResult<Types.CreateSkillCategoryMutation>;
export type CreateSkillCategoryMutationOptions = Apollo.BaseMutationOptions<Types.CreateSkillCategoryMutation, Types.CreateSkillCategoryMutationVariables>;
export const EditSkillCategoryDocument = gql`
    mutation EditSkillCategory($companyId: ID!, $skillCategoryId: ID!, $data: ISkillCategory!, $withSkills: Boolean = false) {
  editSkillCategory(
    companyId: $companyId
    skillCategoryId: $skillCategoryId
    data: $data
  ) {
    ...SkillCategoryData
  }
}
    ${SkillCategoryDataFragmentDoc}`;
export type EditSkillCategoryMutationFn = Apollo.MutationFunction<Types.EditSkillCategoryMutation, Types.EditSkillCategoryMutationVariables>;

/**
 * __useEditSkillCategoryMutation__
 *
 * To run a mutation, you first call `useEditSkillCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSkillCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSkillCategoryMutation, { data, loading, error }] = useEditSkillCategoryMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      skillCategoryId: // value for 'skillCategoryId'
 *      data: // value for 'data'
 *      withSkills: // value for 'withSkills'
 *   },
 * });
 */
export function useEditSkillCategoryMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditSkillCategoryMutation, Types.EditSkillCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditSkillCategoryMutation, Types.EditSkillCategoryMutationVariables>(EditSkillCategoryDocument, options);
      }
export type EditSkillCategoryMutationHookResult = ReturnType<typeof useEditSkillCategoryMutation>;
export type EditSkillCategoryMutationResult = Apollo.MutationResult<Types.EditSkillCategoryMutation>;
export type EditSkillCategoryMutationOptions = Apollo.BaseMutationOptions<Types.EditSkillCategoryMutation, Types.EditSkillCategoryMutationVariables>;
export const DeleteSkillCategoryDocument = gql`
    mutation DeleteSkillCategory($companyId: ID!, $skillCategoryId: ID!) {
  deleteSkillCategory(companyId: $companyId, skillCategoryId: $skillCategoryId) {
    id
  }
}
    `;
export type DeleteSkillCategoryMutationFn = Apollo.MutationFunction<Types.DeleteSkillCategoryMutation, Types.DeleteSkillCategoryMutationVariables>;

/**
 * __useDeleteSkillCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteSkillCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSkillCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSkillCategoryMutation, { data, loading, error }] = useDeleteSkillCategoryMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      skillCategoryId: // value for 'skillCategoryId'
 *   },
 * });
 */
export function useDeleteSkillCategoryMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteSkillCategoryMutation, Types.DeleteSkillCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteSkillCategoryMutation, Types.DeleteSkillCategoryMutationVariables>(DeleteSkillCategoryDocument, options);
      }
export type DeleteSkillCategoryMutationHookResult = ReturnType<typeof useDeleteSkillCategoryMutation>;
export type DeleteSkillCategoryMutationResult = Apollo.MutationResult<Types.DeleteSkillCategoryMutation>;
export type DeleteSkillCategoryMutationOptions = Apollo.BaseMutationOptions<Types.DeleteSkillCategoryMutation, Types.DeleteSkillCategoryMutationVariables>;
export const TimeLogsDocument = gql`
    query TimeLogs($companyId: ID!, $data: IQueryTimeLogs!, $paginationData: IPaginationTimeLogs) {
  timeLogs(companyId: $companyId, data: $data, paginationData: $paginationData) {
    ...TimeLogData
    reportMemberId
    member_id
  }
}
    ${TimeLogDataFragmentDoc}`;

/**
 * __useTimeLogsQuery__
 *
 * To run a query within a React component, call `useTimeLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeLogsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *      paginationData: // value for 'paginationData'
 *   },
 * });
 */
export function useTimeLogsQuery(baseOptions: Apollo.QueryHookOptions<Types.TimeLogsQuery, Types.TimeLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TimeLogsQuery, Types.TimeLogsQueryVariables>(TimeLogsDocument, options);
      }
export function useTimeLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TimeLogsQuery, Types.TimeLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TimeLogsQuery, Types.TimeLogsQueryVariables>(TimeLogsDocument, options);
        }
export type TimeLogsQueryHookResult = ReturnType<typeof useTimeLogsQuery>;
export type TimeLogsLazyQueryHookResult = ReturnType<typeof useTimeLogsLazyQuery>;
export type TimeLogsQueryResult = Apollo.QueryResult<Types.TimeLogsQuery, Types.TimeLogsQueryVariables>;
export const TotalTimeLogsDocument = gql`
    query TotalTimeLogs($companyId: ID!, $data: IQueryTimeLogs!, $paginationData: IPaginationTimeLogs) {
  totalTimeLogs(
    companyId: $companyId
    data: $data
    paginationData: $paginationData
  ) {
    ...TotalTimeLogData
  }
}
    ${TotalTimeLogDataFragmentDoc}`;

/**
 * __useTotalTimeLogsQuery__
 *
 * To run a query within a React component, call `useTotalTimeLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalTimeLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalTimeLogsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *      paginationData: // value for 'paginationData'
 *   },
 * });
 */
export function useTotalTimeLogsQuery(baseOptions: Apollo.QueryHookOptions<Types.TotalTimeLogsQuery, Types.TotalTimeLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TotalTimeLogsQuery, Types.TotalTimeLogsQueryVariables>(TotalTimeLogsDocument, options);
      }
export function useTotalTimeLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TotalTimeLogsQuery, Types.TotalTimeLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TotalTimeLogsQuery, Types.TotalTimeLogsQueryVariables>(TotalTimeLogsDocument, options);
        }
export type TotalTimeLogsQueryHookResult = ReturnType<typeof useTotalTimeLogsQuery>;
export type TotalTimeLogsLazyQueryHookResult = ReturnType<typeof useTotalTimeLogsLazyQuery>;
export type TotalTimeLogsQueryResult = Apollo.QueryResult<Types.TotalTimeLogsQuery, Types.TotalTimeLogsQueryVariables>;
export const CreateTimeLogDocument = gql`
    mutation CreateTimeLog($companyId: ID!, $memberId: ID!, $projectId: ID!, $data: ITimeLog!) {
  createTimeLog(
    companyId: $companyId
    memberId: $memberId
    projectId: $projectId
    data: $data
  ) {
    ...TimeLogData
    member_id
  }
}
    ${TimeLogDataFragmentDoc}`;
export type CreateTimeLogMutationFn = Apollo.MutationFunction<Types.CreateTimeLogMutation, Types.CreateTimeLogMutationVariables>;

/**
 * __useCreateTimeLogMutation__
 *
 * To run a mutation, you first call `useCreateTimeLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTimeLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTimeLogMutation, { data, loading, error }] = useCreateTimeLogMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      memberId: // value for 'memberId'
 *      projectId: // value for 'projectId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTimeLogMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateTimeLogMutation, Types.CreateTimeLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateTimeLogMutation, Types.CreateTimeLogMutationVariables>(CreateTimeLogDocument, options);
      }
export type CreateTimeLogMutationHookResult = ReturnType<typeof useCreateTimeLogMutation>;
export type CreateTimeLogMutationResult = Apollo.MutationResult<Types.CreateTimeLogMutation>;
export type CreateTimeLogMutationOptions = Apollo.BaseMutationOptions<Types.CreateTimeLogMutation, Types.CreateTimeLogMutationVariables>;
export const EditTimeLogDocument = gql`
    mutation EditTimeLog($companyId: ID!, $timeLogId: ID!, $projectId: ID!, $data: ITimeLog!) {
  editTimeLog(
    companyId: $companyId
    timeLogId: $timeLogId
    projectId: $projectId
    data: $data
  ) {
    ...TimeLogData
  }
}
    ${TimeLogDataFragmentDoc}`;
export type EditTimeLogMutationFn = Apollo.MutationFunction<Types.EditTimeLogMutation, Types.EditTimeLogMutationVariables>;

/**
 * __useEditTimeLogMutation__
 *
 * To run a mutation, you first call `useEditTimeLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTimeLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTimeLogMutation, { data, loading, error }] = useEditTimeLogMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      timeLogId: // value for 'timeLogId'
 *      projectId: // value for 'projectId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditTimeLogMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditTimeLogMutation, Types.EditTimeLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditTimeLogMutation, Types.EditTimeLogMutationVariables>(EditTimeLogDocument, options);
      }
export type EditTimeLogMutationHookResult = ReturnType<typeof useEditTimeLogMutation>;
export type EditTimeLogMutationResult = Apollo.MutationResult<Types.EditTimeLogMutation>;
export type EditTimeLogMutationOptions = Apollo.BaseMutationOptions<Types.EditTimeLogMutation, Types.EditTimeLogMutationVariables>;
export const DeleteTimeLogDocument = gql`
    mutation DeleteTimeLog($companyId: ID!, $timeLogIds: [ID!]!) {
  deleteTimeLog(companyId: $companyId, timeLogIds: $timeLogIds) {
    id
    date
    minutes
    assignment_id
  }
}
    `;
export type DeleteTimeLogMutationFn = Apollo.MutationFunction<Types.DeleteTimeLogMutation, Types.DeleteTimeLogMutationVariables>;

/**
 * __useDeleteTimeLogMutation__
 *
 * To run a mutation, you first call `useDeleteTimeLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTimeLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTimeLogMutation, { data, loading, error }] = useDeleteTimeLogMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      timeLogIds: // value for 'timeLogIds'
 *   },
 * });
 */
export function useDeleteTimeLogMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteTimeLogMutation, Types.DeleteTimeLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteTimeLogMutation, Types.DeleteTimeLogMutationVariables>(DeleteTimeLogDocument, options);
      }
export type DeleteTimeLogMutationHookResult = ReturnType<typeof useDeleteTimeLogMutation>;
export type DeleteTimeLogMutationResult = Apollo.MutationResult<Types.DeleteTimeLogMutation>;
export type DeleteTimeLogMutationOptions = Apollo.BaseMutationOptions<Types.DeleteTimeLogMutation, Types.DeleteTimeLogMutationVariables>;
export const CreateTimeLogPeriodDocument = gql`
    mutation CreateTimeLogPeriod($companyId: ID!, $memberId: ID!, $projectId: ID!, $data: ITimeLogPeriod!) {
  createTimeLogPeriod(
    companyId: $companyId
    memberId: $memberId
    projectId: $projectId
    data: $data
  ) {
    ...TimeLogData
  }
}
    ${TimeLogDataFragmentDoc}`;
export type CreateTimeLogPeriodMutationFn = Apollo.MutationFunction<Types.CreateTimeLogPeriodMutation, Types.CreateTimeLogPeriodMutationVariables>;

/**
 * __useCreateTimeLogPeriodMutation__
 *
 * To run a mutation, you first call `useCreateTimeLogPeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTimeLogPeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTimeLogPeriodMutation, { data, loading, error }] = useCreateTimeLogPeriodMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      memberId: // value for 'memberId'
 *      projectId: // value for 'projectId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTimeLogPeriodMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateTimeLogPeriodMutation, Types.CreateTimeLogPeriodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateTimeLogPeriodMutation, Types.CreateTimeLogPeriodMutationVariables>(CreateTimeLogPeriodDocument, options);
      }
export type CreateTimeLogPeriodMutationHookResult = ReturnType<typeof useCreateTimeLogPeriodMutation>;
export type CreateTimeLogPeriodMutationResult = Apollo.MutationResult<Types.CreateTimeLogPeriodMutation>;
export type CreateTimeLogPeriodMutationOptions = Apollo.BaseMutationOptions<Types.CreateTimeLogPeriodMutation, Types.CreateTimeLogPeriodMutationVariables>;
export const ExpensesDocument = gql`
    query Expenses($companyId: ID!, $data: IQueryExpenses!) {
  expenses(companyId: $companyId, data: $data) {
    ...ExpenseData
  }
}
    ${ExpenseDataFragmentDoc}`;

/**
 * __useExpensesQuery__
 *
 * To run a query within a React component, call `useExpensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpensesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useExpensesQuery(baseOptions: Apollo.QueryHookOptions<Types.ExpensesQuery, Types.ExpensesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ExpensesQuery, Types.ExpensesQueryVariables>(ExpensesDocument, options);
      }
export function useExpensesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ExpensesQuery, Types.ExpensesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ExpensesQuery, Types.ExpensesQueryVariables>(ExpensesDocument, options);
        }
export type ExpensesQueryHookResult = ReturnType<typeof useExpensesQuery>;
export type ExpensesLazyQueryHookResult = ReturnType<typeof useExpensesLazyQuery>;
export type ExpensesQueryResult = Apollo.QueryResult<Types.ExpensesQuery, Types.ExpensesQueryVariables>;
export const EditExpenseDocument = gql`
    mutation EditExpense($companyId: ID!, $expenseId: ID!, $data: IExpense!) {
  editExpense(companyId: $companyId, expenseId: $expenseId, data: $data) {
    ...ExpenseData
  }
}
    ${ExpenseDataFragmentDoc}`;
export type EditExpenseMutationFn = Apollo.MutationFunction<Types.EditExpenseMutation, Types.EditExpenseMutationVariables>;

/**
 * __useEditExpenseMutation__
 *
 * To run a mutation, you first call `useEditExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editExpenseMutation, { data, loading, error }] = useEditExpenseMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      expenseId: // value for 'expenseId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditExpenseMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditExpenseMutation, Types.EditExpenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditExpenseMutation, Types.EditExpenseMutationVariables>(EditExpenseDocument, options);
      }
export type EditExpenseMutationHookResult = ReturnType<typeof useEditExpenseMutation>;
export type EditExpenseMutationResult = Apollo.MutationResult<Types.EditExpenseMutation>;
export type EditExpenseMutationOptions = Apollo.BaseMutationOptions<Types.EditExpenseMutation, Types.EditExpenseMutationVariables>;
export const TimeLogsAssignmentsByProjectDocument = gql`
    query TimeLogsAssignmentsByProject($companyId: ID!, $projectId: ID!, $filters: IAssignmentFilters) {
  project(companyId: $companyId, projectId: $projectId) {
    id
    overtimeMultiplier
    assignments(filters: $filters) {
      ...ProjectAssignmentTimeLogData
    }
  }
}
    ${ProjectAssignmentTimeLogDataFragmentDoc}`;

/**
 * __useTimeLogsAssignmentsByProjectQuery__
 *
 * To run a query within a React component, call `useTimeLogsAssignmentsByProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeLogsAssignmentsByProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeLogsAssignmentsByProjectQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      projectId: // value for 'projectId'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useTimeLogsAssignmentsByProjectQuery(baseOptions: Apollo.QueryHookOptions<Types.TimeLogsAssignmentsByProjectQuery, Types.TimeLogsAssignmentsByProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TimeLogsAssignmentsByProjectQuery, Types.TimeLogsAssignmentsByProjectQueryVariables>(TimeLogsAssignmentsByProjectDocument, options);
      }
export function useTimeLogsAssignmentsByProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TimeLogsAssignmentsByProjectQuery, Types.TimeLogsAssignmentsByProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TimeLogsAssignmentsByProjectQuery, Types.TimeLogsAssignmentsByProjectQueryVariables>(TimeLogsAssignmentsByProjectDocument, options);
        }
export type TimeLogsAssignmentsByProjectQueryHookResult = ReturnType<typeof useTimeLogsAssignmentsByProjectQuery>;
export type TimeLogsAssignmentsByProjectLazyQueryHookResult = ReturnType<typeof useTimeLogsAssignmentsByProjectLazyQuery>;
export type TimeLogsAssignmentsByProjectQueryResult = Apollo.QueryResult<Types.TimeLogsAssignmentsByProjectQuery, Types.TimeLogsAssignmentsByProjectQueryVariables>;
export const ProjectByIdDocument = gql`
    query ProjectById($companyId: ID!, $projectId: ID!, $withCommission: Boolean! = false) {
  project(companyId: $companyId, projectId: $projectId) {
    ...ProjectData
    currentCommission(withCommission: $withCommission)
  }
}
    ${ProjectDataFragmentDoc}`;

/**
 * __useProjectByIdQuery__
 *
 * To run a query within a React component, call `useProjectByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectByIdQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      projectId: // value for 'projectId'
 *      withCommission: // value for 'withCommission'
 *   },
 * });
 */
export function useProjectByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.ProjectByIdQuery, Types.ProjectByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProjectByIdQuery, Types.ProjectByIdQueryVariables>(ProjectByIdDocument, options);
      }
export function useProjectByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProjectByIdQuery, Types.ProjectByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProjectByIdQuery, Types.ProjectByIdQueryVariables>(ProjectByIdDocument, options);
        }
export type ProjectByIdQueryHookResult = ReturnType<typeof useProjectByIdQuery>;
export type ProjectByIdLazyQueryHookResult = ReturnType<typeof useProjectByIdLazyQuery>;
export type ProjectByIdQueryResult = Apollo.QueryResult<Types.ProjectByIdQuery, Types.ProjectByIdQueryVariables>;
export const ProjectAssignmentsListDocument = gql`
    query projectAssignmentsList($companyId: ID!, $projectId: ID!, $paginationAssignmentData: IPaginationDashboard, $companyCurrency: Boolean) {
  projectAssignments(
    companyId: $companyId
    projectId: $projectId
    companyCurrency: $companyCurrency
    paginationAssignmentData: $paginationAssignmentData
  ) {
    totalRevenue
    totalCost
    totalCommission
    profit
    profitMargin
    cost_budget_amount
    exchangeRate
    exchangeRateCurrency
    projectCurrency
    projectAssignments {
      ...ProjectAssignmentsData
    }
  }
}
    ${ProjectAssignmentsDataFragmentDoc}`;

/**
 * __useProjectAssignmentsListQuery__
 *
 * To run a query within a React component, call `useProjectAssignmentsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectAssignmentsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectAssignmentsListQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      projectId: // value for 'projectId'
 *      paginationAssignmentData: // value for 'paginationAssignmentData'
 *      companyCurrency: // value for 'companyCurrency'
 *   },
 * });
 */
export function useProjectAssignmentsListQuery(baseOptions: Apollo.QueryHookOptions<Types.ProjectAssignmentsListQuery, Types.ProjectAssignmentsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProjectAssignmentsListQuery, Types.ProjectAssignmentsListQueryVariables>(ProjectAssignmentsListDocument, options);
      }
export function useProjectAssignmentsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProjectAssignmentsListQuery, Types.ProjectAssignmentsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProjectAssignmentsListQuery, Types.ProjectAssignmentsListQueryVariables>(ProjectAssignmentsListDocument, options);
        }
export type ProjectAssignmentsListQueryHookResult = ReturnType<typeof useProjectAssignmentsListQuery>;
export type ProjectAssignmentsListLazyQueryHookResult = ReturnType<typeof useProjectAssignmentsListLazyQuery>;
export type ProjectAssignmentsListQueryResult = Apollo.QueryResult<Types.ProjectAssignmentsListQuery, Types.ProjectAssignmentsListQueryVariables>;
export const ProjectAssignmentsListTotalsDocument = gql`
    query projectAssignmentsListTotals($companyId: ID!, $projectId: ID!, $paginationAssignmentData: IPaginationDashboard, $companyCurrency: Boolean) {
  projectAssignments(
    companyId: $companyId
    projectId: $projectId
    companyCurrency: $companyCurrency
    paginationAssignmentData: $paginationAssignmentData
  ) {
    totalRevenue
    totalCost
    totalCommission
    profit
    profitMargin
    cost_budget_amount
  }
}
    `;

/**
 * __useProjectAssignmentsListTotalsQuery__
 *
 * To run a query within a React component, call `useProjectAssignmentsListTotalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectAssignmentsListTotalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectAssignmentsListTotalsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      projectId: // value for 'projectId'
 *      paginationAssignmentData: // value for 'paginationAssignmentData'
 *      companyCurrency: // value for 'companyCurrency'
 *   },
 * });
 */
export function useProjectAssignmentsListTotalsQuery(baseOptions: Apollo.QueryHookOptions<Types.ProjectAssignmentsListTotalsQuery, Types.ProjectAssignmentsListTotalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProjectAssignmentsListTotalsQuery, Types.ProjectAssignmentsListTotalsQueryVariables>(ProjectAssignmentsListTotalsDocument, options);
      }
export function useProjectAssignmentsListTotalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProjectAssignmentsListTotalsQuery, Types.ProjectAssignmentsListTotalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProjectAssignmentsListTotalsQuery, Types.ProjectAssignmentsListTotalsQueryVariables>(ProjectAssignmentsListTotalsDocument, options);
        }
export type ProjectAssignmentsListTotalsQueryHookResult = ReturnType<typeof useProjectAssignmentsListTotalsQuery>;
export type ProjectAssignmentsListTotalsLazyQueryHookResult = ReturnType<typeof useProjectAssignmentsListTotalsLazyQuery>;
export type ProjectAssignmentsListTotalsQueryResult = Apollo.QueryResult<Types.ProjectAssignmentsListTotalsQuery, Types.ProjectAssignmentsListTotalsQueryVariables>;
export const ProfitabilityDocument = gql`
    query profitability($companyId: ID!, $projectId: ID!, $companyCurrency: Boolean, $data: IPaginationDashboard) {
  profitability(
    companyId: $companyId
    projectId: $projectId
    companyCurrency: $companyCurrency
    data: $data
  ) {
    exchangeRate
    exchangeRateCurrency
    projectCurrency
    profitability {
      ...ProfitabilityData
    }
  }
}
    ${ProfitabilityDataFragmentDoc}`;

/**
 * __useProfitabilityQuery__
 *
 * To run a query within a React component, call `useProfitabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfitabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfitabilityQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      projectId: // value for 'projectId'
 *      companyCurrency: // value for 'companyCurrency'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useProfitabilityQuery(baseOptions: Apollo.QueryHookOptions<Types.ProfitabilityQuery, Types.ProfitabilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProfitabilityQuery, Types.ProfitabilityQueryVariables>(ProfitabilityDocument, options);
      }
export function useProfitabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProfitabilityQuery, Types.ProfitabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProfitabilityQuery, Types.ProfitabilityQueryVariables>(ProfitabilityDocument, options);
        }
export type ProfitabilityQueryHookResult = ReturnType<typeof useProfitabilityQuery>;
export type ProfitabilityLazyQueryHookResult = ReturnType<typeof useProfitabilityLazyQuery>;
export type ProfitabilityQueryResult = Apollo.QueryResult<Types.ProfitabilityQuery, Types.ProfitabilityQueryVariables>;
export const TeamDynamicsDocument = gql`
    query teamDynamics($companyId: ID!, $projectId: ID!, $data: IPaginationDashboard) {
  teamDynamics(companyId: $companyId, projectId: $projectId, data: $data) {
    ...TeamDynamicsData
  }
}
    ${TeamDynamicsDataFragmentDoc}`;

/**
 * __useTeamDynamicsQuery__
 *
 * To run a query within a React component, call `useTeamDynamicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamDynamicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamDynamicsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      projectId: // value for 'projectId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useTeamDynamicsQuery(baseOptions: Apollo.QueryHookOptions<Types.TeamDynamicsQuery, Types.TeamDynamicsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TeamDynamicsQuery, Types.TeamDynamicsQueryVariables>(TeamDynamicsDocument, options);
      }
export function useTeamDynamicsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TeamDynamicsQuery, Types.TeamDynamicsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TeamDynamicsQuery, Types.TeamDynamicsQueryVariables>(TeamDynamicsDocument, options);
        }
export type TeamDynamicsQueryHookResult = ReturnType<typeof useTeamDynamicsQuery>;
export type TeamDynamicsLazyQueryHookResult = ReturnType<typeof useTeamDynamicsLazyQuery>;
export type TeamDynamicsQueryResult = Apollo.QueryResult<Types.TeamDynamicsQuery, Types.TeamDynamicsQueryVariables>;
export const EditProjectDocument = gql`
    mutation EditProject($companyId: ID!, $projectId: ID!, $data: IProject!) {
  editProject(companyId: $companyId, projectId: $projectId, data: $data) {
    ...ProjectData
  }
}
    ${ProjectDataFragmentDoc}`;
export type EditProjectMutationFn = Apollo.MutationFunction<Types.EditProjectMutation, Types.EditProjectMutationVariables>;

/**
 * __useEditProjectMutation__
 *
 * To run a mutation, you first call `useEditProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProjectMutation, { data, loading, error }] = useEditProjectMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      projectId: // value for 'projectId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditProjectMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditProjectMutation, Types.EditProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditProjectMutation, Types.EditProjectMutationVariables>(EditProjectDocument, options);
      }
export type EditProjectMutationHookResult = ReturnType<typeof useEditProjectMutation>;
export type EditProjectMutationResult = Apollo.MutationResult<Types.EditProjectMutation>;
export type EditProjectMutationOptions = Apollo.BaseMutationOptions<Types.EditProjectMutation, Types.EditProjectMutationVariables>;
export const ManagedProjectsDocument = gql`
    query ManagedProjects($companyId: ID!, $filterData: IFilterProjectData, $groupValue: GroupBy, $isArchived: Boolean) {
  managedProjects(
    companyId: $companyId
    filterData: $filterData
    groupValue: $groupValue
    isArchived: $isArchived
  ) {
    ...ManagedProjectData
  }
}
    ${ManagedProjectDataFragmentDoc}`;

/**
 * __useManagedProjectsQuery__
 *
 * To run a query within a React component, call `useManagedProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useManagedProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagedProjectsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      filterData: // value for 'filterData'
 *      groupValue: // value for 'groupValue'
 *      isArchived: // value for 'isArchived'
 *   },
 * });
 */
export function useManagedProjectsQuery(baseOptions: Apollo.QueryHookOptions<Types.ManagedProjectsQuery, Types.ManagedProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ManagedProjectsQuery, Types.ManagedProjectsQueryVariables>(ManagedProjectsDocument, options);
      }
export function useManagedProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ManagedProjectsQuery, Types.ManagedProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ManagedProjectsQuery, Types.ManagedProjectsQueryVariables>(ManagedProjectsDocument, options);
        }
export type ManagedProjectsQueryHookResult = ReturnType<typeof useManagedProjectsQuery>;
export type ManagedProjectsLazyQueryHookResult = ReturnType<typeof useManagedProjectsLazyQuery>;
export type ManagedProjectsQueryResult = Apollo.QueryResult<Types.ManagedProjectsQuery, Types.ManagedProjectsQueryVariables>;
export const ManagedProjectsOptionsDocument = gql`
    query ManagedProjectsOptions($companyId: ID!, $isArchived: Boolean) {
  managedProjects(companyId: $companyId, isArchived: $isArchived) {
    id
    name
    color
  }
}
    `;

/**
 * __useManagedProjectsOptionsQuery__
 *
 * To run a query within a React component, call `useManagedProjectsOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useManagedProjectsOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagedProjectsOptionsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      isArchived: // value for 'isArchived'
 *   },
 * });
 */
export function useManagedProjectsOptionsQuery(baseOptions: Apollo.QueryHookOptions<Types.ManagedProjectsOptionsQuery, Types.ManagedProjectsOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ManagedProjectsOptionsQuery, Types.ManagedProjectsOptionsQueryVariables>(ManagedProjectsOptionsDocument, options);
      }
export function useManagedProjectsOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ManagedProjectsOptionsQuery, Types.ManagedProjectsOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ManagedProjectsOptionsQuery, Types.ManagedProjectsOptionsQueryVariables>(ManagedProjectsOptionsDocument, options);
        }
export type ManagedProjectsOptionsQueryHookResult = ReturnType<typeof useManagedProjectsOptionsQuery>;
export type ManagedProjectsOptionsLazyQueryHookResult = ReturnType<typeof useManagedProjectsOptionsLazyQuery>;
export type ManagedProjectsOptionsQueryResult = Apollo.QueryResult<Types.ManagedProjectsOptionsQuery, Types.ManagedProjectsOptionsQueryVariables>;
export const ResourcePlanningMembersDocument = gql`
    query ResourcePlanningMembers($companyId: ID!, $paginationAssignmentData: IPaginationAssignment, $filterData: IFilterResourcePlanningMembers, $searchValue: String, $withSpecialization: Boolean! = false, $withLeaves: Boolean! = true, $withRequests: Boolean! = true) {
  resourcePlanningMembers(
    companyId: $companyId
    paginationAssignmentData: $paginationAssignmentData
    filterData: $filterData
    searchValue: $searchValue
  ) {
    ...ResourcePlanningMembersData
  }
}
    ${ResourcePlanningMembersDataFragmentDoc}`;

/**
 * __useResourcePlanningMembersQuery__
 *
 * To run a query within a React component, call `useResourcePlanningMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourcePlanningMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourcePlanningMembersQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      paginationAssignmentData: // value for 'paginationAssignmentData'
 *      filterData: // value for 'filterData'
 *      searchValue: // value for 'searchValue'
 *      withSpecialization: // value for 'withSpecialization'
 *      withLeaves: // value for 'withLeaves'
 *      withRequests: // value for 'withRequests'
 *   },
 * });
 */
export function useResourcePlanningMembersQuery(baseOptions: Apollo.QueryHookOptions<Types.ResourcePlanningMembersQuery, Types.ResourcePlanningMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ResourcePlanningMembersQuery, Types.ResourcePlanningMembersQueryVariables>(ResourcePlanningMembersDocument, options);
      }
export function useResourcePlanningMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ResourcePlanningMembersQuery, Types.ResourcePlanningMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ResourcePlanningMembersQuery, Types.ResourcePlanningMembersQueryVariables>(ResourcePlanningMembersDocument, options);
        }
export type ResourcePlanningMembersQueryHookResult = ReturnType<typeof useResourcePlanningMembersQuery>;
export type ResourcePlanningMembersLazyQueryHookResult = ReturnType<typeof useResourcePlanningMembersLazyQuery>;
export type ResourcePlanningMembersQueryResult = Apollo.QueryResult<Types.ResourcePlanningMembersQuery, Types.ResourcePlanningMembersQueryVariables>;
export const ResourcePlanningProjectsDocument = gql`
    query ResourcePlanningProjects($companyId: ID!, $paginationAssignmentData: IPaginationAssignment, $isArchived: Boolean! = false, $searchValue: String, $withLeaves: Boolean! = true) {
  resourcePlanningProjects(
    companyId: $companyId
    paginationAssignmentData: $paginationAssignmentData
    isArchived: $isArchived
    searchValue: $searchValue
  ) {
    ...ResourcePlanningProjectData
  }
}
    ${ResourcePlanningProjectDataFragmentDoc}`;

/**
 * __useResourcePlanningProjectsQuery__
 *
 * To run a query within a React component, call `useResourcePlanningProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourcePlanningProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourcePlanningProjectsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      paginationAssignmentData: // value for 'paginationAssignmentData'
 *      isArchived: // value for 'isArchived'
 *      searchValue: // value for 'searchValue'
 *      withLeaves: // value for 'withLeaves'
 *   },
 * });
 */
export function useResourcePlanningProjectsQuery(baseOptions: Apollo.QueryHookOptions<Types.ResourcePlanningProjectsQuery, Types.ResourcePlanningProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ResourcePlanningProjectsQuery, Types.ResourcePlanningProjectsQueryVariables>(ResourcePlanningProjectsDocument, options);
      }
export function useResourcePlanningProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ResourcePlanningProjectsQuery, Types.ResourcePlanningProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ResourcePlanningProjectsQuery, Types.ResourcePlanningProjectsQueryVariables>(ResourcePlanningProjectsDocument, options);
        }
export type ResourcePlanningProjectsQueryHookResult = ReturnType<typeof useResourcePlanningProjectsQuery>;
export type ResourcePlanningProjectsLazyQueryHookResult = ReturnType<typeof useResourcePlanningProjectsLazyQuery>;
export type ResourcePlanningProjectsQueryResult = Apollo.QueryResult<Types.ResourcePlanningProjectsQuery, Types.ResourcePlanningProjectsQueryVariables>;
export const CreateScenarioDocument = gql`
    mutation createScenario($companyId: ID!, $data: ICreateScenario!) {
  createScenario(companyId: $companyId, data: $data) {
    ...ScenarioData
  }
}
    ${ScenarioDataFragmentDoc}`;
export type CreateScenarioMutationFn = Apollo.MutationFunction<Types.CreateScenarioMutation, Types.CreateScenarioMutationVariables>;

/**
 * __useCreateScenarioMutation__
 *
 * To run a mutation, you first call `useCreateScenarioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScenarioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScenarioMutation, { data, loading, error }] = useCreateScenarioMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateScenarioMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateScenarioMutation, Types.CreateScenarioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateScenarioMutation, Types.CreateScenarioMutationVariables>(CreateScenarioDocument, options);
      }
export type CreateScenarioMutationHookResult = ReturnType<typeof useCreateScenarioMutation>;
export type CreateScenarioMutationResult = Apollo.MutationResult<Types.CreateScenarioMutation>;
export type CreateScenarioMutationOptions = Apollo.BaseMutationOptions<Types.CreateScenarioMutation, Types.CreateScenarioMutationVariables>;
export const CreateScenarioMemberDocument = gql`
    mutation createScenarioMember($companyId: ID!, $projectId: ID!, $data: ICreateScenarioMembers!) {
  createScenarioMembers(companyId: $companyId, projectId: $projectId, data: $data) {
    id
  }
}
    `;
export type CreateScenarioMemberMutationFn = Apollo.MutationFunction<Types.CreateScenarioMemberMutation, Types.CreateScenarioMemberMutationVariables>;

/**
 * __useCreateScenarioMemberMutation__
 *
 * To run a mutation, you first call `useCreateScenarioMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScenarioMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScenarioMemberMutation, { data, loading, error }] = useCreateScenarioMemberMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      projectId: // value for 'projectId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateScenarioMemberMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateScenarioMemberMutation, Types.CreateScenarioMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateScenarioMemberMutation, Types.CreateScenarioMemberMutationVariables>(CreateScenarioMemberDocument, options);
      }
export type CreateScenarioMemberMutationHookResult = ReturnType<typeof useCreateScenarioMemberMutation>;
export type CreateScenarioMemberMutationResult = Apollo.MutationResult<Types.CreateScenarioMemberMutation>;
export type CreateScenarioMemberMutationOptions = Apollo.BaseMutationOptions<Types.CreateScenarioMemberMutation, Types.CreateScenarioMemberMutationVariables>;
export const RefreshScenarioDataDocument = gql`
    mutation RefreshScenarioData($companyId: ID!, $projectId: ID!, $scenarioId: ID!) {
  refreshScenarioData(
    companyId: $companyId
    projectId: $projectId
    scenarioId: $scenarioId
  ) {
    id
  }
}
    `;
export type RefreshScenarioDataMutationFn = Apollo.MutationFunction<Types.RefreshScenarioDataMutation, Types.RefreshScenarioDataMutationVariables>;

/**
 * __useRefreshScenarioDataMutation__
 *
 * To run a mutation, you first call `useRefreshScenarioDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshScenarioDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshScenarioDataMutation, { data, loading, error }] = useRefreshScenarioDataMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      projectId: // value for 'projectId'
 *      scenarioId: // value for 'scenarioId'
 *   },
 * });
 */
export function useRefreshScenarioDataMutation(baseOptions?: Apollo.MutationHookOptions<Types.RefreshScenarioDataMutation, Types.RefreshScenarioDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RefreshScenarioDataMutation, Types.RefreshScenarioDataMutationVariables>(RefreshScenarioDataDocument, options);
      }
export type RefreshScenarioDataMutationHookResult = ReturnType<typeof useRefreshScenarioDataMutation>;
export type RefreshScenarioDataMutationResult = Apollo.MutationResult<Types.RefreshScenarioDataMutation>;
export type RefreshScenarioDataMutationOptions = Apollo.BaseMutationOptions<Types.RefreshScenarioDataMutation, Types.RefreshScenarioDataMutationVariables>;
export const RefreshScenarioExchangeRateDocument = gql`
    mutation RefreshScenarioExchangeRate($companyId: ID!, $scenarioId: ID!) {
  refreshScenarioExchangeRate(companyId: $companyId, scenarioId: $scenarioId) {
    id
  }
}
    `;
export type RefreshScenarioExchangeRateMutationFn = Apollo.MutationFunction<Types.RefreshScenarioExchangeRateMutation, Types.RefreshScenarioExchangeRateMutationVariables>;

/**
 * __useRefreshScenarioExchangeRateMutation__
 *
 * To run a mutation, you first call `useRefreshScenarioExchangeRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshScenarioExchangeRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshScenarioExchangeRateMutation, { data, loading, error }] = useRefreshScenarioExchangeRateMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      scenarioId: // value for 'scenarioId'
 *   },
 * });
 */
export function useRefreshScenarioExchangeRateMutation(baseOptions?: Apollo.MutationHookOptions<Types.RefreshScenarioExchangeRateMutation, Types.RefreshScenarioExchangeRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RefreshScenarioExchangeRateMutation, Types.RefreshScenarioExchangeRateMutationVariables>(RefreshScenarioExchangeRateDocument, options);
      }
export type RefreshScenarioExchangeRateMutationHookResult = ReturnType<typeof useRefreshScenarioExchangeRateMutation>;
export type RefreshScenarioExchangeRateMutationResult = Apollo.MutationResult<Types.RefreshScenarioExchangeRateMutation>;
export type RefreshScenarioExchangeRateMutationOptions = Apollo.BaseMutationOptions<Types.RefreshScenarioExchangeRateMutation, Types.RefreshScenarioExchangeRateMutationVariables>;
export const ScenarioDocument = gql`
    query scenario($companyId: ID!, $scenarioId: ID!, $projectId: ID!, $pagination: IPagination, $companyCurrency: Boolean) {
  scenario(companyId: $companyId, projectId: $projectId, scenarioId: $scenarioId) {
    ...ScenarioDetailsData
    members(paginationData: $pagination, companyCurrency: $companyCurrency) {
      ...ScenarioMembersData
    }
  }
}
    ${ScenarioDetailsDataFragmentDoc}
${ScenarioMembersDataFragmentDoc}`;

/**
 * __useScenarioQuery__
 *
 * To run a query within a React component, call `useScenarioQuery` and pass it any options that fit your needs.
 * When your component renders, `useScenarioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScenarioQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      scenarioId: // value for 'scenarioId'
 *      projectId: // value for 'projectId'
 *      pagination: // value for 'pagination'
 *      companyCurrency: // value for 'companyCurrency'
 *   },
 * });
 */
export function useScenarioQuery(baseOptions: Apollo.QueryHookOptions<Types.ScenarioQuery, Types.ScenarioQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ScenarioQuery, Types.ScenarioQueryVariables>(ScenarioDocument, options);
      }
export function useScenarioLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ScenarioQuery, Types.ScenarioQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ScenarioQuery, Types.ScenarioQueryVariables>(ScenarioDocument, options);
        }
export type ScenarioQueryHookResult = ReturnType<typeof useScenarioQuery>;
export type ScenarioLazyQueryHookResult = ReturnType<typeof useScenarioLazyQuery>;
export type ScenarioQueryResult = Apollo.QueryResult<Types.ScenarioQuery, Types.ScenarioQueryVariables>;
export const ScenarioForecastDocument = gql`
    mutation ScenarioForecast($companyId: ID!, $projectId: ID!, $data: IForecastSettings!, $scenarioId: ID!) {
  scenarioForecast(
    companyId: $companyId
    projectId: $projectId
    data: $data
    scenarioId: $scenarioId
  ) {
    ...ScenarioDetailsData
    members {
      ...ScenarioMembersData
    }
  }
}
    ${ScenarioDetailsDataFragmentDoc}
${ScenarioMembersDataFragmentDoc}`;
export type ScenarioForecastMutationFn = Apollo.MutationFunction<Types.ScenarioForecastMutation, Types.ScenarioForecastMutationVariables>;

/**
 * __useScenarioForecastMutation__
 *
 * To run a mutation, you first call `useScenarioForecastMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScenarioForecastMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scenarioForecastMutation, { data, loading, error }] = useScenarioForecastMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      projectId: // value for 'projectId'
 *      data: // value for 'data'
 *      scenarioId: // value for 'scenarioId'
 *   },
 * });
 */
export function useScenarioForecastMutation(baseOptions?: Apollo.MutationHookOptions<Types.ScenarioForecastMutation, Types.ScenarioForecastMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ScenarioForecastMutation, Types.ScenarioForecastMutationVariables>(ScenarioForecastDocument, options);
      }
export type ScenarioForecastMutationHookResult = ReturnType<typeof useScenarioForecastMutation>;
export type ScenarioForecastMutationResult = Apollo.MutationResult<Types.ScenarioForecastMutation>;
export type ScenarioForecastMutationOptions = Apollo.BaseMutationOptions<Types.ScenarioForecastMutation, Types.ScenarioForecastMutationVariables>;
export const ScenariosDocument = gql`
    query Scenarios($companyId: ID!, $isArchived: Boolean, $projectId: ID) {
  scenarios(companyId: $companyId, isArchived: $isArchived, projectId: $projectId) {
    ...ScenarioData
  }
}
    ${ScenarioDataFragmentDoc}`;

/**
 * __useScenariosQuery__
 *
 * To run a query within a React component, call `useScenariosQuery` and pass it any options that fit your needs.
 * When your component renders, `useScenariosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScenariosQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      isArchived: // value for 'isArchived'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useScenariosQuery(baseOptions: Apollo.QueryHookOptions<Types.ScenariosQuery, Types.ScenariosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ScenariosQuery, Types.ScenariosQueryVariables>(ScenariosDocument, options);
      }
export function useScenariosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ScenariosQuery, Types.ScenariosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ScenariosQuery, Types.ScenariosQueryVariables>(ScenariosDocument, options);
        }
export type ScenariosQueryHookResult = ReturnType<typeof useScenariosQuery>;
export type ScenariosLazyQueryHookResult = ReturnType<typeof useScenariosLazyQuery>;
export type ScenariosQueryResult = Apollo.QueryResult<Types.ScenariosQuery, Types.ScenariosQueryVariables>;
export const EditScenarioMemberAllocationDocument = gql`
    mutation EditScenarioMemberAllocation($companyId: ID!, $scenarioMembersEntityId: ID!, $data: IScenarioMembersEntity!) {
  editScenarioMembersEntity(
    companyId: $companyId
    scenarioMembersEntityId: $scenarioMembersEntityId
    data: $data
  ) {
    scenarioMembersId
    allocationPerCent
    actualAllocationPerCent
    allocationTimeAmount
    cost
    revenue
    id
    profit
    profitMargin
    commission
  }
}
    `;
export type EditScenarioMemberAllocationMutationFn = Apollo.MutationFunction<Types.EditScenarioMemberAllocationMutation, Types.EditScenarioMemberAllocationMutationVariables>;

/**
 * __useEditScenarioMemberAllocationMutation__
 *
 * To run a mutation, you first call `useEditScenarioMemberAllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditScenarioMemberAllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editScenarioMemberAllocationMutation, { data, loading, error }] = useEditScenarioMemberAllocationMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      scenarioMembersEntityId: // value for 'scenarioMembersEntityId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditScenarioMemberAllocationMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditScenarioMemberAllocationMutation, Types.EditScenarioMemberAllocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditScenarioMemberAllocationMutation, Types.EditScenarioMemberAllocationMutationVariables>(EditScenarioMemberAllocationDocument, options);
      }
export type EditScenarioMemberAllocationMutationHookResult = ReturnType<typeof useEditScenarioMemberAllocationMutation>;
export type EditScenarioMemberAllocationMutationResult = Apollo.MutationResult<Types.EditScenarioMemberAllocationMutation>;
export type EditScenarioMemberAllocationMutationOptions = Apollo.BaseMutationOptions<Types.EditScenarioMemberAllocationMutation, Types.EditScenarioMemberAllocationMutationVariables>;
export const EditScenarioMembersRevenueDocument = gql`
    mutation EditScenarioMembersRevenue($companyId: ID!, $scenarioMembersEntityId: ID!, $data: IScenarioMembersEntity!, $companyCurrency: Boolean) {
  editScenarioMembersEntity(
    companyId: $companyId
    scenarioMembersEntityId: $scenarioMembersEntityId
    data: $data
    companyCurrency: $companyCurrency
  ) {
    scenarioMembersId
    id
    revenue
    profit
    profitMargin
    externalRateUnit
    externalRateAmount
    commission
  }
}
    `;
export type EditScenarioMembersRevenueMutationFn = Apollo.MutationFunction<Types.EditScenarioMembersRevenueMutation, Types.EditScenarioMembersRevenueMutationVariables>;

/**
 * __useEditScenarioMembersRevenueMutation__
 *
 * To run a mutation, you first call `useEditScenarioMembersRevenueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditScenarioMembersRevenueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editScenarioMembersRevenueMutation, { data, loading, error }] = useEditScenarioMembersRevenueMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      scenarioMembersEntityId: // value for 'scenarioMembersEntityId'
 *      data: // value for 'data'
 *      companyCurrency: // value for 'companyCurrency'
 *   },
 * });
 */
export function useEditScenarioMembersRevenueMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditScenarioMembersRevenueMutation, Types.EditScenarioMembersRevenueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditScenarioMembersRevenueMutation, Types.EditScenarioMembersRevenueMutationVariables>(EditScenarioMembersRevenueDocument, options);
      }
export type EditScenarioMembersRevenueMutationHookResult = ReturnType<typeof useEditScenarioMembersRevenueMutation>;
export type EditScenarioMembersRevenueMutationResult = Apollo.MutationResult<Types.EditScenarioMembersRevenueMutation>;
export type EditScenarioMembersRevenueMutationOptions = Apollo.BaseMutationOptions<Types.EditScenarioMembersRevenueMutation, Types.EditScenarioMembersRevenueMutationVariables>;
export const EditScenarioMembersCostDocument = gql`
    mutation EditScenarioMembersCost($companyId: ID!, $scenarioMembersEntityId: ID!, $data: IScenarioMembersEntity!, $companyCurrency: Boolean) {
  editScenarioMembersEntity(
    companyId: $companyId
    scenarioMembersEntityId: $scenarioMembersEntityId
    data: $data
    companyCurrency: $companyCurrency
  ) {
    scenarioMembersId
    id
    profit
    profitMargin
    cost
    internalRateUnit
    internalRateAmount
  }
}
    `;
export type EditScenarioMembersCostMutationFn = Apollo.MutationFunction<Types.EditScenarioMembersCostMutation, Types.EditScenarioMembersCostMutationVariables>;

/**
 * __useEditScenarioMembersCostMutation__
 *
 * To run a mutation, you first call `useEditScenarioMembersCostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditScenarioMembersCostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editScenarioMembersCostMutation, { data, loading, error }] = useEditScenarioMembersCostMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      scenarioMembersEntityId: // value for 'scenarioMembersEntityId'
 *      data: // value for 'data'
 *      companyCurrency: // value for 'companyCurrency'
 *   },
 * });
 */
export function useEditScenarioMembersCostMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditScenarioMembersCostMutation, Types.EditScenarioMembersCostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditScenarioMembersCostMutation, Types.EditScenarioMembersCostMutationVariables>(EditScenarioMembersCostDocument, options);
      }
export type EditScenarioMembersCostMutationHookResult = ReturnType<typeof useEditScenarioMembersCostMutation>;
export type EditScenarioMembersCostMutationResult = Apollo.MutationResult<Types.EditScenarioMembersCostMutation>;
export type EditScenarioMembersCostMutationOptions = Apollo.BaseMutationOptions<Types.EditScenarioMembersCostMutation, Types.EditScenarioMembersCostMutationVariables>;
export const DeleteScenarioDocument = gql`
    mutation DeleteScenario($companyId: ID!, $projectId: ID!, $scenarioId: ID!) {
  deleteScenario(
    companyId: $companyId
    projectId: $projectId
    scenarioId: $scenarioId
  ) {
    id
  }
}
    `;
export type DeleteScenarioMutationFn = Apollo.MutationFunction<Types.DeleteScenarioMutation, Types.DeleteScenarioMutationVariables>;

/**
 * __useDeleteScenarioMutation__
 *
 * To run a mutation, you first call `useDeleteScenarioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScenarioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScenarioMutation, { data, loading, error }] = useDeleteScenarioMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      projectId: // value for 'projectId'
 *      scenarioId: // value for 'scenarioId'
 *   },
 * });
 */
export function useDeleteScenarioMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteScenarioMutation, Types.DeleteScenarioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteScenarioMutation, Types.DeleteScenarioMutationVariables>(DeleteScenarioDocument, options);
      }
export type DeleteScenarioMutationHookResult = ReturnType<typeof useDeleteScenarioMutation>;
export type DeleteScenarioMutationResult = Apollo.MutationResult<Types.DeleteScenarioMutation>;
export type DeleteScenarioMutationOptions = Apollo.BaseMutationOptions<Types.DeleteScenarioMutation, Types.DeleteScenarioMutationVariables>;
export const DeleteScenarioMembersDocument = gql`
    mutation DeleteScenarioMembers($companyId: ID!, $projectId: ID!, $scenarioMembersId: ID!) {
  deleteScenarioMembers(
    companyId: $companyId
    projectId: $projectId
    scenarioMembersId: $scenarioMembersId
  ) {
    id
  }
}
    `;
export type DeleteScenarioMembersMutationFn = Apollo.MutationFunction<Types.DeleteScenarioMembersMutation, Types.DeleteScenarioMembersMutationVariables>;

/**
 * __useDeleteScenarioMembersMutation__
 *
 * To run a mutation, you first call `useDeleteScenarioMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScenarioMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScenarioMembersMutation, { data, loading, error }] = useDeleteScenarioMembersMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      projectId: // value for 'projectId'
 *      scenarioMembersId: // value for 'scenarioMembersId'
 *   },
 * });
 */
export function useDeleteScenarioMembersMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteScenarioMembersMutation, Types.DeleteScenarioMembersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteScenarioMembersMutation, Types.DeleteScenarioMembersMutationVariables>(DeleteScenarioMembersDocument, options);
      }
export type DeleteScenarioMembersMutationHookResult = ReturnType<typeof useDeleteScenarioMembersMutation>;
export type DeleteScenarioMembersMutationResult = Apollo.MutationResult<Types.DeleteScenarioMembersMutation>;
export type DeleteScenarioMembersMutationOptions = Apollo.BaseMutationOptions<Types.DeleteScenarioMembersMutation, Types.DeleteScenarioMembersMutationVariables>;
export const ArchiveScenarioDocument = gql`
    mutation ArchiveScenario($action: ScenarioAction!, $companyId: ID!, $projectId: ID!, $scenarioId: ID!) {
  archiveScenario(
    action: $action
    companyId: $companyId
    projectId: $projectId
    scenarioId: $scenarioId
  ) {
    ...ScenarioData
  }
}
    ${ScenarioDataFragmentDoc}`;
export type ArchiveScenarioMutationFn = Apollo.MutationFunction<Types.ArchiveScenarioMutation, Types.ArchiveScenarioMutationVariables>;

/**
 * __useArchiveScenarioMutation__
 *
 * To run a mutation, you first call `useArchiveScenarioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveScenarioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveScenarioMutation, { data, loading, error }] = useArchiveScenarioMutation({
 *   variables: {
 *      action: // value for 'action'
 *      companyId: // value for 'companyId'
 *      projectId: // value for 'projectId'
 *      scenarioId: // value for 'scenarioId'
 *   },
 * });
 */
export function useArchiveScenarioMutation(baseOptions?: Apollo.MutationHookOptions<Types.ArchiveScenarioMutation, Types.ArchiveScenarioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ArchiveScenarioMutation, Types.ArchiveScenarioMutationVariables>(ArchiveScenarioDocument, options);
      }
export type ArchiveScenarioMutationHookResult = ReturnType<typeof useArchiveScenarioMutation>;
export type ArchiveScenarioMutationResult = Apollo.MutationResult<Types.ArchiveScenarioMutation>;
export type ArchiveScenarioMutationOptions = Apollo.BaseMutationOptions<Types.ArchiveScenarioMutation, Types.ArchiveScenarioMutationVariables>;
export const DropdownProjectsDocument = gql`
    query DropdownProjects($companyId: ID!, $filterData: IFilterProjectData, $isArchived: Boolean, $actorFullAccess: Boolean) {
  managedProjects(
    companyId: $companyId
    filterData: $filterData
    isArchived: $isArchived
    actorFullAccess: $actorFullAccess
  ) {
    id
    name
    pm {
      id
    }
  }
}
    `;

/**
 * __useDropdownProjectsQuery__
 *
 * To run a query within a React component, call `useDropdownProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDropdownProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDropdownProjectsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      filterData: // value for 'filterData'
 *      isArchived: // value for 'isArchived'
 *      actorFullAccess: // value for 'actorFullAccess'
 *   },
 * });
 */
export function useDropdownProjectsQuery(baseOptions: Apollo.QueryHookOptions<Types.DropdownProjectsQuery, Types.DropdownProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DropdownProjectsQuery, Types.DropdownProjectsQueryVariables>(DropdownProjectsDocument, options);
      }
export function useDropdownProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DropdownProjectsQuery, Types.DropdownProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DropdownProjectsQuery, Types.DropdownProjectsQueryVariables>(DropdownProjectsDocument, options);
        }
export type DropdownProjectsQueryHookResult = ReturnType<typeof useDropdownProjectsQuery>;
export type DropdownProjectsLazyQueryHookResult = ReturnType<typeof useDropdownProjectsLazyQuery>;
export type DropdownProjectsQueryResult = Apollo.QueryResult<Types.DropdownProjectsQuery, Types.DropdownProjectsQueryVariables>;
export const CreateScenarioMemberEntityDocument = gql`
    mutation CreateScenarioMemberEntity($companyId: ID!, $data: ICreateScenarioMemberEntity!) {
  createScenarioMemberEntity(companyId: $companyId, data: $data) {
    ...ScenarioMemberEntityData
  }
}
    ${ScenarioMemberEntityDataFragmentDoc}`;
export type CreateScenarioMemberEntityMutationFn = Apollo.MutationFunction<Types.CreateScenarioMemberEntityMutation, Types.CreateScenarioMemberEntityMutationVariables>;

/**
 * __useCreateScenarioMemberEntityMutation__
 *
 * To run a mutation, you first call `useCreateScenarioMemberEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScenarioMemberEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScenarioMemberEntityMutation, { data, loading, error }] = useCreateScenarioMemberEntityMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateScenarioMemberEntityMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateScenarioMemberEntityMutation, Types.CreateScenarioMemberEntityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateScenarioMemberEntityMutation, Types.CreateScenarioMemberEntityMutationVariables>(CreateScenarioMemberEntityDocument, options);
      }
export type CreateScenarioMemberEntityMutationHookResult = ReturnType<typeof useCreateScenarioMemberEntityMutation>;
export type CreateScenarioMemberEntityMutationResult = Apollo.MutationResult<Types.CreateScenarioMemberEntityMutation>;
export type CreateScenarioMemberEntityMutationOptions = Apollo.BaseMutationOptions<Types.CreateScenarioMemberEntityMutation, Types.CreateScenarioMemberEntityMutationVariables>;
export const ScenarioMembersDocument = gql`
    query scenarioMembers($companyId: ID!, $scenarioId: ID!, $paginationData: IPagination, $isActive: Boolean, $companyCurrency: Boolean) {
  scenarioMembers(
    companyId: $companyId
    scenarioId: $scenarioId
    paginationData: $paginationData
    isActive: $isActive
    companyCurrency: $companyCurrency
  ) {
    id
    role {
      name
    }
    member {
      id
      first_name
      last_name
      avatar_url
      color
    }
  }
}
    `;

/**
 * __useScenarioMembersQuery__
 *
 * To run a query within a React component, call `useScenarioMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useScenarioMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScenarioMembersQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      scenarioId: // value for 'scenarioId'
 *      paginationData: // value for 'paginationData'
 *      isActive: // value for 'isActive'
 *      companyCurrency: // value for 'companyCurrency'
 *   },
 * });
 */
export function useScenarioMembersQuery(baseOptions: Apollo.QueryHookOptions<Types.ScenarioMembersQuery, Types.ScenarioMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ScenarioMembersQuery, Types.ScenarioMembersQueryVariables>(ScenarioMembersDocument, options);
      }
export function useScenarioMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ScenarioMembersQuery, Types.ScenarioMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ScenarioMembersQuery, Types.ScenarioMembersQueryVariables>(ScenarioMembersDocument, options);
        }
export type ScenarioMembersQueryHookResult = ReturnType<typeof useScenarioMembersQuery>;
export type ScenarioMembersLazyQueryHookResult = ReturnType<typeof useScenarioMembersLazyQuery>;
export type ScenarioMembersQueryResult = Apollo.QueryResult<Types.ScenarioMembersQuery, Types.ScenarioMembersQueryVariables>;
export const CreateLeaveTypeDocument = gql`
    mutation CreateLeaveType($companyId: ID!, $data: ILeaveType!) {
  createLeaveType(companyId: $companyId, data: $data) {
    ...LeaveTypeData
  }
}
    ${LeaveTypeDataFragmentDoc}`;
export type CreateLeaveTypeMutationFn = Apollo.MutationFunction<Types.CreateLeaveTypeMutation, Types.CreateLeaveTypeMutationVariables>;

/**
 * __useCreateLeaveTypeMutation__
 *
 * To run a mutation, you first call `useCreateLeaveTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLeaveTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLeaveTypeMutation, { data, loading, error }] = useCreateLeaveTypeMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateLeaveTypeMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateLeaveTypeMutation, Types.CreateLeaveTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateLeaveTypeMutation, Types.CreateLeaveTypeMutationVariables>(CreateLeaveTypeDocument, options);
      }
export type CreateLeaveTypeMutationHookResult = ReturnType<typeof useCreateLeaveTypeMutation>;
export type CreateLeaveTypeMutationResult = Apollo.MutationResult<Types.CreateLeaveTypeMutation>;
export type CreateLeaveTypeMutationOptions = Apollo.BaseMutationOptions<Types.CreateLeaveTypeMutation, Types.CreateLeaveTypeMutationVariables>;
export const DeleteLeaveTypeDocument = gql`
    mutation DeleteLeaveType($companyId: ID!, $leaveTypeId: ID!) {
  deleteLeaveType(companyId: $companyId, leaveTypeId: $leaveTypeId) {
    id
  }
}
    `;
export type DeleteLeaveTypeMutationFn = Apollo.MutationFunction<Types.DeleteLeaveTypeMutation, Types.DeleteLeaveTypeMutationVariables>;

/**
 * __useDeleteLeaveTypeMutation__
 *
 * To run a mutation, you first call `useDeleteLeaveTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLeaveTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLeaveTypeMutation, { data, loading, error }] = useDeleteLeaveTypeMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      leaveTypeId: // value for 'leaveTypeId'
 *   },
 * });
 */
export function useDeleteLeaveTypeMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteLeaveTypeMutation, Types.DeleteLeaveTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteLeaveTypeMutation, Types.DeleteLeaveTypeMutationVariables>(DeleteLeaveTypeDocument, options);
      }
export type DeleteLeaveTypeMutationHookResult = ReturnType<typeof useDeleteLeaveTypeMutation>;
export type DeleteLeaveTypeMutationResult = Apollo.MutationResult<Types.DeleteLeaveTypeMutation>;
export type DeleteLeaveTypeMutationOptions = Apollo.BaseMutationOptions<Types.DeleteLeaveTypeMutation, Types.DeleteLeaveTypeMutationVariables>;
export const EditLeaveTypeDocument = gql`
    mutation EditLeaveType($companyId: ID!, $data: ILeaveType!, $leaveTypeId: ID!) {
  editLeaveType(companyId: $companyId, data: $data, leaveTypeId: $leaveTypeId) {
    ...LeaveTypeData
  }
}
    ${LeaveTypeDataFragmentDoc}`;
export type EditLeaveTypeMutationFn = Apollo.MutationFunction<Types.EditLeaveTypeMutation, Types.EditLeaveTypeMutationVariables>;

/**
 * __useEditLeaveTypeMutation__
 *
 * To run a mutation, you first call `useEditLeaveTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditLeaveTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editLeaveTypeMutation, { data, loading, error }] = useEditLeaveTypeMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *      leaveTypeId: // value for 'leaveTypeId'
 *   },
 * });
 */
export function useEditLeaveTypeMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditLeaveTypeMutation, Types.EditLeaveTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditLeaveTypeMutation, Types.EditLeaveTypeMutationVariables>(EditLeaveTypeDocument, options);
      }
export type EditLeaveTypeMutationHookResult = ReturnType<typeof useEditLeaveTypeMutation>;
export type EditLeaveTypeMutationResult = Apollo.MutationResult<Types.EditLeaveTypeMutation>;
export type EditLeaveTypeMutationOptions = Apollo.BaseMutationOptions<Types.EditLeaveTypeMutation, Types.EditLeaveTypeMutationVariables>;
export const CreateSkillDocument = gql`
    mutation CreateSkill($companyId: ID!, $data: ISkill!) {
  createSkill(companyId: $companyId, data: $data) {
    ...SkillData
  }
}
    ${SkillDataFragmentDoc}`;
export type CreateSkillMutationFn = Apollo.MutationFunction<Types.CreateSkillMutation, Types.CreateSkillMutationVariables>;

/**
 * __useCreateSkillMutation__
 *
 * To run a mutation, you first call `useCreateSkillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSkillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSkillMutation, { data, loading, error }] = useCreateSkillMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSkillMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateSkillMutation, Types.CreateSkillMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateSkillMutation, Types.CreateSkillMutationVariables>(CreateSkillDocument, options);
      }
export type CreateSkillMutationHookResult = ReturnType<typeof useCreateSkillMutation>;
export type CreateSkillMutationResult = Apollo.MutationResult<Types.CreateSkillMutation>;
export type CreateSkillMutationOptions = Apollo.BaseMutationOptions<Types.CreateSkillMutation, Types.CreateSkillMutationVariables>;
export const DeleteSkillDocument = gql`
    mutation DeleteSkill($companyId: ID!, $skillId: ID!) {
  deleteSkill(companyId: $companyId, skillId: $skillId) {
    id
  }
}
    `;
export type DeleteSkillMutationFn = Apollo.MutationFunction<Types.DeleteSkillMutation, Types.DeleteSkillMutationVariables>;

/**
 * __useDeleteSkillMutation__
 *
 * To run a mutation, you first call `useDeleteSkillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSkillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSkillMutation, { data, loading, error }] = useDeleteSkillMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      skillId: // value for 'skillId'
 *   },
 * });
 */
export function useDeleteSkillMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteSkillMutation, Types.DeleteSkillMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteSkillMutation, Types.DeleteSkillMutationVariables>(DeleteSkillDocument, options);
      }
export type DeleteSkillMutationHookResult = ReturnType<typeof useDeleteSkillMutation>;
export type DeleteSkillMutationResult = Apollo.MutationResult<Types.DeleteSkillMutation>;
export type DeleteSkillMutationOptions = Apollo.BaseMutationOptions<Types.DeleteSkillMutation, Types.DeleteSkillMutationVariables>;
export const EditSkillDocument = gql`
    mutation EditSkill($companyId: ID!, $skillId: ID!, $data: ISkill!) {
  editSkill(companyId: $companyId, skillId: $skillId, data: $data) {
    ...SkillData
  }
}
    ${SkillDataFragmentDoc}`;
export type EditSkillMutationFn = Apollo.MutationFunction<Types.EditSkillMutation, Types.EditSkillMutationVariables>;

/**
 * __useEditSkillMutation__
 *
 * To run a mutation, you first call `useEditSkillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSkillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSkillMutation, { data, loading, error }] = useEditSkillMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      skillId: // value for 'skillId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditSkillMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditSkillMutation, Types.EditSkillMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditSkillMutation, Types.EditSkillMutationVariables>(EditSkillDocument, options);
      }
export type EditSkillMutationHookResult = ReturnType<typeof useEditSkillMutation>;
export type EditSkillMutationResult = Apollo.MutationResult<Types.EditSkillMutation>;
export type EditSkillMutationOptions = Apollo.BaseMutationOptions<Types.EditSkillMutation, Types.EditSkillMutationVariables>;
export const MemberCostRatesDocument = gql`
    query MemberCostRates($companyId: ID!, $memberId: ID!) {
  member(companyId: $companyId, memberId: $memberId) {
    id
    reportingToId
    costRateManagersIds
    color
    company {
      id
      primaryCurrencyId
    }
    cost_rate {
      ...CostRateData
    }
  }
}
    ${CostRateDataFragmentDoc}`;

/**
 * __useMemberCostRatesQuery__
 *
 * To run a query within a React component, call `useMemberCostRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberCostRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberCostRatesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useMemberCostRatesQuery(baseOptions: Apollo.QueryHookOptions<Types.MemberCostRatesQuery, Types.MemberCostRatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MemberCostRatesQuery, Types.MemberCostRatesQueryVariables>(MemberCostRatesDocument, options);
      }
export function useMemberCostRatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MemberCostRatesQuery, Types.MemberCostRatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MemberCostRatesQuery, Types.MemberCostRatesQueryVariables>(MemberCostRatesDocument, options);
        }
export type MemberCostRatesQueryHookResult = ReturnType<typeof useMemberCostRatesQuery>;
export type MemberCostRatesLazyQueryHookResult = ReturnType<typeof useMemberCostRatesLazyQuery>;
export type MemberCostRatesQueryResult = Apollo.QueryResult<Types.MemberCostRatesQuery, Types.MemberCostRatesQueryVariables>;
export const CreateCostRateDocument = gql`
    mutation CreateCostRate($companyId: ID!, $memberId: ID!, $data: ICostRate!) {
  createCostRate(companyId: $companyId, memberId: $memberId, data: $data) {
    ...CostRateData
    member_id
  }
}
    ${CostRateDataFragmentDoc}`;
export type CreateCostRateMutationFn = Apollo.MutationFunction<Types.CreateCostRateMutation, Types.CreateCostRateMutationVariables>;

/**
 * __useCreateCostRateMutation__
 *
 * To run a mutation, you first call `useCreateCostRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCostRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCostRateMutation, { data, loading, error }] = useCreateCostRateMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      memberId: // value for 'memberId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCostRateMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateCostRateMutation, Types.CreateCostRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateCostRateMutation, Types.CreateCostRateMutationVariables>(CreateCostRateDocument, options);
      }
export type CreateCostRateMutationHookResult = ReturnType<typeof useCreateCostRateMutation>;
export type CreateCostRateMutationResult = Apollo.MutationResult<Types.CreateCostRateMutation>;
export type CreateCostRateMutationOptions = Apollo.BaseMutationOptions<Types.CreateCostRateMutation, Types.CreateCostRateMutationVariables>;
export const EditCostRateDocument = gql`
    mutation EditCostRate($companyId: ID!, $costRateId: ID!, $data: ICostRate!) {
  editCostRate(companyId: $companyId, costRateId: $costRateId, data: $data) {
    ...CostRateData
  }
}
    ${CostRateDataFragmentDoc}`;
export type EditCostRateMutationFn = Apollo.MutationFunction<Types.EditCostRateMutation, Types.EditCostRateMutationVariables>;

/**
 * __useEditCostRateMutation__
 *
 * To run a mutation, you first call `useEditCostRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCostRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCostRateMutation, { data, loading, error }] = useEditCostRateMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      costRateId: // value for 'costRateId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditCostRateMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditCostRateMutation, Types.EditCostRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditCostRateMutation, Types.EditCostRateMutationVariables>(EditCostRateDocument, options);
      }
export type EditCostRateMutationHookResult = ReturnType<typeof useEditCostRateMutation>;
export type EditCostRateMutationResult = Apollo.MutationResult<Types.EditCostRateMutation>;
export type EditCostRateMutationOptions = Apollo.BaseMutationOptions<Types.EditCostRateMutation, Types.EditCostRateMutationVariables>;
export const MemberByIdDocument = gql`
    query MemberById($companyId: ID!, $memberId: ID!, $withAssignments: Boolean = true, $withOvertimeMultiplier: Boolean = true) {
  member(companyId: $companyId, memberId: $memberId) {
    id
    first_name
    last_name
    email
    employment_type
    job_title
    color
    status
    memberSpecialization
    memberSpecializationId
    member_role {
      id
      name
    }
    job_title
    vendor {
      id
      name
    }
    member_seniority {
      id
      name
    }
    cost_rate {
      id
      start_date
      end_date
    }
    costRateManagers {
      id
      first_name
      last_name
    }
    costRateManagersIds
    reportingToId
    reportingTo {
      id
      first_name
      last_name
      color
    }
    assignments @include(if: $withAssignments) {
      ...MemberProjectAssignmentData
    }
    leaves {
      id
      startDate
      endDate
      leaveType {
        name
      }
    }
    capacity
    join_date
    exit_date
    skills {
      id
      level
      skill {
        id
        skillCategoryName
        skillCategoryId
        name
      }
    }
  }
}
    ${MemberProjectAssignmentDataFragmentDoc}`;

/**
 * __useMemberByIdQuery__
 *
 * To run a query within a React component, call `useMemberByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberByIdQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      memberId: // value for 'memberId'
 *      withAssignments: // value for 'withAssignments'
 *      withOvertimeMultiplier: // value for 'withOvertimeMultiplier'
 *   },
 * });
 */
export function useMemberByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.MemberByIdQuery, Types.MemberByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MemberByIdQuery, Types.MemberByIdQueryVariables>(MemberByIdDocument, options);
      }
export function useMemberByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MemberByIdQuery, Types.MemberByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MemberByIdQuery, Types.MemberByIdQueryVariables>(MemberByIdDocument, options);
        }
export type MemberByIdQueryHookResult = ReturnType<typeof useMemberByIdQuery>;
export type MemberByIdLazyQueryHookResult = ReturnType<typeof useMemberByIdLazyQuery>;
export type MemberByIdQueryResult = Apollo.QueryResult<Types.MemberByIdQuery, Types.MemberByIdQueryVariables>;
export const MemberEmployeeByIdDocument = gql`
    query MemberEmployeeById($companyId: ID!, $memberId: ID!, $withAssignments: Boolean = true, $withOvertimeMultiplier: Boolean = true) {
  member(companyId: $companyId, memberId: $memberId) {
    id
    first_name
    last_name
    email
    employment_type
    color
    member_role {
      id
      name
    }
    reportingTo {
      id
      first_name
      last_name
      color
    }
    assignments @include(if: $withAssignments) {
      ...MemberProjectAssignmentData
    }
    leaves {
      id
      startDate
      endDate
      leaveType {
        name
      }
    }
  }
}
    ${MemberProjectAssignmentDataFragmentDoc}`;

/**
 * __useMemberEmployeeByIdQuery__
 *
 * To run a query within a React component, call `useMemberEmployeeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberEmployeeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberEmployeeByIdQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      memberId: // value for 'memberId'
 *      withAssignments: // value for 'withAssignments'
 *      withOvertimeMultiplier: // value for 'withOvertimeMultiplier'
 *   },
 * });
 */
export function useMemberEmployeeByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.MemberEmployeeByIdQuery, Types.MemberEmployeeByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MemberEmployeeByIdQuery, Types.MemberEmployeeByIdQueryVariables>(MemberEmployeeByIdDocument, options);
      }
export function useMemberEmployeeByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MemberEmployeeByIdQuery, Types.MemberEmployeeByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MemberEmployeeByIdQuery, Types.MemberEmployeeByIdQueryVariables>(MemberEmployeeByIdDocument, options);
        }
export type MemberEmployeeByIdQueryHookResult = ReturnType<typeof useMemberEmployeeByIdQuery>;
export type MemberEmployeeByIdLazyQueryHookResult = ReturnType<typeof useMemberEmployeeByIdLazyQuery>;
export type MemberEmployeeByIdQueryResult = Apollo.QueryResult<Types.MemberEmployeeByIdQuery, Types.MemberEmployeeByIdQueryVariables>;
export const DeleteMemberSkillDocument = gql`
    mutation DeleteMemberSkill($companyId: ID!, $memberId: ID!, $skillId: ID!) {
  deleteMemberSkill(companyId: $companyId, memberId: $memberId, skillId: $skillId) {
    id
  }
}
    `;
export type DeleteMemberSkillMutationFn = Apollo.MutationFunction<Types.DeleteMemberSkillMutation, Types.DeleteMemberSkillMutationVariables>;

/**
 * __useDeleteMemberSkillMutation__
 *
 * To run a mutation, you first call `useDeleteMemberSkillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMemberSkillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMemberSkillMutation, { data, loading, error }] = useDeleteMemberSkillMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      memberId: // value for 'memberId'
 *      skillId: // value for 'skillId'
 *   },
 * });
 */
export function useDeleteMemberSkillMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteMemberSkillMutation, Types.DeleteMemberSkillMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteMemberSkillMutation, Types.DeleteMemberSkillMutationVariables>(DeleteMemberSkillDocument, options);
      }
export type DeleteMemberSkillMutationHookResult = ReturnType<typeof useDeleteMemberSkillMutation>;
export type DeleteMemberSkillMutationResult = Apollo.MutationResult<Types.DeleteMemberSkillMutation>;
export type DeleteMemberSkillMutationOptions = Apollo.BaseMutationOptions<Types.DeleteMemberSkillMutation, Types.DeleteMemberSkillMutationVariables>;
export const EditMemberSkillDocument = gql`
    mutation EditMemberSkill($companyId: ID!, $memberSkillId: ID!, $data: IEditMemberSkill!) {
  editMemberSkill(
    companyId: $companyId
    memberSkillId: $memberSkillId
    data: $data
  ) {
    level
    id
  }
}
    `;
export type EditMemberSkillMutationFn = Apollo.MutationFunction<Types.EditMemberSkillMutation, Types.EditMemberSkillMutationVariables>;

/**
 * __useEditMemberSkillMutation__
 *
 * To run a mutation, you first call `useEditMemberSkillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMemberSkillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMemberSkillMutation, { data, loading, error }] = useEditMemberSkillMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      memberSkillId: // value for 'memberSkillId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditMemberSkillMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditMemberSkillMutation, Types.EditMemberSkillMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditMemberSkillMutation, Types.EditMemberSkillMutationVariables>(EditMemberSkillDocument, options);
      }
export type EditMemberSkillMutationHookResult = ReturnType<typeof useEditMemberSkillMutation>;
export type EditMemberSkillMutationResult = Apollo.MutationResult<Types.EditMemberSkillMutation>;
export type EditMemberSkillMutationOptions = Apollo.BaseMutationOptions<Types.EditMemberSkillMutation, Types.EditMemberSkillMutationVariables>;
export const EditMemberSkillsDocument = gql`
    mutation EditMemberSkills($companyId: ID!, $data: IMemberSkill!) {
  editMemberSkills(companyId: $companyId, data: $data) {
    id
  }
}
    `;
export type EditMemberSkillsMutationFn = Apollo.MutationFunction<Types.EditMemberSkillsMutation, Types.EditMemberSkillsMutationVariables>;

/**
 * __useEditMemberSkillsMutation__
 *
 * To run a mutation, you first call `useEditMemberSkillsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMemberSkillsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMemberSkillsMutation, { data, loading, error }] = useEditMemberSkillsMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditMemberSkillsMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditMemberSkillsMutation, Types.EditMemberSkillsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditMemberSkillsMutation, Types.EditMemberSkillsMutationVariables>(EditMemberSkillsDocument, options);
      }
export type EditMemberSkillsMutationHookResult = ReturnType<typeof useEditMemberSkillsMutation>;
export type EditMemberSkillsMutationResult = Apollo.MutationResult<Types.EditMemberSkillsMutation>;
export type EditMemberSkillsMutationOptions = Apollo.BaseMutationOptions<Types.EditMemberSkillsMutation, Types.EditMemberSkillsMutationVariables>;