import React, { FC, ReactNode } from 'react';

import styles from './styles.module.scss';

interface Props {
  label: string;
  additionalLabel?: string;
  color: string;
  children: ReactNode;
}

export const ChartTooltip: FC<Props> = ({ color, children, label, additionalLabel }) => {
  return (
    <div className={styles.container}>
      <div className={styles.colorBox} style={{ backgroundColor: color }} />
      <div className={styles.contentBox}>
        <div className={styles.titleBox}>
          <span className={styles.title}>{label}</span>
          {additionalLabel && <span className={styles.additionalTitle}>{additionalLabel}</span>}
        </div>
        <span className={styles.value}>{children}</span>
      </div>
    </div>
  );
};
