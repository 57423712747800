import React, { memo } from 'react';
import clsx from 'clsx';
import { Tooltip } from 'components';

import { Avatar, AvatarProps } from '../Avatar';

interface UserInfoProps extends AvatarProps {
  title?: string | JSX.Element;
  subTitle?: string | JSX.Element;
  className?: string;
  avatarClassName?: string;
  titleClassName?: string;
  subTitleClassName?: string;
  additionalInfoClassName?: string;
  alwaysShowTitleTooltip?: boolean;
  color?: string;
}

export const UserInfo = memo(
  ({
    title,
    color,
    subTitle,
    className,
    titleClassName,
    avatarClassName,
    subTitleClassName,
    additionalInfoClassName,
    alwaysShowTitleTooltip,
    ...avatarProps
  }: UserInfoProps) => {
    return (
      <div className={clsx(['flex', 'align-items-center', className])}>
        <Avatar {...avatarProps} className={avatarClassName} color={color} />
        <div className={clsx('flex flex-column ml-12', additionalInfoClassName)}>
          <Tooltip
            title={title || ''}
            placement="top"
            textClassName={clsx('weight-600 text-14 overflowed-text', titleClassName)}
            alwaysShowTooltip={alwaysShowTitleTooltip}
          >
            {title}
          </Tooltip>
          <div className={clsx('text-12 weight-400 text-typo-secondary', subTitleClassName)}>{subTitle}</div>
        </div>
      </div>
    );
  },
);

UserInfo.displayName = 'UserInfo';
