import React from 'react';
import clsx from 'clsx';
import { toShortFormat } from 'utils';
import { differenceInCalendarDays } from 'date-fns';
import { Tooltip } from 'components';

import styles from './styles.module.scss';

export interface EndDateReminderProps {
  date: Date;
  archived?: boolean;
  hideTag?: boolean;
  textClassName?: string;
}

const formatDaysDifference = (days: number) => {
  if (days >= 0) {
    if (days === 7) {
      return 'in 1w';
    }
    return `in ${days}d`;
  } else {
    return `${Math.abs(days)}d overdue`;
  }
};

export const EndDateReminder = ({ date, archived = false, hideTag = false, textClassName }: EndDateReminderProps) => {
  const daysDifference = differenceInCalendarDays(new Date(date), new Date());
  const isOverdue = daysDifference <= 7;

  return (
    <Tooltip
      title={formatDaysDifference(daysDifference)}
      alwaysShowTooltip={isOverdue && !archived && hideTag}
      placement="bottom"
      className={styles.endDateReminder}
    >
      <div className={clsx([styles.endDateReminder, 'flex', 'align-items-center'])}>
        <span className={clsx(styles.endDateReminderColor, !isOverdue && styles.active)} />
        <div className={clsx(styles.endDateReminderTitle, textClassName)}>{toShortFormat(new Date(date))}</div>
        {isOverdue && !archived && !hideTag && (
          <div className={styles.endDateReminderBadge}>{formatDaysDifference(daysDifference)}</div>
        )}
      </div>
    </Tooltip>
  );
};
