export const TableColumnsWidth = {
  shortDate: {
    minWidth: 132,
    maxWidth: 140,
  },
  longDate: {
    minWidth: 180,
    maxWidth: 180,
  },
  percentage: {
    minWidth: 132,
    maxWidth: 140,
  },
  hours: {
    minWidth: 132,
    maxWidth: 140,
  },
  cost: {
    minWidth: 140,
    maxWidth: 160,
  },
  period: {
    minWidth: 160,
    maxWidth: 160,
  },
  countButton: {
    minWidth: 132,
    maxWidth: 132,
  },
  text: {
    minWidth: 132,
    maxWidth: 200,
  },
};
