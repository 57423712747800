interface Role {
  name: string;
}

const RoleLineWidth = 284;
const DefaultCharWidth = 10;
const GapWidth = 8;
const PaddingsWidth = 16;

export const getShownProjectRoles = (roles: Role[]) => {
  return roles.reduce<{
    lineSpaceLeft: number;
    shownRoles: string[];
    hiddenRoles: string[];
  }>(
    (acc, { name }) => {
      const roleWidth = DefaultCharWidth * name.length + GapWidth + PaddingsWidth;
      const lineSpaceLeft = acc.lineSpaceLeft - roleWidth;

      if (lineSpaceLeft >= 0) {
        return {
          lineSpaceLeft,
          shownRoles: [...acc.shownRoles, name],
          hiddenRoles: [...acc.hiddenRoles],
        };
      }

      return {
        lineSpaceLeft: acc.lineSpaceLeft,
        shownRoles: [...acc.shownRoles],
        hiddenRoles: [...acc.hiddenRoles, name],
      };
    },
    {
      lineSpaceLeft: RoleLineWidth,
      shownRoles: [],
      hiddenRoles: [],
    },
  );
};
