import { ExternalRateDataFragment } from 'generated/types';
import { FormValues } from '../components/RateForm';
import { currencyToValue } from 'utils';

export const isOnlySeniorityEdited = (values: FormValues, rate: ExternalRateDataFragment) => {
  const { start_date, end_date, unit, seniority, amount } = values;

  return (
    start_date === rate.start_date &&
    end_date === rate.end_date &&
    unit.id === rate.unit &&
    Math.round(currencyToValue(amount || 0)) === rate.rate_amount &&
    seniority?.id !== rate.seniorityId
  );
};
