import React, { FC, ReactNode } from 'react';
import styles from './styles.module.scss';
import clsx from 'clsx';

interface Props {
  children: ReactNode;
  labelList: ReactNode;
  chartContainerClassName?: string;
}

export const ChartContainer: FC<Props> = ({ children, labelList, chartContainerClassName }) => {
  return (
    <div className={styles.chart}>
      <div className={clsx(styles.chartContainer, chartContainerClassName)}>{children}</div>
      <div className={styles.chartSidebar}>{labelList}</div>
    </div>
  );
};
