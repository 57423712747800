import { Route } from 'react-router-hoc';
import { idRegEx } from 'utils/regex';
import { lazy } from 'react';
import { ModalModeEnum } from 'types';

export const MilestonesRoute = Route(
  {
    id: Route.params.regex(idRegEx).optional,
    milestoneId: Route.params.regex(idRegEx).optional,
    mode: Route.params.oneOf(...Object.values(ModalModeEnum)).optional,
  },
  ({ id, milestoneId, mode }) => `/project/view/${id}/milestones/${milestoneId}/${mode}`,
);

export const Milestones = MilestonesRoute(lazy(() => import(/* webpackChunkName: "Milestones" */ './Milestones')));
