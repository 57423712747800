import React, { ChangeEvent, KeyboardEvent } from 'react';
import clsx from 'clsx';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { DeleteIcon, SearchIcon } from 'icons';

import styles from './styles.module.scss';

interface SearchFieldProps {
  value?: string;
  onChange: (value?: string) => void;
  onBlur?: () => void;
  onReset?: () => void;
  className?: string;
  fixed?: boolean;
  placeholder?: string;
  focusedSearchWidth?: number;
}

interface StyleProps {
  focusedSearchWidth?: number;
}

const useStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      width: 108,
      height: '36px',
      transition: 'all 0.2s ease-out',
      '& .MuiInputBase-input': {
        fontSize: '14px',
        '&::placeholder': { color: '#85929A' },
      },
      '& fieldset': {
        borderColor: '#ffffff',
      },
      '&:hover ': {
        backgroundColor: '#ebedee',
        '& fieldset': {
          borderColor: '#ebedee',
        },
      },
      '&.Mui-focused': {
        width: ({ focusedSearchWidth }: StyleProps) => focusedSearchWidth || 390,
        transition: 'all 0.2s ease-out',
        backgroundColor: '#ffffff',
        '&:hover': {
          borderColor: '#050709',
          backgroundColor: '#ffffff',

          '& fieldset': {
            borderColor: '#050709',
          },
        },
        '& fieldset': {
          borderColor: '#050709',
        },
      },
    },
  },
});

const useFixedStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      width: '100%',
      height: '100%',
      borderRadius: 0,
      '& .MuiInputBase-input': {
        fontSize: '14px',
        color: '#050709',
        '&::placeholder': { color: '#85929A' },
      },
      '& fieldset': {
        borderColor: '#ffffff',
      },
      '&:hover ': {
        '& fieldset': {
          borderWidth: 0,
        },
      },
      '&.Mui-focused': {
        backgroundColor: '#ffffff',
        '&:hover': {
          borderColor: 'transparent',
          backgroundColor: '#ffffff',

          '& fieldset': {
            borderColor: 'transparent',
          },
        },
        '& fieldset': {
          borderColor: 'transparent',
        },
      },
    },
  },
});

export const ResizableSearch = ({
  value = '',
  onChange,
  onReset,
  onBlur,
  className,
  placeholder,
  fixed = false,
  focusedSearchWidth,
}: SearchFieldProps) => {
  const { t } = useTranslation();
  const classes = fixed ? useFixedStyles() : useStyles({ focusedSearchWidth });
  const handleSearchFieldChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => onChange(value);

  const handleEnterPress = ({ target, key }: KeyboardEvent) => key === 'Enter' && (target as HTMLInputElement).blur();

  const resetSearch = () => {
    onChange(undefined);
    onReset?.();
  };

  return (
    <TextField
      onChange={handleSearchFieldChange}
      onBlur={onBlur}
      value={value}
      fullWidth={false}
      placeholder={placeholder ?? t('forms.search')}
      className={clsx(classes.root, className)}
      onKeyDown={handleEnterPress}
      id="resizable-search"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon className={styles.inputIcon} />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment
            position="start"
            className={clsx(styles.clearIcon, value && styles.visible)}
            onClick={resetSearch}
          >
            <DeleteIcon className={styles.inputIcon} />
          </InputAdornment>
        ),
      }}
    />
  );
};
