import { useQueryParams } from 'utils/useQueryParams';
import { RESOURCE_PLANNING_TEAM_FILTERS } from 'consts';
import { useHistory, useRouteMatch } from 'react-router';
import { localStorageManager } from 'services';
import { Filters } from 'views/ResourcePlanning/types';
import { links } from 'App';

export const useResourcePlanningFilters = () => {
  const query = useQueryParams();
  const { params } = useRouteMatch<{ type?: 'project' }>();
  const { push } = useHistory();
  const filtersStorageData = (localStorageManager.getItem(RESOURCE_PLANNING_TEAM_FILTERS) as unknown) as Filters;

  const onChangeFilters = (data: Partial<Filters>) => {
    localStorageManager.setItem(RESOURCE_PLANNING_TEAM_FILTERS, {
      ...filtersStorageData,
      ...query,
      ...params,
      ...data,
    });
    push(
      links.ResourcePlanning({
        ...params,
        ...filtersStorageData,
        ...query,
        search: typeof query.search === 'string' ? query.search : undefined,
        ...data,
      }),
    );
  };

  const cachedData = {
    membersTypes: undefined,
    membersSeniority: undefined,
    membersSpecialization: undefined,
    membersSkills: undefined,
    ...(filtersStorageData || {}),
  };
  const filters = { ...cachedData, ...query };

  return {
    filters: {
      membersTypes:
        filters.membersTypes instanceof Array || !filters.membersTypes ? filters.membersTypes : [filters.membersTypes],
      membersSeniority:
        filters.membersSeniority instanceof Array || !filters.membersSeniority
          ? filters.membersSeniority
          : [filters.membersSeniority],
      membersSpecialization:
        filters.membersSpecialization instanceof Array || !filters.membersSpecialization
          ? filters.membersSpecialization
          : [filters.membersSpecialization],
      membersSkills:
        filters.membersSkills instanceof Array || !filters.membersSkills
          ? filters.membersSkills
          : [filters.membersSkills],
    },
    onChangeFilters,
  };
};
