import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectMilestoneDataFragment } from 'generated/types';
import { graphqlOnError, updateProjectTotalByMilestones } from 'utils';
import {
  ProjectAssignmentsListDocument,
  ProjectMilestonesDocument,
  ProjectMilestonesSectionDocument,
  useDeleteProjectMilestoneMutation,
  useEditProjectMilestoneMutation,
} from 'generated/graphql';
import { toast } from 'react-toastify';
import { useErrorMsgBuilder } from 'hooks';
import { useAuth } from 'contexts';
import { ViewMilestone } from '../ViewMilestone';
import { FormValues, MilestoneForm } from '../MilestoneForm';
import { format } from 'date-fns';
import { DEFAULT_DATE_FORMAT } from 'consts';

interface Props {
  projectMilestone: ProjectMilestoneDataFragment;
  onToggleEditing?: (id: string) => void;
}

export const MilestoneItem: FC<Props> = ({ projectMilestone, onToggleEditing }) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();
  const [isEdit, setIsEdit] = useState(false);
  const { id, amount, notes, paymentDate, title, projectId } = projectMilestone;

  const [deleteProjectMilestone] = useDeleteProjectMilestoneMutation({
    onCompleted() {
      toast.success(t('viewProjectDetail.milestones.deletedSuccessfully'));
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    update(cache, { data }) {
      if (data) {
        cache.evict({ id: cache.identify(data.deleteProjectMilestone) });
        cache.gc();
        updateProjectTotalByMilestones({ projectId, companyId: userData!.company.id, cache });
      }
    },
    variables: { companyId: userData!.company.id, projectMilestoneId: id },
    refetchQueries: [
      ProjectAssignmentsListDocument,
      {
        query: ProjectMilestonesSectionDocument,
        variables: {
          companyId: userData!.company.id,
          companyCurrency: true,
          projectId: projectId,
        },
      },
      {
        query: ProjectMilestonesSectionDocument,
        variables: {
          companyId: userData!.company.id,
          companyCurrency: false,
          projectId: projectId,
        },
      },
    ],
  });

  const [editProjectMilestone, { loading }] = useEditProjectMilestoneMutation({
    onCompleted() {
      toast.success(t('viewProjectDetail.milestones.editSuccessfully'));
      setIsEdit(false);
      onToggleEditing?.(id);
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    update(cache, { data }) {
      if (data) {
        updateProjectTotalByMilestones({ projectId, companyId: userData!.company.id, cache });
      }
    },
    refetchQueries: [
      {
        query: ProjectMilestonesDocument,
        variables: {
          companyId: userData!.company.id,
          projectId: projectId,
        },
      },
      {
        query: ProjectMilestonesSectionDocument,
        variables: {
          companyId: userData!.company.id,
          companyCurrency: true,
          projectId: projectId,
        },
      },
      {
        query: ProjectMilestonesSectionDocument,
        variables: {
          companyId: userData!.company.id,
          companyCurrency: false,
          projectId: projectId,
        },
      },
      ProjectAssignmentsListDocument,
    ],
  });

  const handleEdit = useCallback(async (values: FormValues) => {
    await editProjectMilestone({
      variables: {
        data: {
          title: values?.title || '',
          paymentDate: format(new Date(values?.paymentDate || ''), DEFAULT_DATE_FORMAT),
          notes: values?.notes,
          amount: +(values?.amount || 0),
        },
        companyId: userData!.company.id,
        projectMilestoneId: id,
      },
    });
  }, []);

  return isEdit ? (
    <MilestoneForm
      onSubmit={handleEdit}
      onCancel={() => {
        setIsEdit(false);
        onToggleEditing?.(id);
      }}
      loading={loading}
      initialValue={{
        title,
        amount,
        paymentDate,
        notes: notes || '',
      }}
    />
  ) : (
    <ViewMilestone
      amount={amount}
      notes={notes}
      paymentDate={paymentDate}
      title={title}
      onDelete={() => deleteProjectMilestone()}
      onEdit={() => {
        setIsEdit(true);
        onToggleEditing?.(id);
      }}
    />
  );
};
