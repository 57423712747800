import React, { ReactNode } from 'react';

import styles from './styles.module.scss';

interface Props {
  children: ReactNode;
  color: string;
}

export const BadgeTag = ({ children, color }: Props) => (
  <div className={styles.badge}>
    <div className={styles.colorMarker} style={{ backgroundColor: color }} />
    {children}
  </div>
);
