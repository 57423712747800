import React, { ChangeEvent, memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router';

import mixpanel from 'mixpanel-browser';
import { ModuleName } from 'types';
import { links } from 'App';
import { FilterCheckboxPopover, FilterRadioPopover, FilterTagsPopover, Select, Switch } from 'components';
import { useAuth, useTimelineContext } from 'contexts';
import { useQueryParams } from 'utils/useQueryParams';
import { localStorageManager } from 'services';
import { GROUP_MEMBERS_BY, GROUP_PROJECTS_BY, MONTH_PERIOD, PER_DAY, PER_WEEK } from 'consts';
import { EmploymentType } from 'generated/types';
import { useSenioritiesQuery, useSkillCategoriesQuery, useSpecializationsQuery } from 'generated/graphql';
import { graphqlOnError } from 'utils';
import { useErrorMsgBuilder } from 'hooks';
import { Filters, ProjectsGroupingTypesFilter, TeamGroupingTypesFilter } from 'views/ResourcePlanning/types';
import { useResourcePlanningFilters } from 'views/ResourcePlanning/utils';

import clsx from 'clsx';
import styles from './styles.module.scss';

export const TimelineFilters = memo(() => {
  const { groupingType, showRequests } = useQueryParams<Filters>();
  const { filters, onChangeFilters } = useResourcePlanningFilters();
  const query = useQueryParams();
  const { t } = useTranslation();
  const { hoursPeriod, onChangeHoursPeriod, timelinePeriod } = useTimelineContext();
  const isMonthView = timelinePeriod === MONTH_PERIOD;
  const { params } = useRouteMatch<{ type?: 'project' }>();
  const { push } = useHistory();
  const [isShowRequest, setIsShowRequest] = useState(showRequests ?? true);
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();

  const teamGroupingOptions = useMemo(
    () =>
      Object.values(TeamGroupingTypesFilter).map((filter) => ({
        id: filter,
        name: t(`resourcePlanning.timeline.filters.groupBy.${filter}`),
      })),
    [],
  );

  const projectsGroupingOptions = useMemo(
    () =>
      Object.values(ProjectsGroupingTypesFilter).map((filter) => ({
        id: filter,
        name: t(`resourcePlanning.timeline.filters.groupBy.${filter}`),
      })),
    [],
  );

  const memberTypeOptions = useMemo(
    () =>
      [EmploymentType.Employee, EmploymentType.Contractor].map((filter) => ({
        id: filter,
        name: t(`resourcePlanning.timeline.filters.members.${filter}`),
      })),
    [],
  );

  const { data: { seniorities = [] } = {} } = useSenioritiesQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: { companyId: userData!.company.id },
  });
  const { data: { specializations = [] } = {} } = useSpecializationsQuery({
    variables: { companyId: userData!.company.id },
    skip: !userData,
  });
  const { data: { skillCategories = [] } = {} } = useSkillCategoriesQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: { companyId: userData!.company.id, withSkills: true },
    skip: !userData,
  });
  const skills = useMemo(
    () => skillCategories.map(({ skills }) => (skills || []).map(({ id, name }) => ({ id, name }))).flat(),
    [skillCategories],
  );

  return (
    <>
      <div className={styles.filters}>
        <div id="hide-team-member-menu" />
        {params.type !== 'project' ? (
          <>
            <FilterRadioPopover
              title={t('resourcePlanning.groupBy')}
              showPrefix
              value={
                groupingType || localStorageManager.getItem(GROUP_MEMBERS_BY) || TeamGroupingTypesFilter.availability
              }
              noSelectOptionStyle
              onChange={(groupingType) => {
                mixpanel.track('Grouping updated', {
                  'Screen name': ModuleName.resourcePlanning,
                  Grouping: groupingType,
                });
                localStorageManager.setItem(GROUP_MEMBERS_BY, groupingType);
                push(
                  links.ResourcePlanning({
                    ...params,
                    ...filters,
                    ...query,
                    groupingType: groupingType as TeamGroupingTypesFilter,
                  }),
                );
              }}
              options={teamGroupingOptions}
            />
            <FilterCheckboxPopover
              title={t('resourcePlanning.timeline.filters.members.label')}
              value={filters.membersTypes}
              onChange={(membersTypes) => onChangeFilters({ membersTypes: membersTypes as EmploymentType[] })}
              options={memberTypeOptions}
            />
            <FilterCheckboxPopover
              title={t('resourcePlanning.timeline.filters.seniority')}
              value={filters.membersSeniority}
              onChange={(membersSeniority) => onChangeFilters({ membersSeniority })}
              options={seniorities}
            />
            <FilterCheckboxPopover
              title={t('resourcePlanning.timeline.filters.specialization')}
              value={filters.membersSpecialization}
              onChange={(membersSpecialization) => onChangeFilters({ membersSpecialization })}
              options={specializations}
            />
            <FilterTagsPopover
              title={t('resourcePlanning.timeline.filters.skills')}
              placeholder={t('resourcePlanning.timeline.filters.selectSkills')}
              value={filters.membersSkills}
              onChange={(membersSkills) => onChangeFilters({ membersSkills })}
              options={skills}
            />
          </>
        ) : (
          <FilterRadioPopover
            title={t('resourcePlanning.groupBy')}
            showPrefix
            value={
              groupingType ?? localStorageManager.getItem(GROUP_PROJECTS_BY) ?? ProjectsGroupingTypesFilter.projectType
            }
            noSelectOptionStyle
            onChange={(groupingType) => {
              mixpanel.track('Grouping updated', {
                'Screen name': ModuleName.resourcePlanning,
                Grouping: groupingType,
              });
              localStorageManager.setItem(GROUP_PROJECTS_BY, groupingType);
              push(
                links.ResourcePlanning({
                  ...params,
                  ...filters,
                  ...query,
                  groupingType: groupingType as ProjectsGroupingTypesFilter,
                }),
              );
            }}
            options={projectsGroupingOptions}
          />
        )}
      </div>

      <div className={clsx(styles.filters, 'justify-content-end')}>
        {params.type !== 'project' && (
          <>
            <span className={styles.hideRequest}>{t('resourcePlanning.timeline.filters.hideRequests')}</span>
            <Switch
              className="mr-4"
              checked={!isShowRequest}
              onChange={() => {
                setIsShowRequest(!isShowRequest);
                push(links.ResourcePlanning({ ...params, ...filters, ...query, showRequests: !isShowRequest }));
              }}
              color="primary"
              name="showRequests"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </>
        )}

        {isMonthView && (
          <Select
            value={hoursPeriod}
            InputProps={{ classes: { input: styles.select } }}
            onChange={(event: ChangeEvent<any>) => onChangeHoursPeriod(event.target.value)}
            defaultText={PER_WEEK}
            options={[
              { id: PER_WEEK, name: t('resourcePlanning.timeline.filters.hoursPeriod.perWeek') },
              { id: PER_DAY, name: t('resourcePlanning.timeline.filters.hoursPeriod.perDay') },
            ]}
          />
        )}
      </div>
    </>
  );
});

TimelineFilters.displayName = 'Timeline.Filters';
