import React, { createRef, memo, PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { getUnixTime } from 'date-fns';
import sortBy from 'lodash/sortBy';
import { Button } from '@material-ui/core';

import { links } from 'App';
import { PlusIcon, ResourcePlanningStateIcon, ResourcePlanningStateLoadingIcon } from 'icons';
import { usePermissions, useTrackScreenView } from 'hooks';
import { GROUP_PROJECTS_BY } from 'consts';
import { AbsoluteSpinner } from 'components';
import { localStorageManager } from 'services';
import { useAuth, useBaseLayoutContext, useTimelineContext } from 'contexts';
import clsx from 'clsx';

import { useQueryParams } from 'utils/useQueryParams';
import { getStartEndPeriodDates } from 'views/ResourcePlanning/utils/days';
import { useResourcePlanningProjectsQuery } from 'generated/graphql';
import { ActionsType, ResourcePlanningProjectDataFragment } from 'generated/types';

import { Project } from '../Sidebar';
import { SearchField } from './SearchField';
import { RowsAccordion } from './RowsAccordion';
import { Availability } from '../Availability';
import { ProjectAssignments } from '../Assignments';
import { Filters, ProjectsGroupingTypesFilter } from '../../types';
import { getMembersGroupedByRole, MemberAssignment, useScrollExpandedIntoView } from '../../utils';
import { ModalModeEnum, ModuleName, ScreenName } from 'types';

import styles from './styles.module.scss';

type Props = {
  date: Date;
  onChange?: (date: Date) => void;
};

type ProjectMemberProps = {
  date: Date;
  member?: MemberAssignment;
};

const ProjectMember = memo<ProjectMemberProps>(({ member, date }) => {
  const { params } = useRouteMatch();
  const { push } = useHistory();
  const { hasAccess } = usePermissions();

  return (
    <Availability
      onLeave={
        hasAccess(ActionsType.EditLeaves)
          ? (id) => push(links.ResourcePlanning({ ...params, date: getUnixTime(date), mode: ModalModeEnum.leave, id }))
          : undefined
      }
      key={member?.id}
      date={date}
      member={member}
    />
  );
});

ProjectMember.displayName = 'Timeline.Project.Member';

type RowProps = {
  date: Date;
  project: ResourcePlanningProjectDataFragment;
  shouldScrollAfterExpand?: boolean;
};

const Row = memo<RowProps>(({ date, project, shouldScrollAfterExpand }) => {
  const [expanded, setExpanded] = useState(false);
  const { params } = useRouteMatch();
  const { push } = useHistory();
  const queryParams = useQueryParams();
  const { hasAccess } = usePermissions();
  const { t } = useTranslation();
  const rowRef = createRef<HTMLDivElement>();
  useScrollExpandedIntoView({ ref: rowRef, expanded, shouldScrollAfterExpand });

  const members = useMemo(() => {
    return (
      project?.assignments?.map((assignment) =>
        Object.assign({}, assignment?.member, { assignments: [assignment] }, { role: assignment.role.name }),
      ) || []
    );
  }, [project.assignments]);

  const membersGroupedByRole = getMembersGroupedByRole(members);
  return (
    <section className={styles.timelineRow} key={project.id} ref={rowRef}>
      <Project
        expanded={expanded}
        onExpand={() => setExpanded(!expanded)}
        data={project}
        members={members}
        className={styles.timelineSidebar}
        tileClassName={styles.timelineSidebarTile}
        additionalExpandComponent={
          <>
            {hasAccess(ActionsType.CreateAssignments) && (
              <Button
                startIcon={<PlusIcon className={styles.timelineSidebarButtonIcon} />}
                variant="text"
                color="inherit"
                className={styles.timelineSidebarButton}
                onClick={() =>
                  push(
                    links.ResourcePlanning({
                      ...params,
                      ...queryParams,
                      projectId: project.id,
                      mode: ModalModeEnum.manage,
                    }),
                  )
                }
              >
                {t('resourcePlanning.addTeamMember')}...
              </Button>
            )}

            {!hasAccess(ActionsType.CreateAssignments) && hasAccess(ActionsType.SubmitAssignmentRequest) && (
              <Button
                startIcon={<PlusIcon className={styles.timelineSidebarButtonIcon} />}
                variant="text"
                color="inherit"
                className={styles.timelineSidebarButton}
                onClick={() =>
                  push(
                    links.ResourcePlanning({
                      ...params,
                      ...queryParams,
                      projectId: project.id,
                      mode: ModalModeEnum.request,
                    }),
                  )
                }
              >
                {t('resourcePlanning.addTeamMember')}...
              </Button>
            )}
          </>
        }
      />
      <section className={styles.timelineAvailability}>
        <ProjectAssignments key={project?.id} date={date} project={project} />
        {expanded &&
          Object.keys(membersGroupedByRole)
            .sort()
            .map((group) => {
              return (
                <div key={group}>
                  <div className={styles.blankTimelineRow} />
                  {membersGroupedByRole[group]?.map((member) => (
                    <ProjectMember date={date} member={member} key={member.id} />
                  ))}
                </div>
              );
            })}
      </section>
    </section>
  );
});

Row.displayName = 'Timeline.Project.Row';

type GroupedProject = {
  [name: string]: ResourcePlanningProjectDataFragment[];
};

export const ProjectTimeline = memo<PropsWithChildren<Props>>(({ date }) => {
  const { userData } = useAuth();
  const { timelinePeriod } = useTimelineContext();
  const { t } = useTranslation();
  const {
    search,
    groupingType = localStorageManager.getItem(GROUP_PROJECTS_BY) ?? ProjectsGroupingTypesFilter.projectType,
  } = useQueryParams<Filters>();
  const { hasAccess } = usePermissions();
  const { isNavBarExpanded } = useBaseLayoutContext();
  useTrackScreenView(ModuleName.resourcePlanning, ScreenName.projects);

  const [startPeriodDate, endPeriodDate] = getStartEndPeriodDates(date, timelinePeriod);

  const cachedProjects = useRef<ResourcePlanningProjectDataFragment[]>();

  const { data, loading } = useResourcePlanningProjectsQuery({
    variables: {
      companyId: userData?.company.id ?? '',
      paginationAssignmentData: {
        start: startPeriodDate.toISOString(),
        end: endPeriodDate.toISOString(),
      },
      withLeaves: hasAccess(ActionsType.ViewLeaves),
      searchValue: search,
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (data?.resourcePlanningProjects) {
      cachedProjects.current = data?.resourcePlanningProjects;
    }
  }, [data?.resourcePlanningProjects]);

  const sortedProjects = useMemo(() => {
    const projects = data?.resourcePlanningProjects || cachedProjects.current;
    return sortBy(projects, 'name');
  }, [data?.resourcePlanningProjects]);

  const projects = useMemo(() => {
    return sortedProjects.filter(({ archived_at }) => !archived_at);
  }, [sortedProjects]);

  const projectRows = useMemo(() => {
    const groupedProjects = projects.reduce<GroupedProject>((acc, project) => {
      if (acc[project.type]?.length) {
        return { ...acc, [project.type]: [...acc[project.type], project] };
      }

      return { ...acc, [project.type]: [project] };
    }, {});

    if (groupingType === ProjectsGroupingTypesFilter.none) {
      return (
        <>
          {sortedProjects.map((project, index) => {
            const isOneOfLastRows = index > sortedProjects.length - 4;

            return <Row date={date} project={project} key={project.id} shouldScrollAfterExpand={isOneOfLastRows} />;
          })}
        </>
      );
    }

    return Object.entries(groupedProjects)
      .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
      .map(([key, value]) => (
        <RowsAccordion title={t(`projectType.${key}`)} key={key}>
          {value?.map((project, index) => {
            const isOneOfLastRows = index > value.length - 4;

            return <Row date={date} project={project} key={project.id} shouldScrollAfterExpand={isOneOfLastRows} />;
          })}
        </RowsAccordion>
      ));
  }, [projects, groupingType]);

  return (
    <>
      {loading && projects?.length && (
        <div className={clsx(styles.timelineLoader, isNavBarExpanded && styles.timelineLoadingExpandedSidebar)}>
          <AbsoluteSpinner />
        </div>
      )}
      {loading && !projects?.length && (
        <div className={clsx(styles.timelineLoadingState, isNavBarExpanded && styles.timelineLoadingExpandedSidebar)}>
          <ResourcePlanningStateLoadingIcon />
          <span className="mt-24 mb-6">{t('resourcePlanning.emptyState.hangTight')}</span>
          <span>{t('resourcePlanning.emptyState.loadingText')}</span>
        </div>
      )}
      {!loading && !projects?.length && (
        <div className={clsx(styles.timelineLoadingState, isNavBarExpanded && styles.timelineLoadingExpandedSidebar)}>
          <ResourcePlanningStateIcon />
          <span className="mt-24 mb-6">{t('resourcePlanning.emptyState.nothingFound')}</span>
        </div>
      )}

      <div className={styles.timelineContainer}>
        <SearchField />
        {projectRows}
      </div>
    </>
  );
});

ProjectTimeline.displayName = 'Timeline.Project';
