import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BillableLeaveRuleAccrualType, BillableLeaveRuleDataFragment } from 'generated/types';
import { graphqlOnError } from 'utils';
import {
  BillableLeaveRuleBalanceDocument,
  BillableLeavesRulesDocument,
  useDeleteBillableLeaveRuleMutation,
  useEditBillableLeaveRuleMutation,
} from 'generated/graphql';
import { toast } from 'react-toastify';
import { useErrorMsgBuilder } from 'hooks';
import { useAuth } from 'contexts';
import { ViewLeaveRule } from '../ViewLeaveRule';
import { FormValues, LeaveRuleForm } from '../LeaveRuleForm';
import { format, parse } from 'date-fns';
import { DEFAULT_DATE_FORMAT } from 'consts';

interface Props {
  leaveRule: BillableLeaveRuleDataFragment;
  usedLeaveRulesId?: string[];
  onToggleEditing?: (id: string) => void;
  onLogDeleted?: () => void;
}

export const LeaveRuleItem: FC<Props> = ({ leaveRule, usedLeaveRulesId, onToggleEditing, onLogDeleted }) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();
  const [isEdit, setIsEdit] = useState(false);
  const { id, limit, leaveTypes, accrualDate, accrualType, projectId } = leaveRule;

  const [deleteRate] = useDeleteBillableLeaveRuleMutation({
    onCompleted() {
      toast.success(t('viewProjectDetail.billableLeaves.deletedSuccessfully'));
      onLogDeleted?.();
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    update(cache, { data }) {
      if (data) {
        cache.evict({ id: cache.identify(data.deleteBillableLeaveRule) });
        cache.gc();
      }
    },
    variables: { companyId: userData!.company.id, billableLeaveRuleId: id, projectId: projectId },
  });

  const [editBillableLeaveRule, { loading }] = useEditBillableLeaveRuleMutation({
    onCompleted() {
      toast.success(t('viewProjectDetail.billableLeaves.editSuccessfully'));
      setIsEdit(false);
      onToggleEditing?.(id);
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    refetchQueries: [
      {
        query: BillableLeavesRulesDocument,
        variables: {
          companyId: userData!.company.id,
          projectId: projectId,
        },
      },
      BillableLeaveRuleBalanceDocument,
    ],
  });

  const handleEdit = useCallback(async (values: FormValues) => {
    const { accrualDate, accrualType } =
      values?.accrualDate?.id === BillableLeaveRuleAccrualType.AssignmentStartMonth
        ? { accrualDate: null, accrualType: BillableLeaveRuleAccrualType.AssignmentStartMonth }
        : {
            accrualDate: format(parse(values?.accrualDate?.id || '', 'MMMM', new Date()), DEFAULT_DATE_FORMAT),
            accrualType: BillableLeaveRuleAccrualType.Monthly,
          };

    await editBillableLeaveRule({
      variables: {
        data: {
          limit: +(values?.limit || 0),
          accrualDate,
          accrualType,
          leaveTypes: values?.leaveTypes?.map(({ id }) => id) || [],
        },
        companyId: userData!.company.id,
        billableLeaveRuleId: id,
        projectId,
      },
    });
  }, []);

  const accrualDateInitialValue = accrualDate
    ? { name: accrualDate, id: accrualDate }
    : {
        name: `${t('viewProjectDetail.billableLeaves.member')} ▸ ${t('viewProjectDetail.billableLeaves.startDate')}`,
        id: accrualType,
      };

  return isEdit ? (
    <LeaveRuleForm
      onSubmit={handleEdit}
      onCancel={() => {
        setIsEdit(false);
        onToggleEditing?.(id);
      }}
      loading={loading}
      initialValue={{ leaveTypes, limit, accrualDate: accrualDateInitialValue }}
      usedLeaveRulesId={usedLeaveRulesId}
    />
  ) : (
    <ViewLeaveRule
      limit={limit}
      leaveTypes={leaveTypes}
      accrualDate={accrualDate || ''}
      onDelete={() => deleteRate()}
      onEdit={() => {
        setIsEdit(true);
        onToggleEditing?.(id);
      }}
    />
  );
};
