import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Button, RadioGroup } from '@material-ui/core';
import { Popover, Radio, Tooltip } from 'components';
import { useIsOpen } from 'hooks';
import { DownTriangle } from 'icons';

import styles from './styles.module.scss';

type Option = { id: string; name: string };

interface Props<T> {
  title: string;
  showPrefix?: boolean;
  value?: string;
  onChange: (value?: string) => void;
  options: T[];
  noSelectOptionStyle?: boolean;
}

export const FilterRadioPopover = <T extends Option>({
  title,
  showPrefix,
  value,
  onChange,
  options,
  noSelectOptionStyle,
}: Props<T>) => {
  const [isOpen, onOpen, onClose] = useIsOpen();
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState<string>(value || '');

  const label = options?.find(({ id }) => id === value)?.name || '';

  return (
    <Popover
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setSelectedOption(value || '');
      }}
      buttonClassName="h-auto"
      button={
        value ? (
          <Button
            variant={noSelectOptionStyle ? 'text' : 'outlined'}
            color="secondary"
            className={noSelectOptionStyle ? styles.filterButton : styles.valueButton}
            onClick={onOpen}
          >
            <Tooltip title={label} textClassName={styles.tooltipTitle}>
              {showPrefix ? `${title}: ` : ''}
              {label}
            </Tooltip>
            <DownTriangle />
          </Button>
        ) : (
          <Button variant="text" color="secondary" className={styles.filterButton} onClick={onOpen}>
            {title}
            <DownTriangle />
          </Button>
        )
      }
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
    >
      <div className={styles.box}>
        <span className={styles.title}>{title}</span>
        <div className={clsx('form', styles.form)}>
          <div className={styles.formBox}>
            <RadioGroup
              name="radioGroup"
              aria-label="radio group"
              onChange={(_e, value) => setSelectedOption(value)}
              value={selectedOption}
            >
              {options?.map(({ id, name }) => (
                <Radio key={id} label={name} value={id} className={styles.radio} />
              ))}
            </RadioGroup>
          </div>

          <div className={styles.buttons}>
            <Button
              onClick={() => {
                onChange(selectedOption);
                onClose();
              }}
            >
              {t('common.filter.apply')}
            </Button>

            <Button
              color="secondary"
              variant="text"
              onClick={() => {
                setSelectedOption('');
                onChange();
                onClose();
              }}
            >
              {t('common.filter.clear')}
            </Button>
          </div>
        </div>
      </div>
    </Popover>
  );
};
