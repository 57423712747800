import React, { useCallback } from 'react';
import { useColumnOrder, useExpanded, useRowSelect, useSortBy, useTable } from 'react-table';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import AutoSizer from 'react-virtualized-auto-sizer';
import CircularProgress from '@material-ui/core/CircularProgress';

import { InfiniteScrollRow } from './parts';
import { selectColumn, TableProps } from './Table';
import { getTableSort, useTableColumnsOrder, useTableSelectedRows } from 'components/common/Table/hooks';
import { Heading } from 'components/common/Table/parts';

import clsx from 'clsx';
import styles from './Table.module.scss';

interface AutoSizerProps {
  height: number;
  width: number;
}

interface InfiniteScrollTableProps {
  fetchMoreItems: () => void;
  hasMoreItems: boolean;
  bottomPadding?: number;
}

const ROW_HEIGHT = 45;
const HEADER_HEIGHT = 49;

export const InfiniteScrollTable = <D extends Record<string, unknown> = Record<string, never>>({
  data,
  columns,
  className,
  loading,
  onRowClick,
  getRedirectRowLink,
  headingClassName,
  showFooter,
  sortByValue,
  columnOrder,
  onChangeTableSortBy,
  insideAccordion,
  onSelectRows,
  selectedRowIds,
  selectIdName,
  stickyLeftSelection,
  isUnselectedRowStyles = true,
  fetchMoreItems,
  hasMoreItems,
  bottomPadding,
}: TableProps<D> & InfiniteScrollTableProps) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state: { sortBy },
    selectedFlatRows,
    setColumnOrder,
    rows,
  } = useTable(
    {
      columns,
      data,
      disableMultiSort: true,
      initialState: {
        ...(selectedRowIds && { selectedRowIds }),
        ...(sortByValue?.[0].id && { sortBy: sortByValue }),
        hiddenColumns: columns
          .filter(({ show }) => show === false)
          .map(({ accessor, id }) => id || accessor) as string[],
      },
    },
    useSortBy,
    useExpanded,
    useColumnOrder,
    useRowSelect,
    (hooks) => {
      onSelectRows && hooks.visibleColumns.push((columns) => [selectColumn, ...columns]);
    },
  );

  getTableSort({ sortBy, onChangeTableSortBy });
  useTableColumnsOrder({ columnOrder, setColumnOrder });
  useTableSelectedRows({ rows: selectedFlatRows, onSelectRows, selectIdName });

  const renderRow = useCallback(
    ({ index, style, data }) => {
      const row = rows[index];
      if (!row) return <div />;

      return (
        <InfiniteScrollRow<D>
          key={`body-row${row.id}`}
          style={{ ...style, height: ROW_HEIGHT, right: 0, width: data.width }}
          row={row}
          onRowClick={onRowClick}
          getRedirectRowLink={getRedirectRowLink}
          prepareRow={prepareRow}
          showFooter={showFooter}
          isCouldBeSelected={!!onSelectRows && isUnselectedRowStyles}
          isSelectMode={!!onSelectRows}
          stickyLeftSelection={stickyLeftSelection}
        />
      );
    },
    [rows],
  );

  if (loading) {
    return <CircularProgress size={64} style={{ color: '#24343D' }} />;
  }

  const itemCount = hasMoreItems ? rows.length + 1 : rows.length;
  const isItemLoaded = (index: number) => !hasMoreItems || index < rows.length;

  return (
    <div
      className={clsx(styles.table, styles.infiniteScrollTable, insideAccordion && styles.insideAccordion, className)}
    >
      <table {...getTableProps()} style={{ height: ROW_HEIGHT * rows.length + HEADER_HEIGHT }}>
        <thead>
          <Heading<D>
            headerGroups={headerGroups}
            headingClassName={headingClassName}
            insideAccordion={insideAccordion}
            isSelectMode={!!onSelectRows}
            stickyLeftSelection={stickyLeftSelection}
            isSetDefaultWidth
          />
        </thead>
        <AutoSizer>
          {({ height, width }: AutoSizerProps) => {
            const listHeight =
              itemCount * ROW_HEIGHT < height - (bottomPadding || 0)
                ? itemCount * ROW_HEIGHT
                : height - (bottomPadding || 0);

            return (
              <tbody {...getTableBodyProps()} className={styles.infiniteScrollTBody}>
                <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={itemCount} loadMoreItems={fetchMoreItems}>
                  {({ onItemsRendered, ref }) => (
                    <List
                      height={listHeight}
                      itemCount={itemCount}
                      itemSize={ROW_HEIGHT}
                      onItemsRendered={onItemsRendered}
                      ref={ref}
                      width={width}
                      itemData={{ width }}
                      style={{ overflowX: 'hidden' }}
                    >
                      {renderRow}
                    </List>
                  )}
                </InfiniteLoader>
              </tbody>
            );
          }}
        </AutoSizer>
      </table>
    </div>
  );
};
