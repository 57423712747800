export const DAY_UNIT = 'day';
export const MONTH_UNIT = 'month';
export const HOUR_UNIT = 'hour';
export const COLLAPSED_WEEKENDS = 'COLLAPSED_WEEKENDS';
export const PER_WEEK = 'perWeek';
export const PER_DAY = 'perDay';
export const RESOURCE_PLANNING_VIEW = 'RESOURCE_PLANNING_VIEW';
export const MONTH_PERIOD = 'month';
export const QUARTER_PERIOD = 'quarter';
export const YEAR_PERIOD = 'year';
export const GROUP_MEMBERS_BY = 'GROUP_MEMBERS_BY';
export const GROUP_PROJECTS_BY = 'GROUP_PROJECTS_BY';
export const RESOURCE_PLANNING_TEAM_FILTERS = 'RESOURCE_PLANNING_TEAM_FILTERS';
export const BILLING_DATE_RANGE = 'BILLING_DATE_RANGE';

export const QUARTERS: { [key: number]: string } = {
  1: 'I',
  2: 'II',
  3: 'III',
  4: 'IV',
};
