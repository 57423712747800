import React, { useMemo } from 'react';
import { Field, FieldProps, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import { TextField } from 'formik-material-ui';

import { CreatableAutocomplete, DatePicker, LoadingButton, NumberTextField } from 'components';
import { removeUTCTimezone } from 'utils';
import { ActionsType, ExpenseType, ProjectType, Scalars } from 'generated/types';
import { DropdownOption } from 'types';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import clsx from 'clsx';
import styles from './styles.module.scss';
import { useCreatableExpenseTypeData, usePermissions } from 'hooks';

export interface ExpenseFormValues {
  type: DropdownOption | null;
  date: string | Date;
  isBillable: boolean;
  amount?: number;
  billableAmount?: number | null;
  notes: string;
}

interface NewExpenseProps {
  onSubmit: (values: ExpenseFormValues) => void | Promise<void>;
  projectType: ProjectType;
  type?: DropdownOption | null;
  date?: string;
  isBillable?: boolean;
  hideIsBillable?: boolean;
  amount?: number;
  billableAmount?: number;
  notes?: string;
  onCancel: () => void;
  submitLabel?: string;
  actionsClassName?: string;
  disabledAction?: boolean;
  maxDate?: Date;
  minDate?: Date;
}

export const NewExpense = ({
  type,
  date,
  isBillable,
  hideIsBillable,
  projectType,
  amount,
  notes,
  billableAmount,
  submitLabel,
  onSubmit,
  onCancel,
  actionsClassName,
  disabledAction,
  maxDate,
  minDate,
}: NewExpenseProps) => {
  const { t } = useTranslation();
  const { hasAccess } = usePermissions();
  const { expenseTypes, expenseTypeLoading, getCreatedExpenseType } = useCreatableExpenseTypeData();

  const validationSchema = useMemo(
    () =>
      Yup.object().shape(
        {
          type: Yup.object().nullable().required(t('forms.newExpense.typeRequired')),
          date: Yup.string().nullable().required(t('forms.newExpense.dateRequired')),
          amount: Yup.number()
            .typeError(t('validation.invalid'))
            .required(t('forms.newExpense.amountRequired'))
            .min(0, t('forms.newExpense.positiveAmount')),
          billableAmount: Yup.number()
            .when(['isBillable'], {
              is: (isBillable: boolean) => !!isBillable,
              then: Yup.number()
                .typeError(t('forms.newExpense.billableAmountRequired'))
                .required(t('forms.newExpense.billableAmountRequired'))
                .min(0, t('forms.newExpense.positiveBillableAmount')),
            })
            .nullable(),

          isBillable: Yup.boolean().default(false),
          notes: Yup.string().max(250, t('forms.newExpense.maxNote')),
        },
        [],
      ),
    [],
  );

  const handleSubmit = async (values: ExpenseFormValues) => {
    const type = await getCreatedExpenseType(values.type as ExpenseType);
    onSubmit({ ...values, type });
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        type: type ?? null,
        date: date ? removeUTCTimezone(date) : new Date(),
        isBillable: isBillable ?? false,
        amount,
        billableAmount,
        notes: notes ?? '',
      }}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, submitCount, values, touched, errors, setFieldValue, setValues, handleBlur, handleChange }) => (
        <Form className="form">
          <div>
            <InputLabel className="required">{t('forms.newExpense.type')}</InputLabel>
            <Field>
              {({
                form: {
                  values: { type },
                },
              }: FieldProps<ExpenseFormValues['type'], ExpenseFormValues>) => (
                <CreatableAutocomplete
                  placeholder={t('forms.newExpense.typePlaceholder')}
                  className="mb-24"
                  name="type"
                  onBlur={handleBlur}
                  value={type as ExpenseType}
                  error={touched.type ? errors.type : undefined}
                  onChange={(type: ExpenseFormValues['type']) =>
                    setValues({
                      ...values,
                      type,
                    })
                  }
                  options={expenseTypeLoading && type ? ([type] as ExpenseType[]) : expenseTypes}
                  enableCreation={hasAccess(ActionsType.OtherSettings)}
                />
              )}
            </Field>

            <InputLabel className="required">{t('forms.newExpense.date')}</InputLabel>
            <Field>
              {({
                form: {
                  values: { date },
                },
              }: FieldProps<ExpenseFormValues['date'], ExpenseFormValues>) => {
                return (
                  <DatePicker
                    className="mb-30"
                    placeholder={t('calendar.date')}
                    value={date}
                    error={Boolean(submitCount && errors.date)}
                    maxDate={maxDate}
                    minDate={minDate}
                    helperText={!!submitCount && errors.date}
                    onChange={(date: Scalars['DateTime']) =>
                      setValues({
                        ...values,
                        date,
                      })
                    }
                  />
                );
              }}
            </Field>
            <InputLabel className="required">{t('forms.newExpense.amount')}</InputLabel>
            <NumberTextField
              error={Boolean((submitCount || touched.amount) && errors.amount)}
              helperText={(submitCount || touched.amount) && t(errors.amount!)}
              onBlur={handleBlur}
              onChange={handleChange}
              name="amount"
              value={values.amount}
              placeholder="0"
              className="mb-24"
            />

            {(projectType === ProjectType.TimeAndMaterial || projectType === ProjectType.Retainer) && (
              <FormControlLabel
                control={<Checkbox checked={values.isBillable} color="primary" />}
                label={t('forms.newExpense.isBillable')}
                name="isBillable"
                onChange={(e) => {
                  handleChange(e);
                  setFieldValue('billableAmount', values.amount);
                }}
                className={hideIsBillable ? 'd-none' : 'mb-24 ml-0'}
                classes={{ label: clsx(styles.checkboxLabelText, values.isBillable && styles.active) }}
              />
            )}

            {values.isBillable && (
              <>
                <InputLabel className="required">{t('forms.newExpense.billableAmount')}</InputLabel>
                <Field component={TextField} name="billableAmount" placeholder="0" className="mb-24" />
              </>
            )}

            <InputLabel>{t('forms.newExpense.notes')}</InputLabel>
            <Field component={TextField} multiline rows={3} name="notes" className="mb-24" />
          </div>
          <div className={clsx('controls', actionsClassName)}>
            <LoadingButton type="submit" loading={isSubmitting} className="mr-8" disabled={disabledAction}>
              {submitLabel ?? t('forms.newExpense.createNewExpense')}
            </LoadingButton>
            <Button variant="outlined" color="secondary" onClick={onCancel}>
              {t('forms.cancel')}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
