import React, { FC } from 'react';

interface Props {
  x: number;
  y: number;
  width: number;
  height: number;
}

export const ReferenceAreaLine: FC<Props | undefined> = ({ x, y, width, height }) => {
  if (!height) {
    return <svg />;
  }

  return (
    <svg>
      <rect x={x + width / 2} y={y} width={1} height={height} fill="#FFAF91" />
    </svg>
  );
};
