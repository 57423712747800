import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Route } from 'react-router-hoc';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Switch } from 'react-router-dom';

import { Button, debounce } from '@material-ui/core';

import { links } from 'App';
import { useAuth } from 'contexts';
import {
  ActionsType,
  EmploymentType,
  ImportType,
  MemberIntegrationStatusesDataFragment,
  MemberListDetailsDataFragment,
} from 'generated/types';
import { ASC, FIRST_NAME, LAST_NAME } from 'consts';
import { useDeviceTypeByWidth, useErrorMsgBuilder, usePermissions, useSetAppTitle } from 'hooks';
import { graphqlOnError, searchFilter, sortByField } from 'utils';
import { GetRouteProps, RouteProps, TeamModals, TeamTabs } from 'types';
import {
  ChangeFiltersData,
  CommonActions,
  CommonFilterOptions,
  CommonFilters,
  IntegrationModal,
  MobileSearch,
  ResizableSearch,
  TabItem,
  TableCountHeader,
  Tabs,
  ViewHeading,
} from 'components';
import { MemberData } from './components/TeamTable';
import { UploadFile } from 'icons';
import {
  useArchiveMemberMutation,
  useMembersIntegrationStatusesQuery,
  useMembersListDataQuery,
  useRestoreMemberMutation,
  useTeamMembersQuery,
} from 'generated/graphql';
import { ApolloError } from '@apollo/client/errors';
import { FetchPolicy } from '@apollo/client/core/watchQueryOptions';

import { ActiveTeamMembers, ArchivedTeamMembers } from './parts';

import styles from './styles.module.scss';
import clsx from 'clsx';

const TeamsRoute = Route(
  {
    search: Route.query.string,
    tab: Route.params.oneOf(...Object.values(TeamTabs)),
    modal: Route.params.oneOf(...Object.values(TeamModals)).optional,
    employmentType: Route.query.array(Route.query.string),
    reportingTo: Route.query.array(Route.query.string),
    seniority: Route.query.array(Route.query.string),
    specialization: Route.query.array(Route.query.string),
  },
  ({ tab, modal }) => `/team/${tab}/${modal}`,
);

type Props = GetRouteProps<typeof TeamsRoute>;

interface IntegrationStatusesData {
  [key: string]: MemberIntegrationStatusesDataFragment;
}

interface MemberListDetailsData {
  [key: string]: MemberListDetailsDataFragment;
}

const Teams = ({
  match: {
    query,
    query: { search, employmentType, reportingTo, seniority, specialization },
    params,
    params: { tab, modal },
  },
  link,
  history: { push },
}: Props) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();
  const [searchTerm, setSearchTerm] = useState<string | undefined>(search);
  const { hasAccess } = usePermissions();
  const isActiveTeamMembersAccess = hasAccess(ActionsType.ViewActiveMembers);
  const isArchiveTeamMembersAccess = hasAccess(ActionsType.ViewArchivedMembers);
  const { isMobileDevice } = useDeviceTypeByWidth();
  useSetAppTitle(t('navigation.teams'));

  useEffect(() => {
    setSearchTerm(search);
  }, [search]);

  const teamQueryVariables = {
    companyId: userData!.company.id,
    isArchived: tab === TeamTabs.archived,
    filterData: {
      employmentType: employmentType?.length === 1 ? (employmentType[0] as EmploymentType) : null,
      reportingTo: (reportingTo as string[]) || null,
      seniority: (seniority as string[]) || null,
      specialization: (specialization as string[]) || null,
    },
  };
  const queryOptions = {
    onError: (err: ApolloError) => {
      graphqlOnError(err, tls(err.message));
    },
    skip:
      (!isActiveTeamMembersAccess && tab !== TeamTabs.active) ||
      (!isArchiveTeamMembersAccess && tab === TeamTabs.archived),
    fetchPolicy: 'cache-and-network' as FetchPolicy,
    nextFetchPolicy: 'cache-first' as FetchPolicy,
  };

  const { data: { members = [] } = {}, refetch, loading } = useTeamMembersQuery({
    variables: teamQueryVariables,
    ...queryOptions,
  });

  const { data: { membersListData = [] } = {} } = useMembersListDataQuery({
    variables: teamQueryVariables,
    ...queryOptions,
  });
  const { data: { membersIntegrationStatuses } = {} } = useMembersIntegrationStatusesQuery({
    onError: queryOptions.onError,
    variables: {
      companyId: userData!.company.id,
      isArchived: tab === TeamTabs.archived,
    },
    skip: !userData?.company.id,
  });

  const integrationStatusesData = useMemo(
    () =>
      (membersIntegrationStatuses || []).reduce<IntegrationStatusesData>(
        (acc, item) => ({ ...acc, [item.memberId]: item }),
        {},
      ),
    [membersIntegrationStatuses],
  );

  const membersDetailsData = useMemo(
    () => membersListData?.reduce<MemberListDetailsData>((acc, item) => ({ ...acc, [item.memberId]: item }), {}),
    [membersListData],
  );

  const filteredMembers = useMemo(() => {
    const filtered = members
      ?.filter(({ first_name, last_name, email }) => !search || searchFilter(search, first_name, last_name, email))
      .map((member) => ({
        ...member,
        ...(membersDetailsData?.[member.id] || {}),
        integrationStatuses: integrationStatusesData?.[member.id]?.statuses || [],
      }));

    return (sortByField(filtered, ASC, FIRST_NAME, LAST_NAME) as unknown) as MemberData[];
  }, [members, search, integrationStatusesData, membersDetailsData]);

  const [archiveMember] = useArchiveMemberMutation({
    async onCompleted() {
      toast.success(t('teams.teamMemberArchivedSuccessfully'));
      refetch();
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
  });

  const [restoreMember] = useRestoreMemberMutation({
    async onCompleted() {
      toast.success(t('teams.teamMemberRestoredSuccessfully'));
      refetch();
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
  });

  const changeQueryParams = useCallback(
    debounce((search?: string) => {
      push(link({ tab, ...query, search: search || undefined }));
    }, 200),
    [query, search, tab],
  );

  const onSearchChange = useCallback(
    (value?: string) => {
      setSearchTerm(value);
      changeQueryParams(value);
    },
    [changeQueryParams],
  );

  const onChangeFilters = (data: ChangeFiltersData) => push(link({ tab, ...query, ...data }));
  const onClose = () => push(link({ ...query, ...params, modal: undefined }));

  const countLabel = loading
    ? ''
    : `${filteredMembers.length} ${filteredMembers.length === 1 ? t('teams.member') : t('teams.members')}`;

  return (
    <>
      <ViewHeading hasSmartBackBtn={false} label={t('teams.label')} className={styles.viewHeading}>
        <section className="flex gap-8 ml-8">
          {hasAccess(ActionsType.CreateAssignments) && (
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<UploadFile />}
              onClick={() => push(link({ ...query, ...params, modal: TeamModals.integration }))}
            >
              {t('teams.importTeamMembers')}
            </Button>
          )}

          <CommonActions
            menuItemsOrder={['teamMember', 'assignment', 'requestAssignment', 'leave', 'client', 'project']}
            refetchMembersAction={['TeamMembers']}
          />
        </section>
      </ViewHeading>

      <Tabs className="tabs-box">
        {isActiveTeamMembersAccess ? (
          <TabItem exact={tab !== TeamTabs.active} route={links.ActiveTeamMembers()}>
            {t('teams.active')}
          </TabItem>
        ) : null}

        {isArchiveTeamMembersAccess ? (
          <TabItem exact={tab !== TeamTabs.archived} route={links.ArchivedTeamMembers()}>
            {t('teams.archived')}
          </TabItem>
        ) : null}
      </Tabs>
      <TableCountHeader countLabel={countLabel} className={isMobileDevice ? 'px-8' : 'px-30'}>
        <div className={clsx(styles.actions, 'flex')}>
          {!isMobileDevice && <ResizableSearch onChange={onSearchChange} value={searchTerm} className="mr-12" />}
          <CommonFilters
            onChange={onChangeFilters}
            filterButtonClassName={styles.filterButton}
            filtersOptions={[
              CommonFilterOptions.employmentType,
              CommonFilterOptions.seniority,
              CommonFilterOptions.specialization,
              CommonFilterOptions.reportingTo,
            ]}
            employmentType={employmentType as string[]}
            seniority={seniority as string[]}
            specialization={specialization as string[]}
            reportingTo={reportingTo as string[]}
          />
        </div>
      </TableCountHeader>

      {isMobileDevice && <MobileSearch onChange={onSearchChange} value={searchTerm} className="px-8 pb-12" />}

      <div className="layout-content-wrapper">
        <Switch>
          <ActiveTeamMembers members={filteredMembers} loading={loading} archiveMember={archiveMember} />
          <ArchivedTeamMembers members={filteredMembers} loading={loading} restoreMember={restoreMember} />
        </Switch>
      </div>

      <IntegrationModal
        title={t('teams.importTeamMembers')}
        importType={ImportType.Member}
        isOpen={hasAccess(ActionsType.CreateMembers) && modal === TeamModals.integration}
        onClose={onClose}
      />
    </>
  );
};

export default TeamsRoute<RouteProps>(Teams);
