import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Button } from '@material-ui/core';
import { Autocomplete, CountChip, Popover, Tag, Tooltip } from 'components';
import { useIsOpen } from 'hooks';
import { DownTriangle } from 'icons';

import styles from './styles.module.scss';

type Option = { id: string; name: string };

interface Props<T> {
  title: string;
  value?: string[];
  placeholder?: string;
  onChange: (value?: string[]) => void;
  options: T[];
}

export const FilterTagsPopover = <T extends Option>({ title, placeholder, value, onChange, options }: Props<T>) => {
  const [isOpen, onOpen, onClose] = useIsOpen();
  const { t } = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState<T[]>([]);

  const label = options?.find(({ id }) => id === value?.[0])?.name || '';
  const selectedAdditionalOptionsCount =
    value && value.length > 1 ? <CountChip count={value.length - 1} className="ml-4" /> : null;

  useEffect(() => {
    if (!options.length || !value) return;

    setSelectedOptions(options.filter((option) => value?.includes(option.id)));
  }, [value, options]);

  return (
    <Popover
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setSelectedOptions(options.filter((option) => value?.includes(option.id)));
      }}
      buttonClassName="h-auto"
      button={
        value?.length ? (
          <Button variant="outlined" color="secondary" className={styles.valueButton} onClick={onOpen}>
            <Tooltip title={label} textClassName={styles.tooltipTitle}>
              {label}
            </Tooltip>
            {selectedAdditionalOptionsCount}
            <DownTriangle />
          </Button>
        ) : (
          <Button variant="text" color="secondary" className={styles.filterButton} onClick={onOpen}>
            {title}
            <DownTriangle />
          </Button>
        )
      }
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
    >
      <div className={styles.box}>
        <span className={styles.title}>{title}</span>
        <div className={clsx('form', styles.form)}>
          <div className={styles.formBox}>
            <Autocomplete
              placeholder={placeholder || title}
              name="tags"
              value={[]}
              options={options}
              filterOptions={(options: T[]) => {
                const selectedOptionsIds = selectedOptions.map(({ id }) => id);
                return options.filter((item: T) => !selectedOptionsIds.includes(item.id));
              }}
              onChange={(value: T[]) => setSelectedOptions((prev) => [...prev, ...value])}
              classes={{ inputRoot: styles.autocomplete }}
              multiple
              disableClearable
            />

            <div className="flex gap-4 flex-wrap mt-8">
              {selectedOptions.map(({ id, name }) => (
                <Tag
                  key={id}
                  name={name}
                  className={styles.tag}
                  color=""
                  onDelete={() => setSelectedOptions((prev) => prev.filter((option) => option.id !== id))}
                />
              ))}
            </div>
          </div>

          <div className={styles.buttons}>
            <Button
              onClick={() => {
                onChange(selectedOptions.map(({ id }) => id));
                onClose();
              }}
            >
              {t('common.filter.apply')}
            </Button>

            <Button
              color="secondary"
              variant="text"
              onClick={() => {
                setSelectedOptions([]);
                onChange();
                onClose();
              }}
            >
              {t('common.filter.clear')}
            </Button>
          </div>
        </div>
      </div>
    </Popover>
  );
};
