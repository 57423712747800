import React, { FC, useCallback, useMemo, useState } from 'react';
import { Cell, Pie, PieChart, PieProps, ResponsiveContainer, Sector, Tooltip, TooltipProps } from 'recharts';
import { MemberProjectDistributionDataFragment } from 'generated/types';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { ChartTooltip } from 'views/Insights/components';
import { EmptyState, StyledNumberValue } from 'components';
import { AssignmentsDistributionLegend } from './AssignmentsDistributionLegend';
import { useTranslation } from 'react-i18next';
import { useMemberProjectsDistributionQuery } from 'generated/graphql';
import { graphqlOnError } from 'utils';
import { DEFAULT_DATE_FORMAT } from 'consts';
import { useAuth } from 'contexts';
import { useErrorMsgBuilder } from 'hooks';

import styles from './styles.module.scss';

interface Props {
  memberId: string;
}

const strokeColor = '#EBEDEE';

const renderActiveShape: PieProps['activeShape'] = (props) => {
  const { cx, cy, cornerRadius, strokeWidth, innerRadius, outerRadius, startAngle, endAngle, fill } = props!;

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
        cornerRadius={cornerRadius}
        strokeWidth={strokeWidth}
      />
    </g>
  );
};

export const AssignmentsDistribution: FC<Props> = ({ memberId }) => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();

  const { data: { memberProjectsDistribution = [] } = {} } = useMemberProjectsDistributionQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
      memberId,
      interval: {
        end: format(endOfMonth(new Date()), DEFAULT_DATE_FORMAT),
        start: format(startOfMonth(new Date()), DEFAULT_DATE_FORMAT),
      },
    },
    skip: !userData?.company.id,
  });

  const chartData = useMemo<MemberProjectDistributionDataFragment[]>(() => {
    if (!memberProjectsDistribution?.length)
      return [
        {
          projectId: '',
          projectName: t('viewTeamMember.assignmentsChart.noAssignments'),
          totalHours: 1,
          percentage: 100,
          projectColor: strokeColor,
        },
      ];

    return memberProjectsDistribution.slice().sort((a, b) => b.percentage - a.percentage);
  }, [memberProjectsDistribution]);

  const isOneItemPieChart = chartData.length === 1;
  const isNoItemPieChart = !memberProjectsDistribution?.length;

  const renderCustomTooltip = useCallback(
    ({ active, payload: payloads }: TooltipProps<string, string>) => {
      const data = payloads?.[0];
      if (!active || !data?.payload?.projectId) return null;

      const { payload } = data;
      setActiveIndex(chartData.findIndex(({ projectName }) => projectName === payload.projectName));

      return (
        <ChartTooltip color={payload.projectColor || ''} label={payload.projectName || ''}>
          <StyledNumberValue autoDigitsAfterDot digitsAfterDot={1} suffix="%" value={payload.percentage || 0} />
        </ChartTooltip>
      );
    },
    [chartData],
  );

  return (
    <div className={styles.box}>
      <h3 className={styles.titleBox}>
        {t('viewTeamMember.assignmentsChart.assignmentDistribution')}
        <span className={styles.dateText}>{t('viewTeamMember.assignmentsChart.currentMonth')}</span>
      </h3>
      <div className="flex gap-16">
        <div className={styles.chartBox}>
          <ResponsiveContainer width="100%" height="100%">
            <PieChart width={124} height={124}>
              <Pie
                dataKey="totalHours"
                data={chartData}
                innerRadius={10}
                outerRadius={60}
                cornerRadius={2}
                strokeWidth={isOneItemPieChart ? 0 : 2}
                activeIndex={activeIndex ?? undefined}
                onMouseOut={() => setActiveIndex(null)}
                onMouseLeave={() => setActiveIndex(null)}
                activeShape={renderActiveShape}
                isAnimationActive={false}
              >
                {chartData.map((item) => (
                  <Cell key={item.projectId} fill={item.projectColor} />
                ))}
              </Pie>
              <Pie
                dataKey="value"
                data={[{ value: 1 }]}
                outerRadius={8}
                blendStroke
                fill={strokeColor}
                isAnimationActive={false}
              />
              <Tooltip allowEscapeViewBox={{ x: true, y: true }} content={renderCustomTooltip} />
            </PieChart>
          </ResponsiveContainer>
        </div>
        {isNoItemPieChart ? (
          <EmptyState title={t('viewTeamMember.assignmentsChart.noAssignments')} className={styles.emptyState} />
        ) : (
          <AssignmentsDistributionLegend chartData={chartData} />
        )}
      </div>
    </div>
  );
};
