import { startOfYear, endOfMonth, startOfMonth, subMonths, format, endOfYear, subYears } from 'date-fns';
import { DateRangeTypes } from 'types';

const DATE_FORMAT = 'yyyy-MM-dd';

export const getDateRangeFromType = (range: DateRangeTypes) => {
  switch (range) {
    case DateRangeTypes.yearToDate:
      return [format(startOfYear(new Date()), DATE_FORMAT), format(endOfMonth(new Date()), DATE_FORMAT)];
    case DateRangeTypes.lastYear:
      return [
        format(startOfMonth(subMonths(new Date(), 12)), DATE_FORMAT),
        format(endOfMonth(new Date()), DATE_FORMAT),
      ];
    case DateRangeTypes.lastMonth:
      return [
        format(startOfMonth(subMonths(new Date(), 1)), DATE_FORMAT),
        format(endOfMonth(subMonths(new Date(), 1)), DATE_FORMAT),
      ];
    case DateRangeTypes.lastThreeMonths:
      return [format(startOfMonth(subMonths(new Date(), 2)), DATE_FORMAT), format(endOfMonth(new Date()), DATE_FORMAT)];
    case DateRangeTypes.thisMonth:
      return [format(startOfMonth(new Date()), DATE_FORMAT), format(endOfMonth(new Date()), DATE_FORMAT)];
    case DateRangeTypes.thisYear:
      return [format(startOfYear(new Date()), DATE_FORMAT), format(endOfYear(new Date()), DATE_FORMAT)];
    case DateRangeTypes.prevYear:
      return [
        format(startOfYear(subYears(new Date(), 1)), DATE_FORMAT),
        format(endOfYear(subYears(new Date(), 1)), DATE_FORMAT),
      ];
    default:
      return [undefined, undefined];
  }
};
