import React, { FC, PropsWithChildren, ReactElement, useState } from 'react';
import { Popover as MuiPopover, PopoverOrigin } from '@material-ui/core';

import styles from './styles.module.scss';
import clsx from 'clsx';

interface Props {
  button: ReactElement;
  isOpen: boolean;
  onClose: () => void;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  buttonClassName?: string;
}

export const Popover: FC<PropsWithChildren<Props>> = ({
  isOpen,
  onClose,
  button,
  children,
  anchorOrigin,
  transformOrigin,
  buttonClassName,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onClose();
  };

  const id = isOpen ? 'popover' : undefined;

  return (
    <>
      <button aria-describedby={id} onClick={handleClick} className={clsx(styles.button, buttonClassName)}>
        {button}
      </button>
      <MuiPopover
        id={id}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={
          anchorOrigin || {
            vertical: 'bottom',
            horizontal: 'center',
          }
        }
        transformOrigin={
          transformOrigin || {
            vertical: 'top',
            horizontal: 'center',
          }
        }
      >
        <div>{children}</div>
      </MuiPopover>
    </>
  );
};
