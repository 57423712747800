import React, { FC, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Popover } from 'components';
import { useErrorMsgBuilder, useIsOpen } from 'hooks';
import { Button } from '@material-ui/core';
import { EditIcon } from 'icons';
import clsx from 'clsx';
import { BillableLeaveRuleAccrualType } from 'generated/types';
import { useFormik } from 'formik';
import InputLabel from '@material-ui/core/InputLabel';
import { DEFAULT_DATE_FORMAT, EMPTY_DATA_STATE } from 'consts';
import { useDeleteMemberBalanceAccrualMonthMutation, useSetMemberBalanceAccrualMonthMutation } from 'generated/graphql';
import { graphqlOnError } from 'utils';
import { toast } from 'react-toastify';
import { useAuth } from 'contexts';
import { format, parse } from 'date-fns';

import styles from './styles.module.scss';

interface Props {
  accrualMonth?: string;
  billableLeaveRuleId: string;
  memberId: string;
  refetchLeaveBalance: () => void;
}

export type FormValues = {
  accrualDate: { name: string; id: string } | null;
};

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const AccrualMonthCell: FC<Props> = ({ accrualMonth, memberId, billableLeaveRuleId, refetchLeaveBalance }) => {
  const { t } = useTranslation();
  const tls = useErrorMsgBuilder();
  const { userData } = useAuth();
  const [isOpen, onOpen, onClose] = useIsOpen();
  const anchorRef = useRef<HTMLButtonElement>(null);

  const [setMemberBalanceAccrualMonth] = useSetMemberBalanceAccrualMonthMutation({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    onCompleted() {
      toast.success(t('viewProjectDetail.leavesBalance.accrualMonthUpdateSuccessfully'));
      refetchLeaveBalance();
      onClose();
    },
  });

  const [deleteMemberBalanceAccrualMonth] = useDeleteMemberBalanceAccrualMonthMutation({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    onCompleted() {
      toast.success(t('viewProjectDetail.leavesBalance.accrualMonthUpdateSuccessfully'));
      refetchLeaveBalance();
      onClose();
    },
  });

  const onSetAccrualMonth = (accrualMonth: string) => {
    const accrualDate = format(parse(accrualMonth || '', 'MMMM', new Date()), DEFAULT_DATE_FORMAT);
    setMemberBalanceAccrualMonth({
      variables: { accrualDate, companyId: userData!.company.id, billableLeaveRuleId, memberId },
    });
  };
  const onDeleteAccrualMonth = () => {
    deleteMemberBalanceAccrualMonth({
      variables: { companyId: userData!.company.id, billableLeaveRuleId, memberId },
    });
  };

  const monthOptions = useMemo(() => {
    return months.map((value) => ({
      name: t(`common.months.${value}`),
      id: value,
    }));
  }, []);

  const { handleSubmit, handleBlur, values, setValues, touched, errors } = useFormik<FormValues>({
    initialValues: {
      accrualDate: accrualMonth
        ? {
            name: t(`common.months.${accrualMonth}`),
            id: accrualMonth,
          }
        : null,
    },
    onSubmit: ({ accrualDate }) => {
      if (accrualDate?.id) {
        onSetAccrualMonth(accrualDate.id);
      } else {
        onDeleteAccrualMonth();
      }
    },
    validateOnChange: true,
  });

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Popover
        isOpen={isOpen}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        button={
          <Button
            ref={anchorRef}
            variant="text"
            className={styles.button}
            endIcon={<EditIcon className={styles.icon} />}
            onClick={isOpen ? onClose : onOpen}
          >
            {accrualMonth ? t(`common.months.${accrualMonth}`) : EMPTY_DATA_STATE}
          </Button>
        }
      >
        <div className={styles.container}>
          <span className={styles.title}>{t('viewProjectDetail.billableLeaves.setAccrualMonth')}</span>
          <form>
            <InputLabel>{t('viewProjectDetail.billableLeaves.selectMonth')}</InputLabel>
            <Autocomplete
              placeholder={t('viewProjectDetail.billableLeaves.month')}
              name="accrualDate"
              onBlur={handleBlur}
              className={clsx(styles.item, styles.accrualDate)}
              value={values.accrualDate}
              error={touched.accrualDate ? errors.accrualDate : undefined}
              options={monthOptions}
              renderOption={(option: { id: string; name: string }) =>
                option.id === BillableLeaveRuleAccrualType.AssignmentStartMonth ? (
                  <div>
                    <span className={styles.secondaryColor}>{t('viewProjectDetail.billableLeaves.member')} ▸ </span>{' '}
                    {t('viewProjectDetail.billableLeaves.startDate')}
                  </div>
                ) : (
                  option.name
                )
              }
              onChange={(accrualDate: FormValues['accrualDate']) => setValues({ ...values, accrualDate })}
              classes={{ inputRoot: 'white-background' }}
            />

            <div className={styles.buttons}>
              <Button color="secondary" variant="outlined" onClick={onClose}>
                {t('forms.cancel')}
              </Button>
              <Button onClick={() => handleSubmit()}>{t('forms.save')}</Button>
            </div>
          </form>
        </div>
      </Popover>
    </div>
  );
};
