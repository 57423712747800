import React, { FC } from 'react';
import { BadgeTag, Tooltip } from 'components';
import { useTranslation } from 'react-i18next';
import { MemberProjectDistributionDataFragment } from 'generated/types';

import styles from './styles.module.scss';

interface Props {
  chartData: MemberProjectDistributionDataFragment[];
}

const strokeColor = '#EBEDEE';

export const AssignmentsDistributionLegend: FC<Props> = ({ chartData }) => {
  const { t } = useTranslation();

  const shownProjects = chartData.slice(0, 3);
  const hiddenProjects = chartData.slice(3);

  const getProjectItem = ({
    projectId,
    projectColor,
    projectName,
    percentage,
  }: MemberProjectDistributionDataFragment) => (
    <BadgeTag key={projectId} color={projectColor}>
      <Tooltip title={projectName} textClassName={styles.projectName}>
        {projectName}
      </Tooltip>
      &nbsp;• {percentage}%
    </BadgeTag>
  );

  return (
    <div className={styles.legend}>
      {shownProjects.map(getProjectItem)}
      {!!hiddenProjects.length && (
        <Tooltip
          className={styles.tooltip}
          title={<div className={styles.hiddenProjectTooltip}>{hiddenProjects.map(getProjectItem)}</div>}
          alwaysShowTooltip
        >
          <BadgeTag color={strokeColor}>
            {hiddenProjects.length} {t('viewTeamMember.more')}
          </BadgeTag>
        </Tooltip>
      )}
    </div>
  );
};
