export const downloadExcelXLS = (link: string, name: string) => {
  const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${link}`;
  const downloadLink = document.createElement('a');
  const fileName = `${name.replace('.', '')}`;

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
  downloadLink.remove();
};
