import React, { FC, MutableRefObject, useLayoutEffect, useMemo, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { useAuth } from 'contexts';
import { Autocomplete, ConfirmModal } from 'components';
import { addTimezoneOffset, currencyToValue, graphqlOnError, sortByField } from 'utils';
import { ASC, DEFAULT_DATE_FORMAT, NAME } from 'consts';
import { useCreateExternalRate, useErrorMsgBuilder, useIsOpen } from 'hooks';

import { ExternalRateDataFragment, RateUnit, Role } from 'generated/types';
import { useRolesQuery } from 'generated/graphql';

import { getRates, isOverlapped } from '../helpers';
import { RateData, SeniorityRates } from '../Rates';
import { FormValues, RateForm } from '../components/RateForm';

import styles from './styles.module.scss';

interface Props {
  showRoleSelect?: boolean;
  showSenioritySelect?: boolean;
  roleId?: string;
  seniorityId?: string | null;
  projectId: string;
  currency?: string;
  unit: RateUnit;
  onClose: () => void;
  addRoleInputRef?: MutableRefObject<HTMLDivElement | null>;
  rates?: ExternalRateDataFragment[];
  rateData?: RateData;
  seniorityRates?: SeniorityRates;
}

export const CreateRate: FC<Props> = ({
  showRoleSelect,
  showSenioritySelect,
  projectId,
  currency,
  unit,
  roleId,
  seniorityId,
  onClose,
  addRoleInputRef,
  rateData,
  seniorityRates,
  rates,
}) => {
  const { t } = useTranslation();
  const [role, setRole] = useState<Role | null>(null);
  const [isOpenConfirm, onOpenConfirm, onCloseConfirm] = useIsOpen(false);
  const [rate, setRate] = useState<FormValues>();
  const [isConfirmSubmitting, setConfirmSubmitting] = useState<boolean | undefined>(undefined);
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();

  useLayoutEffect(() => {
    if (addRoleInputRef?.current) {
      addRoleInputRef.current.focus();
    }
  }, [addRoleInputRef?.current]);

  const createExternalRate = useCreateExternalRate({
    onCompleted: onClose,
    projectId,
    companyId: userData!.company.id,
  });

  const onHandleOpenConfirm = () => {
    setConfirmSubmitting(false);
    onOpenConfirm();
  };

  const onHandleCreate = async (values?: FormValues) => {
    const data = rate ?? values;
    if (data) {
      setConfirmSubmitting(true);
      const { seniority, start_date, end_date, unit, amount } = data;
      await createExternalRate({
        variables: {
          companyId: userData?.company.id as string,
          projectId: projectId,
          roleId: role?.id || roleId || '',
          seniorityId: seniority?.id || seniorityId,
          data: {
            start_date: format(new Date(start_date), DEFAULT_DATE_FORMAT),
            end_date: end_date ? format(new Date(end_date), DEFAULT_DATE_FORMAT) : '',
            rate_amount: Math.round(currencyToValue(amount || 0)),
            unit: unit.id,
          },
        },
      }).then(() => setConfirmSubmitting(undefined));
    }
  };

  const create = (values: FormValues) => {
    setRate(values);
    const { seniority, start_date, end_date } = values;

    const typedStart: Date =
      typeof start_date === 'string' ? addTimezoneOffset(parseISO(start_date)) : addTimezoneOffset(start_date as Date);

    const typedEnd: Date | undefined =
      typeof end_date === 'string' ? parseISO(end_date) : !!end_date ? (end_date as Date) : undefined;

    const currentRates = getRates(role, seniority, rateData, seniorityRates, rates);
    const confirmIsNeeded = isOverlapped(typedStart, currentRates ?? [], typedEnd);

    confirmIsNeeded ? onHandleOpenConfirm() : onHandleCreate({ ...values });
  };

  const { data: { roles = [] as Role[] } = {} } = useRolesQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData?.company.id as string,
    },
    skip: !showRoleSelect,
  });

  const sortedRoles = useMemo(() => sortByField(roles, ASC, NAME), [roles]);

  const roleSelect = useMemo(() => {
    if (!showRoleSelect) {
      return;
    }

    return (
      <div className={styles.container}>
        {role ? (
          <h4 className={styles.title}>{role.name}</h4>
        ) : (
          <Autocomplete
            name="role"
            placeholder={`${t('rateCard.addRole')}...`}
            className={styles.roleSelect}
            value={role}
            onChange={(role: Role) => setRole(role)}
            options={sortedRoles || []}
            ref={addRoleInputRef}
          />
        )}
      </div>
    );
  }, [role, showRoleSelect, sortedRoles]);

  return (
    <div>
      {roleSelect}
      {(role || roleId) && (
        <RateForm
          showSenioritySelect={showSenioritySelect}
          currencyCode={currency}
          unit={unit}
          onSubmit={create}
          onCancel={onClose}
          isConfirmSubmitting={isConfirmSubmitting}
        />
      )}
      <ConfirmModal
        title={t('financial.headsUp')}
        isOpen={isOpenConfirm}
        onSubmit={onHandleCreate}
        onClose={() => onCloseConfirm()}
        submitButtonTitle={t('actions.create')}
      >
        {t('financial.confirmMessage')}
      </ConfirmModal>
    </div>
  );
};
