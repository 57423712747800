import React from 'react';
import { Route } from 'react-router-hoc';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';

import { ConfirmIconButton, EmptyState } from 'components';
import { MemberData, TeamTable } from 'views/Teams/components/TeamTable';
import { ModuleName, ScreenName } from 'types';
import { ActionsType, ArchiveMemberMutation, Exact } from 'generated/types';
import { MutationFunctionOptions } from '@apollo/client/react/types/types';
import { useAuth } from 'contexts';
import { ArchiveIcon } from 'icons';
import { usePermissions, useTrackScreenView } from 'hooks';
import CircularProgress from '@material-ui/core/CircularProgress';

const ActiveTeamMembersRoute = Route('/team/active');

interface ActiveTeamMembersProps {
  members: MemberData[];
  loading: boolean;
  archiveMember: (
    options:
      | MutationFunctionOptions<
          ArchiveMemberMutation,
          Exact<{
            companyId: string;
            memberId: string;
          }>
        >
      | undefined,
  ) => void;
}

const TeamMembers = ({ members, loading, archiveMember }: ActiveTeamMembersProps) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const { hasAccess, isPermissionsLoading } = usePermissions();
  useTrackScreenView(ModuleName.team, ScreenName.list);

  const handleArchiveMember = (id: string) =>
    archiveMember({
      variables: {
        companyId: userData!.company.id,
        memberId: id,
      },
    });

  if (loading || isPermissionsLoading) {
    return <CircularProgress size={64} style={{ color: '#24343D' }} />;
  }

  return hasAccess(ActionsType.ViewActiveMembers) ? (
    <TeamTable
      members={members}
      showTableActions={hasAccess(ActionsType.ArchiveMembers)}
      getTableActions={(id) => (
        <>
          <Box mr="10px" />
          <ConfirmIconButton
            tooltipText={t('actions.archive')}
            onClick={() => handleArchiveMember(id)}
            confirmTitle={t('teams.archiveUser')}
            confirmMessage={t('teams.confirmArchiveUser')}
            confirmSubmitButtonTitle={t('teams.archiveUser')}
          >
            <ArchiveIcon />
          </ConfirmIconButton>
        </>
      )}
    />
  ) : (
    <EmptyState className="mt-40" title="permission.denied" />
  );
};

export default ActiveTeamMembersRoute(TeamMembers);
